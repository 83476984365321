<fsx-side-panel-component
  #sidePanel
  [headerConfig]="headerConfig"
  [footerConfig]="footerConfig"
>
  <fsx-new-filing (setNewFilingEvent)="setNewFiling($event)"> </fsx-new-filing>

  <div *ngIf="showClientMatterNumber" class="client-matter-container">
    <fsx-text-component
      [fieldDefinition]="clientMatterNumberFieldDefinition"
      [initialValue]="''"
      [hint]="'Type Client Matter Number'"
      [width]="'440px'"
      [height]="'28px'"
      (textChanged)="onClientMatterNumberTextChanged($event)"
      [caption]="'Client Matter'"
    ></fsx-text-component>
  </div>

  <div *ngIf="showProposedCaseName" class="case-name-container">
    <fsx-textbox-component
      [caption]="'Transaction Name'"
      [width]="'440px'"
      [height]="'100px'"
      [initialValues]="['']"
      [fieldDefinitions]="[proposedCaseNameFieldDefinition]"
      (textChanged)="onTransactionNameTextChanged($event)"
    ></fsx-textbox-component>
  </div>
</fsx-side-panel-component>
