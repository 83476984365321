import { Component, Input } from '@angular/core';
import { ValidationError } from '../../filing-editor/services/validation-errors.service';

@Component({
  selector: 'fsx-label-with-validation',
  templateUrl: './label-with-validation.component.html',
  styleUrls: ['./label-with-validation.component.scss'],
})
export class LabelWithValidationComponent {
  @Input() labelText: string = '';

  @Input() validationErrors: ValidationError[] = [];
}
