import {
  ApiVersion,
  endpoints,
  EnvConfig,
  ENVIRONMENT,
  MockCourtData,
} from '@fsx/fsx-shared';
import packageFile from 'package.json';

const BaseURL = 'https://app.efsp.dev.fileandserve.com/';
const BaseIdentityServerURL = 'https://app.identity.demo.fileandserve.com';
const versionedUrl = `${BaseURL}/v1`;

const TestEfmCourtIdSitApi = '32c7d9f3-9cd4-438c-80e7-69b66af5cbe6';
const TestEfmCourtIdDevApi = '2c787c74-12a7-4113-9fd3-4f599cd114ce';
const TestEfmCourtCaption = 'Test EFM Court 1';

enum CurrentApiForLocal {
  DEV_API = 'dev',
  SIT_API = 'sit',
}

const getTestCourtId = (currentApi: CurrentApiForLocal): string =>
  currentApi === CurrentApiForLocal.DEV_API
    ? TestEfmCourtIdDevApi
    : TestEfmCourtIdSitApi;

export const environment: EnvConfig & MockCourtData = {
  API_VERSION: ApiVersion.v1,
  ApiServer: {
    BaseURL,
  },
  ApplicationInsights: {},
  CONFIG_FILE: ENVIRONMENT.DEV,
  Endpoints: endpoints(versionedUrl),
  Environment: {
    Env: ENVIRONMENT.DEV,
  },
  IdentityServer: {
    BaseURL: BaseIdentityServerURL,
  },
  MAX_FILE_SIZE: 1_000_000,
  NG_APP_VERSION: packageFile.version,
  production: false,

  TEST_EFM_COURT_ID: getTestCourtId(CurrentApiForLocal.SIT_API),
  TEST_EFM_COURT_CAPTION: TestEfmCourtCaption,
};
