import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  CombinedFilingData,
  ContactProfile,
  DocumentInfo,
  DocumentSpec,
  Filing,
  FilingModeSpec,
  FilingProfile,
  ParticipantCommonCategory,
  ParticipantSpec,
  RequestDocument,
} from '@fsx/fsx-shared';
import { Observable, catchError, combineLatest, map, of, take } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from './case-request-data.service';
import {
  FsxFilingDataService,
  IFilingDataService,
} from './filing-data.service';
import {
  FsxFilingProfileDataService,
  IFilingProfileDataService,
} from './filing-profile-data.service';
import {
  FsxDocumentInfoDataService,
  IDocumentInfoDataService,
} from './document-info-data.service';
import {
  FsxFilingModeSpecLookupService,
  IFilingModeSpecLookupService,
} from 'projects/libs/shared/src/lib/services/filings/filing-mode-spec-lookup.service';
import {
  FsxContactProfileDataService,
  IContactProfileDataService,
} from './contact-profile-data.service';

export const FsxCombinedFilingDataService =
  new InjectionToken<ICombinedFilingDataService>(
    'FsxCombinedFilingDataService'
  );

export const FsxCombinedFilingDataService2 =
  new InjectionToken<ICombinedFilingDataService>(
    'FsxCombinedFilingDataService2'
  );

export interface ICombinedFilingDataService {
  combinedFilingData$: Observable<CombinedFilingData>;

  filing$: Observable<Filing>;

  /**
   * an observable of the current FilingModeSpec (originalPetition / subsequent).
   */
  modeSpec$: Observable<FilingModeSpec>;

  /**
   * An observable of all ParticipantSpec objects on the current FilingModeSpec.
   */
  allParticipantSpecs$: Observable<ParticipantSpec[]>;

  /**
   * A helper method to lookup the ParticipantSpec objects on the current FilingModeSpec for a given
   * ParticipantCommonCategory. This is used to help derive the "Party Type" and "Attorney Type" lists.
   *
   * @param commonCategory The ParticipantCommonCategory to filter the collection of ParticipantSpec objects by.
   */
  getParticipantSpecsForCommonCategory(
    commonCategory: ParticipantCommonCategory
  ): Observable<ParticipantSpec[]>;

  /**
   * A helper method to lookup the DocumentSpec object on the current FilingModeSpec for a given RequestDocument.
   *
   * @param document The RequestDocument object taht we want to lookup the DocumentSpec object up for.
   *
   * @returns The DocumentSpec object for the given RequestDocument's catgeory name or null when no spec can be found.
   */
  getDocumentSpecForDocument(
    document: RequestDocument
  ): Observable<DocumentSpec | null>;
}

@Injectable()
export class CombinedFilingDataService implements ICombinedFilingDataService {
  combinedFilingData$: Observable<CombinedFilingData> = combineLatest([
    this.filingDataService.filing$,
    this.caseRequestDataService.caseRequest$,
    this.filingProfileDataService.filingProfile$,
    this.contactProfileDataService.contactProfile$,
    this.documentInfoDataService.documentInfos$,
  ]).pipe(
    map(
      ([filing, caseRequest, filingProfile, contactProfile, documentInfos]: [
        Filing,
        CaseRequestViewModel,
        FilingProfile,
        ContactProfile,
        DocumentInfo[]
      ]) => {
        return {
          filing,
          filingProfile,
          contactProfile,
          caseRequest,
          modeSpec: this.filingModeSpecLookupService.getModeSpec(
            filing.mode,
            filingProfile
          ),
          documentInfos,
        };
      }
    )
  );

  filing$: Observable<Filing> = this.combinedFilingData$.pipe(
    map((combinedFilingData: CombinedFilingData) => {
      return combinedFilingData.filing;
    })
  );

  /**
   * an observable of the current FilingModeSpec (originalPetition / subsequent).
   */
  modeSpec$: Observable<FilingModeSpec> = this.combinedFilingData$.pipe(
    map((combinedFilingData: CombinedFilingData) => {
      return combinedFilingData.modeSpec;
    })
  );

  /**
   * An observable of all ParticipantSpec objects on the current FilingModeSpec.
   */
  allParticipantSpecs$: Observable<ParticipantSpec[]> = this.modeSpec$.pipe(
    map((filingModeSpec: FilingModeSpec) => {
      return filingModeSpec.participant;
    })
  );

  constructor(
    @Inject(FsxFilingDataService)
    private readonly filingDataService: IFilingDataService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxFilingProfileDataService)
    private readonly filingProfileDataService: IFilingProfileDataService,
    @Inject(FsxContactProfileDataService)
    private readonly contactProfileDataService: IContactProfileDataService,
    @Inject(FsxDocumentInfoDataService)
    private readonly documentInfoDataService: IDocumentInfoDataService,
    @Inject(FsxFilingModeSpecLookupService)
    private readonly filingModeSpecLookupService: IFilingModeSpecLookupService
  ) {}

  /**
   * A helper method to lookup the ParticipantSpec objects on the current FilingModeSpec for a given
   * ParticipantCommonCategory. This is used to help derive the "Party Type" and "Attorney Type" lists.
   *
   * @param commonCategory The ParticipantCommonCategory to filter the collection of ParticipantSpec objects by.
   */
  getParticipantSpecsForCommonCategory(
    commonCategory: ParticipantCommonCategory
  ): Observable<ParticipantSpec[]> {
    return this.allParticipantSpecs$.pipe(
      take(1), // Ensures that the stream completes with the values that it returns.
      map((participantSpecs: ParticipantSpec[]) => {
        // Lookup the ParticipantSpec
        return participantSpecs.filter((pSpec) => {
          return pSpec.participantCategory.commonCategory === commonCategory;
        })!;
      }),
      catchError((err, caught) => {
        console.error(
          `Error getting participant specs for common catgeory ${commonCategory}`,
          err
        );
        return caught;
      })
    );
  }

  /**
   * A helper method to lookup the DocumentSpec object on the current FilingModeSpec for a given RequestDocument.
   *
   * @param document The RequestDocument object taht we want to lookup the DocumentSpec object up for.
   *
   * @returns The DocumentSpec object for the given RequestDocument's catgeory name or null when no spec can be found.
   */
  getDocumentSpecForDocument(
    document: RequestDocument
  ): Observable<DocumentSpec | null> {
    return this.modeSpec$.pipe(
      take(1), // Ensures that the stream completes with the values that it returns.
      map((filingModeSpec: FilingModeSpec) => {
        // Guard clause to prevent unnecessary lookup without a category
        if (!document.category) {
          return null;
        }

        // The document specs for lead and documents are held in different properties on the FilingModeSpec obje3ct.
        const documentSpecs: DocumentSpec[] = document.isLeadDocument
          ? filingModeSpec.leadDocument
          : filingModeSpec.supportingDocument;

        // Once we have the list of DocumentSpec objects we can look for the one that matches that
        // of the given RequestDocument's category name.
        const documentSpec: DocumentSpec | undefined = documentSpecs.find(
          (docSpec: DocumentSpec) => {
            return docSpec.documentCategory.name === document.category?.name;
          }
        );

        // We throw an error if we are trying to lookup something that does not exist
        // (should never happen - something gone very wrong if it does!)
        if (!documentSpec) {
          throw 'No DocumentSpec could be found';
        }

        return documentSpec;
      }),
      catchError((err) => {
        console.error(
          `Error getting document spec for document category ${document.category?.name}`,
          err
        );
        return of(null);
      })
    );
  }
}
