import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  AttorneySearchTypeEnum,
  ContactsSearchTypeEnum,
} from '../contacts.model';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  delay,
  map,
  merge,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  ContactQueryResultItem,
  ContactQueryResultItemGridResult,
  ContactViewModel,
  Direction,
  EmailAddressViewModel,
  Filter,
  FsxContactApiService,
  IContactApiService,
  Operator,
  Sort,
  Option,
  ContactSummary,
  ContactListItemViewModel,
  Identification,
  IdentificationCommonCategory,
} from '@fsx/fsx-shared';
import {
  FsxSelectedContactsService,
  ISelectedContactsService,
} from '../contacts-list/selected-contacts.service';
import { SkipAndLimit } from '../../transactions/services/filings.service';
import {
  FsxUserDataService,
  IUserDataService,
} from '../../filing-editor/services/user-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IContactsListTwoService to use at runtime.
 */
export const FsxContactsListTwoService =
  new InjectionToken<IContactsListTwoService>('ContactsListTwoService');

/**
 * A blueprint for a ui service for retrieving contact list data using the
 * stored filters and sorts.
 */
export interface IContactsListTwoService {
  /**
   * The scroll position to set in the contact list.
   */
  scrollPosition$: Observable<number>;

  /**
   * The contact summary records exposed as an Observable.
   */
  contactsData$: Observable<ContactListItemViewModel[]>;

  /**
   * A boolean value indicating whether the contacts are being loaded or not
   * expressed as an Observable.
   */
  isLoadingContacts$: Observable<boolean>;

  /**
   * A method to allow setting of the a search term filter.
   *
   * @param searchTerm The search term we want to set a filter for.
   */
  setSearchTerm(searchTerm: string): void;

  /**
   * A method to allow setting of a sort field.
   *
   * @param column The field we want to sort on.
   */
  setSort(column: string): void;

  /**
   * A method to trigger retrieval of the next page of data.
   */
  nextPage(): void;

  /**
   * A method to trigger the reloading of a single Contact Summary object in
   * the contacts cache without resetting the list.
   */
  reloadContact(contactId: string): void;

  /**
   * A method to trigger reloading of the list with any pre-existing filter.
   */
  reloadAll(): void;

  /**
   * A method for setting of the attorney seach type filter.
   *
   * @param attorneySearchType The attorney search type to set a filter for.
   */
  setAttorneySearchType(attorneySearchType: AttorneySearchTypeEnum): void;

  setContactFilters(): void;

  setAttorneyFilters(): void;

  /**
   * A method to allow all Contact Summary objects to be selected.
   */
  selectAll(): void;

  /**
   * A method to allow all Contact Summary objects to be unselected.
   */
  unselectAll(): void;
}

/**
 * The properties that we pass to the API to get the list of
 * ContactSummary objects back from the server.
 */
interface QueryConfig {
  filter: Filter[];
  sort: Sort[];
  skip: number;
  searchTerm: string | null;
  selectedRow: number;
}

/**
 * A concrete implementation of a ui service for retrieving contact list data
 * using the stored filters and sorts.
 */
@Injectable()
export class ContactsListTwoService implements IContactsListTwoService {
  /**
   * A subject to trigger the setting of the scroll position of the contact list.
   */
  private scrollPosition$$: Subject<number> = new Subject<number>();

  /**
   * The scroll position to set in the contact list.
   */
  scrollPosition$: Observable<number> = this.scrollPosition$$.asObservable();

  /**
   * The search type saved in a BehaviorSubject.
   */
  private searchType$$ = new BehaviorSubject<ContactsSearchTypeEnum>(
    ContactsSearchTypeEnum.contacts
  );

  private options$$ = new BehaviorSubject<Option[]>([]);

  /**
   * The initial sort object (Ascending on Caption by default).
   */
  private initialSort: Sort = {
    column: 'caption',
    direction: Direction.Ascending,
  };

  /**
   * The sort (column and direction) saved in a BehaviorSubject.
   */
  private sort$$ = new BehaviorSubject<Sort>(this.initialSort);

  /**
   * The initial QueryConfig object with no sorts, filters, selections or paging settings set.
   */
  private initialQueryConfig: QueryConfig = {
    filter: [],
    sort: [],
    skip: 0,
    searchTerm: null,
    selectedRow: 0,
  };

  /**
   * The QueryConfig object to use when executing the contactApiService.getContacts() query.
   * We build and set this up using public methods such as setSearchTerm() within this service.
   */
  private queryConfig$$ = new BehaviorSubject<QueryConfig>(
    this.initialQueryConfig
  );

  /**
   * A boolean value indicating whether the contacts are being loaded or not
   * stored in a BehaviorSubject.
   */
  private isLoadingContacts$$ = new BehaviorSubject<boolean>(false);

  /**
   * A boolean value indicating whether the contacts are being loaded or not
   * expressed as an Observable.
   */
  isLoadingContacts$: Observable<boolean> =
    this.isLoadingContacts$$.asObservable();

  /**
   * The initial paging data to retrieve the first page of contacts data.
   */
  private defaultSkipAndLimit: SkipAndLimit = {
    skip: 0,
    limit: 40,
  };

  /**
   * The paging data to pass to subsequent api calls to tell it which page of data to load for.
   */
  private skipAndLimit$$ = new BehaviorSubject<SkipAndLimit>(
    this.defaultSkipAndLimit
  );

  /**
   * A local copy of the last emitted contacts data. We either append to this (when paging)
   * or replace the contents of it (when applying a new filter/sort).
   */
  private contactsCache$$ = new BehaviorSubject<ContactListItemViewModel[]>([]);

  /**
   * The main output stream of ContactSummary objects as loaded from the server.
   *
   * Will emit when:
   * - the search type is changed
   * - the filter (search term) is changed
   * - the sort field or direction is changed.
   * - the paging data (skip and limit) is changed.
   *
   * The resulting data is always appended to the contacts cache (last emitted contacts data).
   * Note however that the contacts cache may be cleared prior to this stream emitting, which
   * gives the appearance of an initial load (by appending to an empty array).
   */
  private loadedContacts$: Observable<ContactListItemViewModel[]> =
    combineLatest([
      this.options$$,
      this.queryConfig$$,
      this.sort$$,
      this.skipAndLimit$$,
    ]).pipe(
      withLatestFrom(
        this.selectedContactsService.selectedContacts$,
        this.contactsCache$$
      ),
      switchMap(
        ([
          [options, queryConfig, sort, skipAndLimit],
          selectedContacts,
          contactsCache,
        ]) => {
          // We're about to trigger an APi call so turn on the loading indicator.
          this.isLoadingContacts$$.next(true);

          // Make the API call to load ContactSummary data.
          return this.contactApiService
            .getContacts({
              skip: skipAndLimit.skip,
              limit: skipAndLimit.limit,
              sort: [sort],
              filters: queryConfig.filter,
              options,
              exactTotal: false,
            })
            .pipe(
              delay(500),
              map((gridResult: ContactQueryResultItemGridResult) => {
                // The API request has complete, so turn off the loading indicator.
                this.isLoadingContacts$$.next(false);

                let resultItems: ContactQueryResultItem[] = gridResult.data;

                // Derive the Contact Summary objects from the grid result returned by the API.
                const contactsData: ContactListItemViewModel[] =
                  resultItems.map((resultItem: ContactQueryResultItem) => {
                    let contactListItem =
                      resultItem.contactSummary as ContactListItemViewModel;

                    // Lookup and set the bar number (set to empty string if not found)
                    const identifications = resultItem.identifications || [];
                    contactListItem.barNumber =
                      identifications.find((identification: Identification) => {
                        return (
                          identification.category.commonCategory ===
                          IdentificationCommonCategory.BarNumber
                        );
                      })?.identificationKey || '';

                    // Re-apply the isSelected flag to highlight if the record was selected.
                    contactListItem.isSelected =
                      selectedContacts.filter(
                        (c: ContactListItemViewModel) =>
                          c.id === contactListItem.id
                      ).length > 0;
                    return contactListItem;
                  });

                // Always append to the cache. In cases where we don't want to append to the cache
                // we must clear the cache prior to triggering this stream to emit.
                const combinedContactsData: ContactListItemViewModel[] = [
                  ...contactsCache,
                  ...contactsData,
                ];

                // Return the output contcts data to dependent streams.
                return combinedContactsData;
              })
            );
        }
      )
    );

  /**
   * A subject to trigger the reloading of a single contact summary object
   * in the contacts cache, without resetting the list.
   */
  private reloadContact$$ = new Subject<string>();

  /**
   * An output stream of ContactSummary objects, derived from the contacts cache with one
   * single contact summary record updated inline from the server. This allows us to update
   * a single record inline without resetting the list or any sorts or filters.
   */
  private updatedContacts$: Observable<ContactListItemViewModel[]> =
    this.reloadContact$$.pipe(
      withLatestFrom(this.contactsCache$$),
      switchMap(
        ([contactId, contactsCache]: [string, ContactListItemViewModel[]]) => {
          return this.contactApiService.getContact(contactId).pipe(
            map((contact: ContactViewModel) => {
              // Find the primary email address to set (the first one in the array).
              const contactEmails: EmailAddressViewModel[] =
                contact.emails || [];
              const primaryEmailAddress = contactEmails.find(
                (_, i) => i === 0
              )?.caption;

              // Derive the ContactSummary object from the Contact object and patch
              // the primaryEmailAddress.
              const updatedContactSummary: ContactListItemViewModel = {
                ...contact,
                primaryEmailAddress,
              };

              // Replace the old ContactSummary object with the updated ContactSummary
              // object inline in the colection.
              const updatedContacts: ContactListItemViewModel[] =
                contactsCache.map(
                  (contactSummary: ContactListItemViewModel) => {
                    return contactSummary.id === contactId
                      ? updatedContactSummary
                      : contactSummary;
                  }
                );

              // Return the updated collection of ContactSummary objects.
              return updatedContacts;
            })
          );
        }
      )
    );

  /**
   * A subject to set the selected state of all ContactSummary objects
   * in the contacts cache, without resetting the list.
   */
  private selectAllContacts$$ = new Subject<boolean>();

  /**
   * An output stream of ContactSummary objects, derived from the contacts cache with
   * all ContactSummary object's isSelected properties set to the selectAllContacts value.
   */
  private selectedContacts$: Observable<ContactListItemViewModel[]> =
    this.selectAllContacts$$.pipe(
      withLatestFrom(this.contactsCache$$),
      map(
        ([selected, contactsCache]: [boolean, ContactListItemViewModel[]]) => {
          // Update each ContactSummary object's isSelected property
          const updatedContacts: ContactListItemViewModel[] = contactsCache.map(
            (contactSummary: ContactListItemViewModel) => {
              contactSummary.isSelected = selected;
              return contactSummary;
            }
          );

          // Get the new list of selected contacts
          const selectedContacts: ContactListItemViewModel[] =
            updatedContacts.filter(
              (contactSummary: ContactListItemViewModel) => {
                return contactSummary.isSelected;
              }
            );

          // Always clear the selected contacts first.
          this.selectedContactsService.clearSelectedContacts();

          // Add any selected contacts to the selected contact service.
          this.selectedContactsService.bulkAddSelectedContacts(
            selectedContacts
          );

          // Return the collection of selected ContactSummary objects.
          return updatedContacts;
        }
      )
    );

  /**
   * The contact summary records exposed as an Observable.
   * - This is where all output streams merge in a single public output stream.
   */
  contactsData$: Observable<ContactListItemViewModel[]> = merge(
    this.updatedContacts$,
    this.loadedContacts$,
    this.selectedContacts$
  ).pipe(
    tap((contactSummaries: ContactListItemViewModel[]) => {
      // The contacts cache always gets set to the last output contacts data.
      this.contactsCache$$.next(contactSummaries);
    })
  );

  /**
   *
   * @param contactApiService The Api service that contains the endpoint to load contact summary data.
   *
   * @param selectedContactsService  The state service that stores the selected contacts. Needed to re-apply on-screen selections between loads.
   */
  constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxSelectedContactsService)
    readonly selectedContactsService: ISelectedContactsService,
    @Inject(FsxUserDataService) readonly userDataService: IUserDataService
  ) {}

  /**
   * A method to allow setting of the a search term filter.
   *
   * @param searchTerm The search term we want to set a filter for.
   */
  setSearchTerm(searchTerm: string): void {
    // A new search has been triggered so clear the cache and reset the paging data.
    this.clearCacheAndResetPagingData();

    // Start will all QueryConfig filters.
    const queryConfigFilters: Filter[] = this.queryConfig$$.value.filter || [];

    // Filter out the search term filter (that we will try to re-apply)
    const noneSearchTermFilters: Filter[] = queryConfigFilters.filter(
      (f: Filter) => {
        return f.column !== 'Search';
      }
    );

    // Create a new filters array to use as the output.
    const updatedFilters: Filter[] = [...noneSearchTermFilters];

    // Check to see if there was a search term supplied. If there was then
    // set a new filter for it and push to the output array.
    if (!!searchTerm) {
      updatedFilters.push({
        column: 'Search',
        operator: Operator.Contains,
        value1: searchTerm,
      });
    }

    // Update the QueryConfig object with the updated filters array.
    const updatedQueryConfig: QueryConfig = {
      ...this.queryConfig$$.value,
      searchTerm: searchTerm,
      filter: updatedFilters,
    };

    // Set the updated QueryConfig object back into the BehaviorSubject.
    this.queryConfig$$.next(updatedQueryConfig);
  }

  /**
   * A method for setting of the attorney seach type filter.
   *
   * @param attorneySearchType The attorney search type to set a filter for.
   */
  setAttorneySearchType(attorneySearchType: AttorneySearchTypeEnum): void {
    this.scrollPosition$$.next(0);

    this.userDataService.contactSummary$
      .pipe(
        take(1),
        tap((contactSummary: ContactSummary) => {
          this.clearCacheAndResetPagingData();

          // Start will all QueryConfig filters.
          const queryConfigFilters: Filter[] =
            this.queryConfig$$.value.filter || [];

          // Filter out the parent org id filter (that we will try to re-apply)
          const noneParentOrgIdFilters: Filter[] = queryConfigFilters.filter(
            (f: Filter) => {
              return f.column !== 'ParentOrgId';
            }
          );

          // Create a new filters array to use as the output.
          const updatedFilters: Filter[] = [...noneParentOrgIdFilters];

          // For "My Firm" we push a new filter specifying those contacts with matching parent organisation.
          if (attorneySearchType === AttorneySearchTypeEnum.Firm) {
            updatedFilters.push({
              column: 'ParentOrgId',
              operator: Operator.Equal,
              value1: contactSummary.parentOrganization?.id,
            });
          }

          // For "Other" we push a new filter specifying those contacts with different parent organisation.
          if (attorneySearchType === AttorneySearchTypeEnum.Other) {
            updatedFilters.push({
              column: 'ParentOrgId',
              operator: Operator.NotEqual,
              value1: contactSummary.parentOrganization?.id,
            });
          }

          // Update the QueryConfig object with the updated filters array.
          const updatedQueryConfig: QueryConfig = {
            ...this.queryConfig$$.value,
            filter: updatedFilters,
          };

          // Set the updated QueryConfig object back into the BehaviorSubject.
          this.queryConfig$$.next(updatedQueryConfig);
        })
      )
      .subscribe();
  }

  /**
   * A method to allow setting of a sort field.
   *
   * @param column The field we want to sort on.
   */
  setSort(column: string): void {
    // A new sort has been triggered so clear the cache and reset the paging data.
    this.clearCacheAndResetPagingData();

    // Setup the new Sort object. Reverse the sort direction if sorting by same
    // column as already sorting by.
    const newSort: Sort = {
      column,
      direction:
        this.sort$$.value.column === column
          ? this.sort$$.value.direction === Direction.Ascending
            ? Direction.Descending
            : Direction.Ascending
          : Direction.Ascending,
    };

    // Apply the sort.
    this.sort$$.next(newSort);
  }

  /**
   * A method to trigger retrieval of the next page of data.
   */
  nextPage(): void {
    // Get the currently active skip and limit values.
    const { skip, limit } = this.skipAndLimit$$.value;

    // Setup the skip and limit values for the next page of data.
    const newSkipAndLimit: SkipAndLimit = {
      limit,
      skip: skip + limit,
    };

    // Apply the new skip and limit values to load the next page of data.
    this.skipAndLimit$$.next(newSkipAndLimit);
  }

  /**
   * A method to trigger the reloading of a single Contact Summary object in
   * the contacts cache without resetting the list.
   */
  reloadContact(contactId: string): void {
    this.reloadContact$$.next(contactId);
  }

  /**
   * A method to trigger reloading of the list with any pre-existing filter.
   */
  reloadAll(): void {
    this.clearCacheAndResetPagingData();
  }

  /**
   * A private function to clear the existing cache and paging data when
   * a new filter or sort is applied.
   */
  private clearCacheAndResetPagingData() {
    this.scrollPosition$$.next(0);
    this.contactsCache$$.next([]);
    this.skipAndLimit$$.next(this.defaultSkipAndLimit);
  }

  setContactFilters(): void {
    this.clearCacheAndResetPagingData();
    this.options$$.next([]);
    this.searchType$$.next(ContactsSearchTypeEnum.contacts);

    // Start will all QueryConfig filters.
    const queryConfigFilters: Filter[] = this.queryConfig$$.value.filter || [];

    // Filter out the common catgeory filter (that we will try to re-apply)
    const noneCommonCategoryFilters: Filter[] = queryConfigFilters.filter(
      (f: Filter) => {
        return f.column !== 'IdentificationCommonCategory';
      }
    );

    // Create a new filters array to use as the output.
    const updatedFilters: Filter[] = [...noneCommonCategoryFilters];

    // Update the QueryConfig object with the updated filters array.
    const updatedQueryConfig: QueryConfig = {
      ...this.queryConfig$$.value,
      filter: updatedFilters,
    };

    // Set the updated QueryConfig object back into the BehaviorSubject.
    this.queryConfig$$.next(updatedQueryConfig);
  }

  setAttorneyFilters(): void {
    this.userDataService.contactSummary$
      .pipe(
        take(1),
        tap((contactSummary: ContactSummary) => {
          const userOrgId: string | undefined =
            contactSummary.parentOrganization?.id;

          this.clearCacheAndResetPagingData();
          this.options$$.next([{ name: 'IncludeIdentifications' }]);
          this.searchType$$.next(ContactsSearchTypeEnum.attorneys);

          // Start will all QueryConfig filters.
          const queryConfigFilters: Filter[] =
            this.queryConfig$$.value.filter || [];

          // Filter out the common catgeory filter (that we will try to re-apply)
          const noneCommonCategoryFilters: Filter[] = queryConfigFilters.filter(
            (f: Filter) => {
              return f.column !== 'IdentificationCommonCategory';
            }
          );

          // Create a new filters array to use as the output.
          const updatedFilters: Filter[] = [...noneCommonCategoryFilters];

          updatedFilters.push({
            column: 'IdentificationCommonCategory',
            operator: Operator.Equal,
            value1: 'BarNumber',
          });

          // If the loged in user's ContacSummary record has a parent org id set, then apply the filter.
          if (userOrgId) {
            updatedFilters.push({
              column: 'ParentOrgId',
              operator: Operator.Equal,
              value1: userOrgId,
            });
          }

          // Update the QueryConfig object with the updated filters array.
          const updatedQueryConfig: QueryConfig = {
            ...this.queryConfig$$.value,
            filter: updatedFilters,
          };

          // Set the updated QueryConfig object back into the BehaviorSubject.
          this.queryConfig$$.next(updatedQueryConfig);
        })
      )
      .subscribe();
  }

  /**
   * A method to allow all Contact Summary objects to be selected.
   */
  selectAll(): void {
    this.selectAllContacts$$.next(true);
  }

  /**
   * A method to allow all Contact Summary objects to be unselected.
   */
  unselectAll(): void {
    this.selectAllContacts$$.next(false);
  }
}
