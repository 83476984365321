import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

export interface IShowHideNavStateService {
  showNav$: Observable<boolean>;

  showNav(showNav: boolean): void;
}

@Injectable({
  providedIn: 'root',
})
export class ShowHideNavStateService implements IShowHideNavStateService {
  /**
   * Behavior Subject storing the state of the new or existing case.
   * @private
   */
  private showNav$$ = new BehaviorSubject<boolean>(false);

  /**
   * Observable storing the state of the new or existing case. This is the one we subscibe to in the component.
   */
  showNav$: Observable<boolean> = this.showNav$$.asObservable();

  /**
   * Sets the state of the isViewingNewOrExisting observable.
   * @param showNav
   */
  showNav(showNav: boolean) {
    this.showNav$$.next(showNav);
  }
}
