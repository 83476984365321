import {
  Actor,
  AdditionalFieldValue,
  Address,
  AddressCategory,
  AliasCategory,
  CaseParty,
  CaseRequest,
  Contact,
  ContactAlias,
  ContactOrganization,
  ContactPerson,
  ContactReference,
  ContactSummary,
  ContactType,
  CurrencyValue,
  DocumentCategory,
  EmailAddress,
  EmailCategory,
  Identification,
  IdentificationCategory,
  NewContact,
  ParticipantCategory,
  PersonalName,
  Phone,
  PhoneCategory,
  RequestCase,
  RequestContactOrganization,
  RequestDocument,
  RequestDocumentCase,
  RequestDocumentParticipant,
  RequestParticipant,
  RequestParticipantAlias,
  RequestParticipantRepresentation,
  UserData,
  ValidationResult,
} from '@fsx/fsx-shared';

export interface IValidatable {
  // errors: ValidationDetail[];
  isValid?: boolean;
}

export class CaseRequestViewModel implements CaseRequest, IValidatable {
  cases?: RequestCaseViewModel[] | null;
  parties?: CasePartyViewModel[] | null;
  participants?: RequestParticipantViewModel[] | null;
  documents?: RequestDocumentViewModel[] | null;
  noteToClerk?: string | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
  isCaseDetailsValid?: boolean;
  isPartiesValid?: boolean;
  isReviewValid?: boolean;
  isDocumentsValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class RequestCaseViewModel implements RequestCase, IValidatable {
  caseId?: string | null | undefined;
  title?: string | null | undefined;
  caption?: string | null | undefined;
  efmKey?: string | null | undefined;
  amountInControversy?: CurrencyValue | null | undefined;
  isComplexCase: boolean = false;
  incidentAddress?: AddressViewModel | null | undefined;
  additionalFieldValues?: AdditionalFieldValue[] | null | undefined;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class CasePartyViewModel implements CaseParty, IValidatable {
  caseId?: string | null | undefined;
  caption!: string;
  participantName!: string;
  efmKey?: string | null | undefined;
  participantCategory?: ParticipantCategory | null | undefined;
  participantSubCategoryNames?: string[] | null | undefined;
  representation?:
    | RequestParticipantRepresentationViewModel[]
    | null
    | undefined;
  isSelfRepresented!: boolean;
  additionalFieldValues?: AdditionalFieldValue[] | null | undefined;

  // errors!: ValidationDetail[];
  isValid?: boolean;
  isRepresentationValid?: boolean;

  /**
   * The index of the party in the caseRequest.parties array. This is used
   * in subsequent PATCH requests to the server when attempting updates.
   */
  caseRequestPartyIndex?: number;
}

// tslint:disable-next-line: max-classes-per-file
export class RequestParticipantRepresentationViewModel
  implements RequestParticipantRepresentation, IValidatable
{
  caption!: string;
  participantName!: string;
  efmKey?: string | null | undefined;
  participantCategory?: ParticipantCategory | null | undefined;
  additionalFieldValues?: AdditionalFieldValue[] | null | undefined;
  parentOrganizationCaption?: string | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class RequestParticipantViewModel
  implements RequestParticipant, IValidatable
{
  linkedContact?: ContactReferenceViewModel | null;
  name!: string;
  parentOrganizationCaption?: string | null;
  aliases?: RequestParticipantAliasViewModel[] | null;
  caption!: string;
  contactType!: ContactType;
  person?: ContactPersonViewModel | null;
  organization?: RequestContactOrganizationViewModel | null;
  addresses!: AddressViewModel[];
  emails!: EmailAddressViewModel[];
  identifications!: IdentificationViewModel[];
  phones!: PhoneViewModel[];

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class ContactReferenceViewModel
  implements ContactReference, IValidatable
{
  id!: string;
  caption!: string;
  contactType!: ContactType;
  clientNameText?: string | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class RequestParticipantAliasViewModel
  implements RequestParticipantAlias, IValidatable
{
  category!: AliasCategory;
  caption!: string;
  contactType!: ContactType;
  person?: ContactPersonViewModel | null;
  organization?: RequestContactOrganizationViewModel | null;
  addresses!: AddressViewModel[];
  emails!: EmailAddressViewModel[];
  identifications!: IdentificationViewModel[];
  phones!: PhoneViewModel[];

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class ContactPersonViewModel implements ContactPerson, IValidatable {
  personalName!: PersonalNameViewModel;
  dateOfDeath?: string | null;
  dateOfBirth?: string | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class PersonalNameViewModel implements PersonalName, IValidatable {
  givenName?: string | null | undefined;
  middleName?: string | null | undefined;
  surName?: string | null | undefined;
  prefix?: string | null | undefined;
  suffix?: string | null | undefined;
  fullName!: string;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class RequestContactOrganizationViewModel
  implements RequestContactOrganization, IValidatable
{
  title!: string;
  caption!: string;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class AddressViewModel implements Address, IValidatable {
  category?: AddressCategory | null;
  caption!: string;
  addressLine1!: string;
  addressLine2?: string | null;
  locality!: string;
  administrativeArea?: string | null;
  postalCode!: string;
  postalCodeExtension?: string | null;
  country!: string;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class EmailAddressViewModel implements EmailAddress, IValidatable {
  category?: EmailCategory | null;
  caption!: string;
  address!: string;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class IdentificationViewModel implements Identification, IValidatable {
  category!: IdentificationCategory;
  identificationKey!: string;
  regionKey!: string;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class PhoneViewModel implements Phone, IValidatable {
  category?: PhoneCategory | null;
  caption!: string;
  countryKey?: string | null;
  fullNumber!: string;
  suffix?: string | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class RequestDocumentViewModel implements RequestDocument, IValidatable {
  id?: string | null;
  name!: string;
  title!: string;
  fileName!: string;
  efmKey?: string | null;
  categoryName?: string | null;
  category?: DocumentCategory | null;
  accessCategoryName?: string | null;
  isLeadDocument!: boolean;
  cases?: RequestDocumentCaseViewModel[] | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
  isNew?: boolean = true;
  isFileSizeTooLarge?: boolean;
}

export class RequestDocumentCaseViewModel
  implements RequestDocumentCase, IValidatable
{
  caseId?: string | null;
  filedBy?: RequestDocumentParticipantViewModel[] | null;
  filedAsTo?: RequestDocumentParticipantViewModel[] | null;
  linkedDocuments?: any[] | null;
  additionalFieldValues?: AdditionalFieldValue[] | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
  isNew?: boolean = true;
}

export class RequestDocumentParticipantViewModel
  implements RequestDocumentParticipant, IValidatable
{
  participantName!: string;
  additionalFieldValues?: AdditionalFieldValue[] | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class ContactSummaryViewModel implements ContactSummary, IValidatable {
  id!: string;
  type!: ContactType;
  caption!: string;
  primaryEmailAddress?: string | null;
  parentOrganization?: ContactReference | null;
  clientNameText?: string | null;

  // errors!: ValidationDetail[];
  isValid?: boolean;
  isSelected?: boolean;
}

export class ContactListItemViewModel extends ContactSummaryViewModel {
  barNumber?: string;
}

// tslint:disable-next-line: max-classes-per-file
export class ContactViewModel implements Contact, IValidatable {
  type!: ContactType;
  caption!: string;
  user?: UserData | null;
  actor?: Actor | null;
  person?: ContactPersonViewModel | null;
  organization?: ContactOrganizationViewModel | null;
  addresses!: AddressViewModel[];
  emails!: EmailAddressViewModel[];
  identifications!: IdentificationViewModel[];
  phones!: PhoneViewModel[];
  aliases!: ContactAliasViewModel[];
  parentOrganization?: ContactReference | null;
  effectiveClientNameText?: string | null;
  organizationId!: string;
  id!: string;
  createdBy!: string;
  createdAt!: string;
  modifiedBy!: string;
  modifiedAt!: string;
  bookmarked!: boolean;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class ContactAliasViewModel implements ContactAlias, IValidatable {
  category!: AliasCategory;
  contactType!: ContactType;
  caption!: string;
  person?: ContactPersonViewModel | null;
  organization?: ContactOrganizationViewModel | null;
  addresses!: AddressViewModel[];
  emails!: EmailAddressViewModel[];
  identifications!: IdentificationViewModel[];
  phones!: PhoneViewModel[];

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class ContactOrganizationViewModel
  implements ContactOrganization, IValidatable
{
  primaryContact?: ContactReference | null;
  clientNameText?: string | null;
  title!: string;
  caption!: string;

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export class NewContactViewModel implements NewContact, IValidatable {
  contactType!: ContactType;
  aliases!: ContactAliasViewModel[];
  parentOrganization?: ContactReference | null;
  caption!: string;
  person?: ContactPersonViewModel | null;
  organization?: ContactOrganizationViewModel | null;
  addresses!: AddressViewModel[];
  emails!: EmailAddressViewModel[];
  identifications!: IdentificationViewModel[];
  phones!: PhoneViewModel[];

  // errors!: ValidationDetail[];
  isValid?: boolean;
}

// tslint:disable-next-line: max-classes-per-file
export interface ValidationResultViewModel extends ValidationResult {
  status: number;
}
