import {
  AuthConfig,
  LoginOptions,
  OAuthModuleConfig,
  OAuthStorage,
} from 'angular-oauth2-oidc';
export const FsxB2CDocumentDiscoveryURL =
  'https://login.dev.fileandserve.com/fileandservexpressdev.onmicrosoft.com/B2C_1A_DEMO_SUSI_USERNAME_SEAMLESSMIGRATION/v2.0/.well-known/openid-configuration';
export const FsxB2CIssuer =
  'https://login.dev.fileandserve.com/fileandservexpressdev.onmicrosoft.com/b2c_1a_demo_susi_username_seamlessmigration/v2.0/';
const FsxB2CClientId = '6fb825e4-a08e-4680-bed4-c2594aefd582';
const FsxB2CScopes =
  'openid profile email offline_access https://fileandservexpressdev.onmicrosoft.com/6fb825e4-a08e-4680-bed4-c2594aefd582/read https://fileandservexpressdev.onmicrosoft.com/6fb825e4-a08e-4680-bed4-c2594aefd582/write';
const FsxB2CLoginUrl =
  'https://login.dev.fileandserve.com/fileandservexpressdev.onmicrosoft.com/B2C_1A_DEMO_SUSI_USERNAME_SEAMLESSMIGRATION/oauth2/v2.0/authorize';
const FsxB2CLogoutUrl =
  'https://login.dev.fileandserve.com/fileandservexpressdev.onmicrosoft.com/signout/B2C_1A_DEMO_SUSI_USERNAME_SEAMLESSMIGRATION';
const FsxTenantId = '6c17c8ba-3735-4796-86a2-e3b81f20f219';

// Important: Request offline_access to get a refresh token
// The api scope is a usecase specific one
export const authCodeFlowConfig = (
  identityServerBaseUrl: string
): AuthConfig => {
  // ToDO: For reference - This is the old flow for use with identity
  // return {
  //   clearHashAfterLogin: false,
  //   clientId: 'efsp',
  //   issuer: `${identityServerBaseUrl}`,
  //   sessionChecksEnabled: false,
  //   loginUrl: `${identityServerBaseUrl}/Account/Login`,
  //   logoutUrl: `${identityServerBaseUrl}/Account/Login`,
  //   redirectUri: `${window.location.origin}/index.html`,
  //   oidc: true,
  //   responseType: 'code',
  //   scope: 'openid profile organization efsp offline_access',
  //   showDebugInformation: true,
  //   useSilentRefresh: true,
  // };
  // ---------- Start of using B2C -----------
  return {
    // clearHashAfterLogin: false,
    issuer: FsxB2CIssuer,
    loginUrl: FsxB2CLoginUrl,
    logoutUrl: FsxB2CLogoutUrl,
    // ToDo: I believe we need to register all possible returnUri's in the Azure AD B2C App Registration
    redirectUri: window.location.origin + '/index.html',
    // silentRefreshRedirectUri: window.location.origin + '/silent-refresh.html',
    clientId: FsxB2CClientId,
    responseType: 'code',
    strictDiscoveryDocumentValidation: false,
    scope: FsxB2CScopes,
    showDebugInformation: false,
    skipIssuerCheck: true,
    oidc: true,
    // useSilentRefresh: true,
    // sessionChecksEnabled: false,
  };
};

export const tryLoginOptions: LoginOptions = {
  /**
   * Set this to true to disable the oauth2 state check which is a best practice to avoid
   * security attacks. .. As OIDC defines a nonce check that includes
   * this, this can be set to true when only doing OIDC.
   */
  disableOAuth2StateCheck: true,
  /**
   * Set this to true to disable the nonce check which is used to avoid replay attacks.
   * This flag should never be true in production environments.
   */
  disableNonceCheck: false,
  /**
   * Normally, you want to clear your hash fragment after the lib reads the token(s) so that
   * they are not displayed anymore in the url. If not, set this to true. For code flow
   * this controls removing query string values.
   */
  preventClearHashAfterLogin: true,
  /**
   * Set this for code flow if you used a custom redirect Uri when retrieving the code. This is
   * used internally for silent refresh and popup flows.
   */
  // customRedirectUri: '',
};

export const authModuleConfig = (
  identityServerBaseUrl: string
): OAuthModuleConfig => {
  return {
    resourceServer: {
      allowedUrls: [`${identityServerBaseUrl}`],
      sendAccessToken: true,
    },
  };
};

export function storageFactory(): OAuthStorage {
  return localStorage;
}
