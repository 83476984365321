import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import {
  AddressProfileSpec,
  ContactProfile,
  ContactViewModel,
  FsxContactApiService,
  IContactApiService,
  IdentificationCommonCategory,
  IdentificationViewModel,
  NamedList,
  NamedListItem,
} from '@fsx/fsx-shared';
import {
  FsxContactProfileDataService,
  IContactProfileDataService,
} from '../../filing-editor/services/contact-profile-data.service';
import { Observable, catchError, combineLatest, map } from 'rxjs';

export interface StateAndBarNumber {
  /**
   * The state that the bar number is associated with.
   */
  state: string;

  /**
   * The bar number identification object
   */
  barNumber: IdentificationViewModel;
}

export interface ContactDetailViewModel {
  /**
   * The contact object to display in the detail view.
   */
  contact: ContactViewModel | null;

  /**
   * The contact profile from which we derive rules from.
   */
  contactProfile: ContactProfile;

  /**
   * The default address profile to format the address with.
   */
  addressProfileSpec: AddressProfileSpec;

  /**
   * The array of "state - bar number" strings.
   */
  stateAndBarNumbers: StateAndBarNumber[];
}

@Component({
  selector: 'fsx-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss'],
})
export class ContactDetailComponent implements OnChanges {
  /**
   * The id of the contact that is to be loaded and displayed in this ContactDetailComponent.
   */
  @Input() contactId: string | null = null;

  /**
   * An output event to allow the parent panel component to open the edit form.
   */
  @Output() editContactEvent = new EventEmitter<void>();

  /**
   * The derived view model object to pass to the view.
   */
  vm$!: Observable<ContactDetailViewModel>;

  constructor(
    @Inject(FsxContactProfileDataService)
    private readonly contactProfileDataService: IContactProfileDataService,
    @Inject(FsxContactApiService) readonly contactApiService: IContactApiService
  ) {}

  ngOnChanges(): void {
    this.deriveViewModel();
  }

  /**
   * A method to derive the view model object.
   */
  private deriveViewModel(): void {
    // We must at least have a contact.
    if (this.contactId) {
      this.vm$ = combineLatest([
        this.contactApiService.getContact(this.contactId),
        this.contactProfileDataService.contactProfile$,
        this.contactProfileDataService.getDefaultAddressProfile(),
        this.contactProfileDataService.getIdentificationRegionAdditionalList(),
      ]).pipe(
        map(
          ([
            contact,
            contactProfile,
            defaultAddressProfileSpec,
            identificationRegionList,
          ]: [
            ContactViewModel,
            ContactProfile,
            AddressProfileSpec,
            NamedList
          ]) => {
            // Lookup the bar numbers in the identifications array.
            const barNumbers: IdentificationViewModel[] =
              contact.identifications.filter(
                (identification: IdentificationViewModel) => {
                  return (
                    identification.category.commonCategory ===
                    IdentificationCommonCategory.BarNumber
                  );
                }
              );

            // Derive an object of the state names with their bar numbers.
            const stateAndBarNumbers: StateAndBarNumber[] = barNumbers.map(
              (identification: IdentificationViewModel) => {
                const stateName: string =
                  identificationRegionList.items.find(
                    (namedListItem: NamedListItem) => {
                      // STAKE KEY MAPPING HACK. Copied from other part of the app where states are being displayed...
                      // This is necessary because the saved keys (e.g. "usa.nj") are different to the actual list keys (e.g "NJ").
                      const item = identification.regionKey
                        .toLowerCase()
                        .includes(namedListItem.name.toLowerCase());
                      return item;
                    }
                  )?.caption || 'Unknown';

                // Return the state name with the bar number identification object.
                return {
                  state: stateName,
                  barNumber: identification,
                };
              }
            );

            return {
              contact,
              contactProfile,
              addressProfileSpec: defaultAddressProfileSpec,
              stateAndBarNumbers,
            };
          }
        ),
        catchError((err, caught) => {
          console.error(err);
          return caught;
        })
      );
    }
  }

  /**
   * A handler method for the edit icon's click event.
   */
  onEditIconClicked(): void {
    this.editContactEvent.emit();
  }
}
