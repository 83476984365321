import {
  CaseRequestViewModel,
  FsxValidationService,
  IValidationService,
  ValidationResultsViewModel,
} from '@fsx/fsx-shared';
import { Moment } from 'moment';
import { Component, Inject, Input, OnDestroy, OnInit } from '@angular/core';
import { CombinedFilingData } from '@fsx/fsx-shared';
import { Observable, Subject, takeUntil, tap, withLatestFrom } from 'rxjs';
import {
  FsxFilingChecklistService,
  IFilingChecklistService,
} from './filing-checklist.service';
import {
  FsxValidationGroupErrorsService,
  IValidationGroupErrorsService,
  ValidationGroupError,
} from '../../filing-editor/services/validation-group-errors.service';

interface ErrorDetails {
  caption: string;
  count?: number;
  showCount: boolean;
}

@Component({
  selector: 'fsx-filing-checklist',
  templateUrl: './filing-checklist.component.html',
  styleUrls: ['./filing-checklist.component.scss'],
})
export class FilingChecklistComponent implements OnInit, OnDestroy {
  @Input() combinedFilingData$!: Observable<CombinedFilingData>;
  @Input() dataRebind$!: Observable<Moment>;

  isVisible$ = this.filingChecklistService.isVisible$;
  isCaseValid$ = this.filingChecklistService.isValidCrossTab$;

  totalErrors: number = 0;
  errors: ErrorDetails[] = [];
  serverValidationErrors!: ValidationResultsViewModel[];

  /**
   * The grouped validation errors which we iterate over to display in the checklist.
   */
  validationGroupErrors$: Observable<ValidationGroupError[]> =
    this.validationGroupErrorsService.validationGroupErrros$;

  private destroy$: Subject<unknown> = new Subject();

  constructor(
    @Inject(FsxValidationGroupErrorsService)
    private readonly validationGroupErrorsService: IValidationGroupErrorsService,
    @Inject(FsxFilingChecklistService)
    private readonly filingChecklistService: IFilingChecklistService,
    @Inject(FsxValidationService)
    private readonly validationService: IValidationService
  ) {}

  ngOnInit(): void {
    this.dataRebind$
      .pipe(
        takeUntil(this.destroy$),
        withLatestFrom(this.combinedFilingData$),
        tap(([_, combinedFilingData]) => {
          this.setTabsValidity(combinedFilingData.caseRequest);
        })
      )
      .subscribe();

    this.validationService.serverValidationErrors$
      .pipe(
        takeUntil(this.destroy$),
        tap((errs) => {
          this.serverValidationErrors = errs;

          if (errs && errs.length > 0) {
            this.filingChecklistService.setVisibility(true);
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  private setTabsValidity(caseRequest: CaseRequestViewModel): void {
    this.errors = [];

    if (caseRequest) {
      this.addDetailsErrors(caseRequest);
      this.addParticipantErrors(caseRequest);
      this.addDocumentErrors(caseRequest);
      this.addReviewErrors(caseRequest);
    }

    this.totalErrors = this.errors.reduce(
      (acc, curr) => acc + (curr.count ?? 0),
      0
    );
  }

  private addDocumentErrors(caseRequest: CaseRequestViewModel): void {
    if (!caseRequest.documents) {
      return;
    }

    const count =
      caseRequest.documents.reduce(
        (acc, curr) => (curr.isValid === false ?? true ? acc + 1 : acc),
        0
      ) ?? 0;

    if (count > 0) {
      this.errors.push({
        caption: 'document' + (count === 1 ? '' : 's'),
        count,
        showCount: true,
      });
    }
  }

  private addParticipantErrors(caseRequest: CaseRequestViewModel): void {
    if (!caseRequest.documents) {
      return;
    }

    let count: number = 0;

    caseRequest.parties?.forEach((party) => {
      const repsAreInvalid = party.representation?.some(
        (r) => r.isValid === false
      );

      if (
        party.isValid === false ||
        party.isRepresentationValid === false ||
        repsAreInvalid
      ) {
        count++;
      }
    });

    if (count > 0) {
      this.errors.push({
        caption: count === 1 ? 'party' : 'parties',
        count,
        showCount: true,
      });
    }
  }

  private addDetailsErrors(caseRequest: CaseRequestViewModel) {
    if (!caseRequest.cases || caseRequest.cases.length === 0) {
      return;
    }

    // TODO - cater for multiple cases
    const requestCase = caseRequest.cases[0];

    if (requestCase.isValid === false) {
      this.errors.push({
        count: 1,
        caption: 'Case Details',
        showCount: false,
      });
    }
  }

  private addReviewErrors(caseRequest: CaseRequestViewModel) {
    if (caseRequest.isReviewValid === false) {
      this.errors.push({
        count: 1,
        caption: 'Review & Submit',
        showCount: false,
      });
    }
  }

  close() {
    this.filingChecklistService.setVisibility(false);
  }
}
