<div
  class="fsx-single-selection-component"
  *ngIf="dropdownOptions"
  [ngStyle]="{
    width: width,
    display: visible ? 'block' : 'none',
    height: height
  }"
  [ngClass]="{ readonly: readOnly }"
>
  <ng-container *ngIf="!readOnly; else readOnlyView">
    <label>
      {{ caption }}
      <span class="required" *ngIf="required && showAsterisk">*</span>
      <mat-icon *ngIf="helpText" [matTooltip]="helpText">info_outline</mat-icon>
    </label>

    <ng-container *ngIf="errorPosition === ErrorPosition.ABOVE">
      <p class="error-message">
        {{
          (formControlTemp.invalid && formControlTemp.touched) ||
          (formControl.invalid && formControl.touched)
            ? getErrorMessage()
            : ""
        }}
      </p>
    </ng-container>

    <div class="input-container">
      <input
        #input
        autocomplete="never"
        [formControl]="formControlTemp"
        [placeholder]="hint"
        [ngClass]="{
          invalid:
            (formControlTemp.invalid && formControlTemp.touched) ||
            (formControl.invalid && formControl.touched)
        }"
        [matAutocomplete]="auto"
        [title]="selectedSingleOptionCaption"
        [required]="required"
        (keydown.tab)="selectOption()"
        (keydown.ArrowUp)="setFocusedElement()"
        (keydown.ArrowDown)="setFocusedElement()"
        (keydown.enter)="preventEvent($event)"
        (change)="valueChange($event)"
        (click)="inputTouched()"
        (blur)="inputTouched()"
      />
      <div class="arrow-container">
        <button
          *ngIf="!formControl.value || required"
          class="icon-button"
          (click)="input.focus()"
          (keydown.enter)="input.focus()"
        >
          <span
            class="material-icons arrow-down"
            [ngClass]="{ disabled: formControlTemp.disabled }"
          >
            expand_more
          </span>
        </button>

        <button
          class="icon-button"
          (keydown.enter)="clearSelection(); input.focus()"
          (click)="clearSelection(); input.focus()"
          *ngIf="formControl.value && clearable && !required"
        >
          <span
            class="material-icons-outlined cancel"
            [ngClass]="{ disabled: formControlTemp.disabled }"
          >
            cancel
          </span>
        </button>
      </div>
    </div>

    <ng-container *ngIf="errorPosition === ErrorPosition.BELOW">
      <p class="error-message">
        {{
          (formControlTemp.invalid && formControlTemp.touched) ||
          (formControl.invalid && formControl.touched)
            ? getErrorMessage()
            : ""
        }}
      </p>
    </ng-container>

    <mat-autocomplete
      #auto="matAutocomplete"
      [displayWith]="selectionDisplayFn"
      (closed)="onSingleSelectDropdownClose()"
      (optionSelected)="optionSelected($event)"
    >
      <mat-option
        *ngFor="let option of filteredOptions$ | async"
        [value]="option"
        [title]="option.caption"
      >
        {{ option.caption }}
      </mat-option>

      <mat-option class="dropdown-option-link" *ngIf="dropdownOptionLink">
        {{ dropdownOptionLink }}
      </mat-option>
    </mat-autocomplete>
  </ng-container>

  <ng-template #readOnlyView>
    <div class="read-only-container">
      <p class="readonly-text">{{ selectedSingleOptionCaption }}</p>
      <label class="readonly-label">{{ caption }}</label>
    </div>
  </ng-template>
</div>

<fsx-additional-fields-component
  *ngFor="let addlFieldSpec of additionalFieldSpec; let index = index"
  [additionalFieldSpec]="addlFieldSpec"
  [resolver]="resolver"
  [initialValues]="
    resolver.getAdditionalFieldInitialValue(
      addlFieldSpec,
      additionalFieldValues
    )
  "
  [additionalFieldValues]="additionalFieldValues"
  [combinedFilingData]="combinedFilingData"
  (additionalFieldValueEvent)="additionalFieldValueEventHandler($event)"
  (filesUploadedFromAdditionalFieldEvent)="
    filesUploadedFromSelectionFieldEventHandler($event)
  "
  (fileRemovedFromAdditionalFieldEvent)="
    fileRemovedFromSelectionFieldEventHandler($event)
  "
></fsx-additional-fields-component>
