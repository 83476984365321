import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  RequestDocumentViewModel,
  DocumentSpec,
  CaseRequestViewModel,
  RequestDocumentCaseViewModel,
  ParticipantFieldSpec,
  RequestDocumentParticipantViewModel,
  IValidatable,
  DocumentCommonCategory,
  FilingModeSpec,
  FilingProfile,
} from '../../../types';
import {
  FsxAdditionalFieldsValidationService,
  IAdditionalFieldsValidationService,
} from './additional-fields-validation.service';
import {
  FsxDocumentFilesValidationService,
  IDocumentFilesValidationService,
} from './document-files-validation.service';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-errors.service';
import { ValidationGroupConstants } from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-group-errors.service';

export const FsxDocumentValidationService =
  new InjectionToken<IDocumentValidationService>(
    'FsxDocumentValidationService'
  );

export interface IDocumentValidationService {
  validateAllDocuments(
    caseRequest: CaseRequestViewModel, // Needed to pass the documents that we want to validate
    modeSpec: FilingModeSpec, // Needed for the documentSpecs that we want to validate against
    filingProfile: FilingProfile, // Only needed down stream by other validation services (Do they really need it?)
    includedDocumentIds: string[]
  ): boolean;

  validateDocument(
    document: RequestDocumentViewModel,
    spec: DocumentSpec,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;
}

@Injectable()
export class DocumentValidationService implements IDocumentValidationService {
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxDocumentFilesValidationService)
    private readonly documentFilesValidationService: IDocumentFilesValidationService,
    @Inject(FsxAdditionalFieldsValidationService)
    private readonly additionalFieldsValidationService: IAdditionalFieldsValidationService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService
  ) {}

  public validateAllDocuments(
    caseRequest: CaseRequestViewModel,
    modeSpec: FilingModeSpec,
    filingProfile: FilingProfile,
    includedDocumentIds: string[]
  ): boolean {
    let result = true;

    const caseRequestDocuments: RequestDocumentViewModel[] =
      caseRequest.documents || [];

    // Check against modeSpec-level rules first...
    const countOfLeadDocuments: number = caseRequestDocuments.filter(
      (document: RequestDocumentViewModel) => {
        return document.isLeadDocument;
      }
    ).length;
    const maxLeadDocsErrorCode = 'maxLeadDocs';
    const documentOrDocuments: string =
      modeSpec.maxLeadDocumentsAllowed === 1 ? 'Document' : 'Documents';
    if (countOfLeadDocuments > modeSpec.maxLeadDocumentsAllowed) {
      result = false;
      this.validationErrorsService.addValidationError({
        errorCode: maxLeadDocsErrorCode,
        errorMessage: `Must not provide more than ${modeSpec.maxLeadDocumentsAllowed} Lead ${documentOrDocuments}`,
        group: ValidationGroupConstants.documents,
      });
    } else {
      this.validationErrorsService.removeValidationError(maxLeadDocsErrorCode);
    }

    const minLeadDocsErrorCode = 'minLeadDocs';
    const documentOrDocuments2: string =
      modeSpec.minLeadDocumentsRequired === 1 ? 'Document' : 'Documents';
    if (countOfLeadDocuments < modeSpec.minLeadDocumentsRequired) {
      result = false;
      this.validationErrorsService.addValidationError({
        errorCode: minLeadDocsErrorCode,
        errorMessage: `Must provide at least ${modeSpec.minLeadDocumentsRequired} Lead ${documentOrDocuments2}`,
        group: ValidationGroupConstants.documents,
      });
    } else {
      this.validationErrorsService.removeValidationError(minLeadDocsErrorCode);
    }

    // Check against documentSpec-level rules next...
    modeSpec.leadDocument.forEach(
      (docSpec: DocumentSpec, docSpecIndex: number) => {
        const documentsForSpec: RequestDocumentViewModel[] =
          caseRequestDocuments.filter((doc: RequestDocumentViewModel) => {
            const documentCategoryName = doc.category?.name || '';
            return documentCategoryName === docSpec.documentCategory.name;
          });

        const countForSpec: number = documentsForSpec.length;
        const docSpecMinErrorCode: string = 'docSpecMin' + docSpecIndex;
        const documentOrDocuments: string =
          docSpec.file?.minRequired === 1 ? 'Document' : 'Documents';
        const docSpecFileMinRequired: number = docSpec.file?.minRequired || 0;
        if (countForSpec < docSpecFileMinRequired) {
          result = false;
          this.validationErrorsService.addValidationError({
            errorCode: docSpecMinErrorCode,
            errorMessage: `Must provide at least ${docSpec.file?.minRequired} ${documentOrDocuments} of type ${docSpec.documentCategory.caption}`,
            group: ValidationGroupConstants.documents,
          });
        } else {
          this.validationErrorsService.removeValidationError(
            docSpecMinErrorCode
          );
        }
      }
    );

    if (modeSpec?.leadDocument && caseRequest.documents) {
      if (
        !this.validateDocuments(
          modeSpec?.leadDocument,
          DocumentCommonCategory.LeadDocument,
          caseRequest.documents,
          caseRequest,
          filingProfile,
          caseRequest,
          includedDocumentIds
        )
      ) {
        result = false;
      }
    }

    if (modeSpec?.supportingDocument && caseRequest.documents) {
      if (
        !this.validateDocuments(
          modeSpec?.supportingDocument,
          DocumentCommonCategory.SupportingDocument,
          caseRequest.documents,
          caseRequest,
          filingProfile,
          caseRequest,
          includedDocumentIds
        )
      ) {
        result = false;
      }
    }

    return result;
  }

  private validateDocuments(
    documentsSpecs: DocumentSpec[],
    commonCategory: DocumentCommonCategory,
    documents: RequestDocumentViewModel[],
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel,
    includedDocumentIds: string[]
  ): boolean {
    let result = true;

    // This probably isn't necessary but kept in to be on the safe side...
    // - We're filtering leadDocument specs by a common catgeory of DocumentCommonCategory.LeadDocument
    // - Can leadDocuments array contain documentSpecs of a different DocumentCommonCategory?
    const documentSpecsForCommonCategory: DocumentSpec[] =
      documentsSpecs.filter((docSpec: DocumentSpec) => {
        return docSpec.documentCategory.commonCategory === commonCategory;
      });

    // Use the includedDocumentIds array of GUIDs to determine which parties to validate.
    const documentsToValidate: RequestDocumentViewModel[] =
      includedDocumentIds.length > 0
        ? documents.filter((document: RequestDocumentViewModel) => {
            const isDocumentToValidate: boolean =
              includedDocumentIds.indexOf(document.id!) > -1;
            return isDocumentToValidate;
          })
        : documents;

    for (const document of documentsToValidate) {
      const documentCategoryName: string | undefined = document.category?.name;

      const noDocumentCategoryErrorCode = 'noDocumentCategoryErrorCode';
      if (!documentCategoryName) {
        // We don't have a document category ("Document Type" in the UI) so we
        // can't map to any spec object, we raise a validation error instead.
        this.validationErrorsService.addValidationError({
          errorCode: noDocumentCategoryErrorCode,
          errorMessage: `Must provide a document type`,
          group: ValidationGroupConstants.documents,
          metadata: {
            parentEntityId: document.id!,
            formSectionName: 'Document Type',
          },
        });
        document.isValid = false;
        result = false;
      } else {
        // We do have a document category ("Document Type" in the UI) so we
        // can continue to validate the document against the document spec.
        const documntSpec: DocumentSpec | undefined =
          documentSpecsForCommonCategory.find((docSpec: DocumentSpec) => {
            return docSpec.documentCategory.name === documentCategoryName;
          });

        this.validationErrorsService.removeValidationError(
          noDocumentCategoryErrorCode,
          document.id!
        );

        if (documntSpec) {
          if (
            !this.validateDocument(
              document,
              documntSpec,
              scope,
              filingProfile,
              caseRequest
            )
          ) {
            result = false;
          }
        }
      }
    }

    return result;
  }

  public validateDocument(
    document: RequestDocumentViewModel,
    spec: DocumentSpec,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    document.isValid = true;
    let result = true;

    // if it's newly added, don't validate, so as not to overwhelm the user
    if (document.isNew) {
      return true;
    }

    if (!document.category || !document.category.name) {
      result = false;
      this.validationHelperService.markItemAsInvalid(document, scope);
    }

    if (
      !this.textFieldValidationService.validateTextField(
        document,
        filingProfile,
        spec.fileName,
        document.fileName
      )
    ) {
      result = false;
      this.validationHelperService.markItemAsInvalid(document, scope);
    }

    if (
      !this.textFieldValidationService.validateTextField(
        document,
        filingProfile,
        spec.title,
        document.title
      )
    ) {
      result = false;
      this.validationHelperService.markItemAsInvalid(document, scope);
    }

    // Temporarily disabled document file validation as does not play well
    // with the polling (creating false positives). Can re-enable when polling
    // and validation generally have more tests.
    const validateDocumentInfos = false;
    if (validateDocumentInfos) {
      if (document.id) {
        if (
          !this.documentFilesValidationService.validateDocumentFiles(
            [document.id],
            spec.file,
            scope,
            document
          )
        ) {
          result = false;
          this.validationHelperService.markItemAsInvalid(document, scope);
        }
      } else {
        result = false;
        this.validationHelperService.markItemAsInvalid(document, scope);
      }
    }

    if (document.cases) {
      for (let documentCase of document.cases) {
        if (
          !this.validateDocumentParticipant(
            documentCase,
            spec.filedBy,
            documentCase.filedBy,
            document,
            filingProfile,
            caseRequest,
            document.id!
          )
        ) {
          result = false;
          this.validationHelperService.markItemAsInvalid(document, scope);
        }

        if (
          !this.validateDocumentParticipant(
            documentCase,
            spec.asTo,
            documentCase.filedAsTo,
            scope,
            filingProfile,
            caseRequest,
            document.id!
          )
        ) {
          result = false;
          this.validationHelperService.markItemAsInvalid(document, scope);
        }

        if (
          !this.additionalFieldsValidationService.validateAdditionalFields(
            documentCase.additionalFieldValues,
            spec.additionalFields,
            documentCase.caseId,
            caseRequest,
            filingProfile,
            caseRequest
          )
        ) {
          result = false;
          this.validationHelperService.markItemAsInvalid(document, scope);
        }
      }
    }

    return true;
  }

  private validateDocumentParticipant(
    documentCase: RequestDocumentCaseViewModel,
    spec: ParticipantFieldSpec | null | undefined,
    documentParticipants:
      | RequestDocumentParticipantViewModel[]
      | null
      | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel,
    parentEntityId?: string
  ): boolean {
    let result = true;

    if (!spec || !spec.participantFieldDefinition) {
      return true;
    }

    if (!documentParticipants) {
      documentParticipants = [];
    }

    // Validate against Associate Party here...
    const minAssociatedPartiesErrorCode = 'minAssociatedPartiesErrorCode';
    if (
      documentParticipants.length < spec.participantFieldDefinition.minRequired
    ) {
      result = false;
      this.validationHelperService.markItemAsInvalid(documentCase, scope);
      this.validationErrorsService.addValidationError({
        errorCode: minAssociatedPartiesErrorCode,
        errorMessage: `Must provide at least ${spec.participantFieldDefinition.minRequired}`,
        group: ValidationGroupConstants.documents,
        metadata: {
          parentEntityId: parentEntityId,
          formSectionName: 'Associated Party',
        },
      });
    } else {
      this.validationErrorsService.removeValidationError(
        minAssociatedPartiesErrorCode,
        parentEntityId
      );
    }

    if (
      documentParticipants.length > spec.participantFieldDefinition.maxAllowed
    ) {
      result = false;
      this.validationHelperService.markItemAsInvalid(documentCase, scope);
    }

    if (
      !this.additionalFieldsValidationService.validateAdditionalFields(
        documentCase.additionalFieldValues,
        spec.participantFieldDefinition.additionalFields,
        documentCase.caseId,
        scope,
        filingProfile,
        caseRequest
      )
    ) {
      result = false;
      this.validationHelperService.markItemAsInvalid(documentCase, scope);
    }

    return result;
  }
}
