import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CombinedFilingData,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  ICaseRequestBuilderService,
  ICaseRequestUpdateService,
  ParticipantCommonCategory,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import { CaseRequestViewModel } from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  of,
  switchMap,
  tap,
  withLatestFrom,
  zip,
} from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from '../../filing-editor/services/validation-errors.service';

export const FsxRemoveParticipantOrchestrationService =
  new InjectionToken<IRemoveParticipantOrchestrationService>(
    'FsxRemoveParticipantOrchestrationService'
  );

export interface IRemoveParticipantEventParams {
  /**
   * The CaseParty object of the party being removed.
   */
  partyToRemove: CasePartyViewModel;

  /**
   * The catgeory of the list that the party is being removed from. Used in this
   * orchestration service to scope the subsequent validation to the list.
   */
  participantCommonCategory?: ParticipantCommonCategory;
}

export interface IRemoveParticipantOrchestrationService {
  /**
   * The orchestration steps needed to remove a participant from the case request.
   */
  removeParticipantFromCaseRequest$: Observable<CaseRequestViewModel>;

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The parameters needed to initiate this orchestration.
   */
  removeParticipant(params: IRemoveParticipantEventParams): void;
}

@Injectable()
export class RemoveParticipantOrchestrationService
  implements IRemoveParticipantOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private removeParticipant$$ = new Subject<IRemoveParticipantEventParams>();

  /**
   * The orchestration steps needed to remove a participant from the case request.
   */
  removeParticipantFromCaseRequest$: Observable<CaseRequestViewModel> =
    this.removeParticipant$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      switchMap(
        ([params, combinedFilingData]: [
          IRemoveParticipantEventParams,
          CombinedFilingData
        ]) => {
          const { caseRequest, filing } = combinedFilingData;
          const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;
          const filingId = filing.id;
          const partyToRemove = params.partyToRemove;

          return zip([
            this.caseRequestBuilderService.removeAssociatedParties({
              filingId,
              caseRequest,
              partyToRemove,
            }),
            this.caseRequestBuilderService.removeAdditionalFieldValues({
              filingId,
              caseRequest,
              partyToRemove,
            }),
          ]).pipe(
            switchMap(() => {
              // Conditionally remove the party and participant based on the existence of the efmKey.
              // (We don't remove existing parties and participants in SubF, but we do remove new ones)
              const conditionallyRemoveParty$: Observable<CaseRequestViewModel> =
                !params.partyToRemove.efmKey
                  ? this.caseRequestBuilderService
                      .removePartyAndParticipant({
                        filingId,
                        caseRequest,
                        partyToRemove,
                      })
                      .pipe(
                        tap(() => {
                          // Remove any related validation errors for the party and representation.
                          this.validationErrorsService.removeEntityValidationErrors(
                            partyToRemove.participantName
                          );
                          partyToRemove.representation?.forEach(
                            (
                              rep: RequestParticipantRepresentationViewModel
                            ) => {
                              this.validationErrorsService.removeEntityValidationErrors(
                                rep.participantName
                              );
                            }
                          );
                        })
                      )
                  : of(caseRequest);

              return conditionallyRemoveParty$.pipe(
                switchMap(() => {
                  return this.caseRequestUpdateService
                    .optimisticPutOrRestore(
                      filing.id,
                      caseRequest,
                      caseRequestBackup
                    )
                    .pipe(
                      tap(() => {
                        /**
                         * When removing a participant the only rules we can clear/violate are min/max rules and
                         * any duplicate parties (similar to adding a party).
                         *
                         * Here we prevent other parties from inadvertently being flagged as invalid by passing
                         * in the removed party in the includedParticipantNames array. (The removed participant
                         * will not be there to be validated, we just use the same mechanism for consistency)
                         *
                         * We also pass in the ParticipantCommonCategory to restrict cross-party validation to
                         * the list that the party is being removed from. Necessary for flagging/removing min/max
                         * allowed parties.
                         */

                        // If the parties grid that we're removing from does have a ParticipantCommonCategory set
                        // for it then we use it to restrict cross-party rule checks.
                        const includedParticipantCommonCatgeories =
                          params.participantCommonCategory
                            ? [params.participantCommonCategory]
                            : [];

                        this.validatePartiesOrchestrationService.validateParties(
                          {
                            includedParticipantNames: [
                              partyToRemove.participantName,
                            ],
                            includedParticipantCommonCatgeories,
                          }
                        );
                      })
                    );
                })
              );
            })
          );
        }
      )
    );

  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService
  ) {}

  /**
   * A method to allow orchestration to be triggered from components.
   *
   * @param params The parameters needed to initiate this orchestration.
   */
  removeParticipant(params: IRemoveParticipantEventParams): void {
    this.removeParticipant$$.next(params);
  }
}
