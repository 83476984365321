import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import {
  CourtsApiService,
  CourtSummaryItemTree,
  CourtSummaryItemTreeTreeResult,
  FilingMode,
  FilingProfileSummary,
  FilingProfileSummaryItemTree,
  FilingProfileSummaryItemTreeTreeResult,
  NewFiling,
  NewFilingCase,
  RequestType,
} from '@fsx/fsx-shared';
import { DropdownOption, FsxProfileSearchComponent } from '@fsx/ui-components';
import { Subject, take, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'fsx-new-filing',
  templateUrl: './new-filing.component.html',
  styleUrls: ['./new-filing.component.scss'],
})
export class NewFilingComponent implements OnInit, OnDestroy {
  @ViewChild(FsxProfileSearchComponent)
  profileSearchComponent!: FsxProfileSearchComponent;
  @Output() setNewFilingEvent = new EventEmitter<NewFiling | null>();

  public courtId!: string | null;
  public profileId!: string | null;
  public courtSelectedDropdownOption!: DropdownOption<void> | null;
  public courtSelectedData!: CourtSummaryItemTree;
  public profileSelectedDropdownOption!: DropdownOption<void> | null;
  public profileSelectedData!: FilingProfileSummaryItemTree | null;
  public browsingCourts: boolean = false;
  public browsingProfiles: boolean = false;
  public courtPath!: string | null;
  public courtState!: string | null;
  public profilePath!: string | null;
  public profileCourt!: string | null;

  private destroy$: Subject<void> = new Subject<void>();

  constructor(public readonly courtsApiService: CourtsApiService) {}

  ngOnInit(): void {
    this.courtsApiService
      .getCourtSelectionTree([])
      .pipe(
        takeUntil(this.destroy$),
        tap((courtsData: CourtSummaryItemTreeTreeResult) => {
          if (courtsData.data) {
            this.courtSelectedData = courtsData.data;
          }
        })
      )
      .subscribe();
  }

  public courtSelected(courtSelected: DropdownOption<void>): void {
    if (this.courtId) {
      this.setNewFilingEvent.emit(null);
    }

    this.courtId = courtSelected.name;
    this.courtSelectedDropdownOption = courtSelected;
    this.courtPath = null;
    this.courtState = null;

    if (
      this.courtSelectedDropdownOption?.itemPath &&
      this.courtSelectedDropdownOption.itemPath?.length > 2
    ) {
      this.courtPath =
        this.courtSelectedDropdownOption.itemPath
          .reverse()
          .slice(2, this.courtSelectedDropdownOption?.itemPath.length)
          .join(' | ') ?? null;
    }
    this.courtState = this.getCourtState();
    this._getProfiles();
  }

  public profileSelected(profileSelected: DropdownOption<void>): void {
    const timestampString = new Date().getTime().toString();
    const last5Digits = timestampString.substr(timestampString.length - 5);
    const derivedCaseName = `${last5Digits}`;
    const profileSummary = this.profileSearchComponent.options.find(
      (o) => o.caption === profileSelected.caption
    ) as FilingProfileSummary;
    const newFilingCase: NewFilingCase = {
      caption: null,
      title: profileSummary?.allowsProposingCaseName ? derivedCaseName : null,
      efmKey: null,
      clientMatterKey: null,
    };
    const newFiling: NewFiling = {
      courtId: this.courtId ?? '',
      profileId: profileSelected.name,
      mode: FilingMode.OriginalPetition,
      caption: derivedCaseName,
      cases: [newFilingCase],
      requestTypes: [RequestType.Case],
    };
    this.profileSelectedDropdownOption = profileSelected;
    this.profileId = profileSelected.name;
    this.profilePath = this.getProfilePath();
    this.setNewFilingEvent.emit(newFiling);
  }

  public toggleBrowsingCourts(): void {
    this.browsingCourts = !this.browsingCourts;
  }

  public toggleBrowsingProfiles(): void {
    this.browsingProfiles = !this.browsingProfiles;
  }

  public browsedCourtSelected(courtData: {
    courtId: string;
    courtName: string;
    courtState?: string;
    courtPath?: string[];
  }): void {
    this.courtId = courtData.courtId;
    this.courtSelectedDropdownOption = {
      caption: courtData.courtName,
      name: courtData.courtId,
      itemPath: courtData.courtPath,
      selected: false,
    };
    this.courtPath = courtData.courtPath?.join(' | ') ?? null;
    this.courtState = courtData.courtState ?? null;
    this._getProfiles();
    this.toggleBrowsingCourts();
  }

  public browsedProfileSelected(profileData: {
    profileId: string;
    profileName: string;
    profilePath?: string[];
  }): void {
    this.profileId = profileData.profileId;
    this.profileSelectedDropdownOption = {
      caption: profileData.profileName,
      name: this.profileId,
      itemPath: profileData.profilePath,
      selected: false,
    };
    this.profilePath = this.getProfilePath();
    this.toggleBrowsingProfiles();
    this.profileSelected(this.profileSelectedDropdownOption);
  }

  public getCourtPath(): string | null {
    if (
      this.courtSelectedDropdownOption &&
      this.courtSelectedDropdownOption.itemPath
    ) {
      return this.courtSelectedDropdownOption.itemPath
        .slice(0, this.courtSelectedDropdownOption.itemPath.length - 2)
        .join(' | ');
    }
    return null;
  }

  public getCourtState(): string | null {
    if (
      this.courtSelectedDropdownOption &&
      this.courtSelectedDropdownOption.itemPath &&
      this.courtSelectedDropdownOption.itemPath.length >= 2
    ) {
      return this.courtSelectedDropdownOption.itemPath[1];
    }
    return null;
  }

  public getProfilePath(): string | null {
    if (
      this.profileSelectedDropdownOption &&
      this.profileSelectedDropdownOption.itemPath
    ) {
      return this.profileSelectedDropdownOption.itemPath.join(' - ');
    }
    return null;
  }

  public resetCourtSelected(): void {
    this.courtId = null;
    this.courtSelectedDropdownOption = null;
    this.resetProfileSelected();
  }

  public resetProfileSelected(): void {
    this.profileId = null;
    this.profileSelectedDropdownOption = null;
    if (!this.courtId) {
      this.profileSelectedData = null;
    }
    this.setNewFilingEvent.emit(null);
  }

  private _getProfiles(): void {
    if (!this.courtId) {
      return;
    }

    this.courtsApiService
      .getFilingProfileSelectionTree(this.courtId)
      .pipe(
        tap((profile: FilingProfileSummaryItemTreeTreeResult) => {
          if (profile.data) {
            this.profileSelectedData = profile.data;
          }
        }),
        take(1)
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }
}
