import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  FilingProfile,
  RequestDocumentViewModel,
  CombinedFilingData,
} from '@fsx/fsx-shared';
import { IUploadedFile } from '@fsx/ui-components';
import { Subject, Observable, map, tap } from 'rxjs';
import {
  DocumentAndFile,
  FsxUploadFilesOrchestrationService,
  IUploadFilesOrchestrationService,
  UploadFilesParams,
} from './upload-files-orchestration.service';

export const FsxReplaceDocumentFileOrchestrationService =
  new InjectionToken<IReplaceDocumentFileOrchestrationService>(
    'FsxReplaceDocumentFileOrchestrationService'
  );

export interface ReplaceDocumentFileParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  filingProfile: FilingProfile;
  requestDocument: RequestDocumentViewModel;
  uploadedFile: IUploadedFile;
  combinedFilingData: CombinedFilingData;
}

export interface IReplaceDocumentFileOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to replace a document file.
   */
  replaceDocumentFileStream$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  replaceDocumentFile(params: ReplaceDocumentFileParams): void;
}

@Injectable()
export class ReplaceDocumentFileOrchestrationService
  implements IReplaceDocumentFileOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private replaceDocumentFileParams$$ =
    new Subject<ReplaceDocumentFileParams>();

  /**
   * The pipeline of orchestration steps needed to replace a document file.
   */
  replaceDocumentFileStream$: Observable<CaseRequestViewModel> =
    this.replaceDocumentFileParams$$.pipe(
      tap((params: ReplaceDocumentFileParams) => {
        const { requestDocument, uploadedFile } = params;
        const documentAndFile: DocumentAndFile = new DocumentAndFile(
          requestDocument,
          uploadedFile
        );
        const documentsAndFiles: DocumentAndFile[] = [documentAndFile];
        const uploadFilesParams: UploadFilesParams = {
          ...params,
          documentsAndFiles,
        };
        this.uploadFilesOrchestrationService.uploadFiles(uploadFilesParams);
      }),
      map((params) => {
        return params.caseRequest;
      })
    );

  constructor(
    @Inject(FsxUploadFilesOrchestrationService)
    private readonly uploadFilesOrchestrationService: IUploadFilesOrchestrationService
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  replaceDocumentFile(params: ReplaceDocumentFileParams): void {
    this.replaceDocumentFileParams$$.next(params);
  }
}
