import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MatCheckbox } from '@angular/material/checkbox';
import {
  AdditionalFieldSpec,
  AdditionalFieldValue,
  BooleanFieldDefinition,
  CombinedFilingData,
} from '@fsx/fsx-shared';
import { FormControlWithModel } from '../../models/form-control.model';
import { FSXFormControlService } from '../../services';
import { ReferenceResolver } from '../../types';
import { Subject, takeUntil, tap } from 'rxjs';

@Component({
  selector: 'fsx-boolean-component',
  templateUrl: './boolean.component.html',
  styleUrls: ['./boolean.component.scss'],
})
export class FsxBooleanComponent implements OnInit, OnDestroy {
  @ViewChild('checkbox') checkbox!: MatCheckbox;
  @Input() fieldDefinition!: BooleanFieldDefinition;
  @Input() initialValue!: boolean;
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint!: string;
  @Input() fieldType!: string;
  @Input() width!: string;
  @Input() height?: string = '';
  @Input() resolver!: ReferenceResolver;

  /**
   * The array of existing AdditionalField value objects to pass onto nested
   * instances of the AdditionalFieldComponent
   */
  @Input() additionalFieldValues: AdditionalFieldValue[] | null = null;

  @Input() combinedFilingData!: CombinedFilingData | null;

  @Output() formControlEmitter = new EventEmitter<
    FormControlWithModel<BooleanFieldDefinition>
  >();
  @Output() updateAdditionalFieldValues =
    new EventEmitter<AdditionalFieldValue>();

  public formControl!: FormControlWithModel<BooleanFieldDefinition>;
  public parseError!: string | null;
  public showPlaceholder = true;
  public additionalFieldSpec!: AdditionalFieldSpec[];
  private destroy$: Subject<unknown> = new Subject();

  constructor(private readonly fsxFormControlService: FSXFormControlService) {}

  ngOnInit(): void {
    this.formControl = this.fsxFormControlService.createFormControl(
      this.fieldDefinition,
      this.fieldType,
      this.initialValue
    );
    if (this.initialValue) {
      this.additionalFieldSpec = this.fieldDefinition.additionalFields ?? [];
    }
    this.formControlEmitter.emit(this.formControl);

    this.formControl.valueChanges
      .pipe(
        takeUntil(this.destroy$),
        tap((value) => {
          if (value) {
            this.additionalFieldSpec =
              this.fieldDefinition.additionalFields ?? [];
          } else {
            this.additionalFieldSpec = [];
          }
        })
      )
      .subscribe();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  public getErrorMessage(): string {
    return Object.values({ ...this.formControl.errors })[0];
  }

  public clickCheckbox(): void {
    if (
      !this.formControl.disabled &&
      !this.formControl.fieldDefinition.readOnly
    ) {
      this.checkbox.toggle();
    }
  }

  public additionalFieldValueEventHandler(value: AdditionalFieldValue) {
    this.updateAdditionalFieldValues.emit(value);
  }

  public validate(): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
