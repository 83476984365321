import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Observable,
  map,
  catchError,
  of,
  tap,
  switchMap,
  take,
  Subject,
  concatMap,
  EMPTY,
} from 'rxjs';
import {
  AdditionalFieldValue,
  CasePartyViewModel,
  CaseRequestViewModel,
  ParticipantCategory,
  RequestParticipantRepresentationViewModel,
} from '../../types';
import { FsxFilingApiService, IFilingApiService } from './filing-api.service';
import { Operation as JsonPatchOperation } from 'fast-json-patch';

// TODO: Code Smell. Think about which way to fix this. Shared services should not depend on feature level services
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/case-request-data.service';

export const FsxCaseRequestUpdateService =
  new InjectionToken<ICaseRequestUpdateService>('FsxCaseRequestUpdateService');

export enum JsonPatchOperationEnum {
  add = 'add',
  remove = 'remove',
  replace = 'replace',
}

export interface PatchOrRestoreCaseRequestParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  backupCaseRequest: CaseRequestViewModel;
  partialCaseRequest: Partial<CaseRequestViewModel>;
  optimistic: boolean;
}

export interface ICaseRequestUpdateService {
  optimisticPutOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    backup: CaseRequestViewModel,
    optimistic?: boolean
  ): Observable<CaseRequestViewModel>;

  optimisticPatchOrRestoreCaseRequest(
    params: PatchOrRestoreCaseRequestParams
  ): Observable<CaseRequestViewModel>;

  optimisticPatchParticipantTypeOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    partyIndex: number,
    participantCategory: ParticipantCategory,
    backup: CaseRequestViewModel,
    optimistic?: boolean
  ): Observable<CaseRequestViewModel>;

  optimisticPatchAdditionalFieldsOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    partyIndex: number,
    additionalFieldValues: AdditionalFieldValue[],
    backup: CaseRequestViewModel,
    optimistic?: boolean
  ): Observable<CaseRequestViewModel>;

  optimisticPatchRepresentationTypeOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    caseParty: CasePartyViewModel,
    representation: RequestParticipantRepresentationViewModel,
    participantCategory: ParticipantCategory | null,
    backup: CaseRequestViewModel,
    optimistic?: boolean
  ): Observable<CaseRequestViewModel>;

  optimisticPatchRepresentationAdditionalFieldsOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    caseParty: CasePartyViewModel,
    representation: RequestParticipantRepresentationViewModel,
    additionalFields: AdditionalFieldValue[],
    backup: CaseRequestViewModel,
    optimistic?: boolean
  ): Observable<CaseRequestViewModel>;

  optimisticPatchCaseSummaryOrRestore(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    value: AdditionalFieldValue | AdditionalFieldValue[] | string | null;
    path: string;
    backup: CaseRequestViewModel;
    optimistic: boolean;
    jsonPatchOperation?: JsonPatchOperationEnum;
  }): Observable<CaseRequestViewModel>;
}

@Injectable()
export class CaseRequestUpdateService implements ICaseRequestUpdateService {
  private etagDependentStream$$ = new Subject<
    Observable<CaseRequestViewModel>
  >();
  private etagDependentStream$ = this.etagDependentStream$$.asObservable().pipe(
    concatMap((stream$: Observable<CaseRequestViewModel>) => {
      return stream$.pipe(take(1));
    })
  );

  constructor(
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService
  ) {
    this.etagDependentStream$.subscribe();
  }

  optimisticPutOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    backup: CaseRequestViewModel,
    optimistic: boolean = true
  ): Observable<CaseRequestViewModel> {
    const stream$: Observable<CaseRequestViewModel> = of(optimistic).pipe(
      tap((optimistic: boolean) => {
        if (optimistic) {
          this.caseRequestDataService.setCaseRequestData(caseRequest);
        }
      }),
      switchMap(() => {
        return this.filingApiService
          .putCaseRequest2(filingId, caseRequest)
          .pipe(
            map(() => caseRequest),
            catchError(() => {
              return of(backup).pipe(
                tap((backupCaseRequest: CaseRequestViewModel) => {
                  this.caseRequestDataService.setCaseRequestData(
                    backupCaseRequest
                  );
                })
              );
            }),
            tap((outputCaseRequest: CaseRequestViewModel) => {
              if (!optimistic) {
                this.caseRequestDataService.setCaseRequestData(
                  outputCaseRequest
                );
              }
            })
          );
      }),
      catchError(() => {
        return of(caseRequest);
      })
    );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }

  optimisticPatchOrRestoreCaseRequest(
    params: PatchOrRestoreCaseRequestParams
  ): Observable<CaseRequestViewModel> {
    const { filingId, caseRequest, backupCaseRequest, optimistic } = params;
    const stream$: Observable<CaseRequestViewModel> = of(
      params.optimistic
    ).pipe(
      take(1),
      tap((optimistic: boolean) => {
        if (optimistic) {
          params.caseRequest = {
            ...params.caseRequest,
            ...params.partialCaseRequest,
          } as CaseRequestViewModel;

          this.caseRequestDataService.setCaseRequestData(params.caseRequest);
        }
      }),
      switchMap(() => {
        const property: string = Object.keys(params.partialCaseRequest)[0];
        const path: string = `/${property}`;
        const value = Object.values(params.partialCaseRequest)[0];

        const jsonPatchOperations: JsonPatchOperation[] = [
          {
            path,
            value,
            op: JsonPatchOperationEnum.replace,
          },
        ];

        return this.patchOrRestore(
          filingId,
          caseRequest,
          backupCaseRequest,
          jsonPatchOperations,
          optimistic
        );
      })
    );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }

  optimisticPatchParticipantTypeOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    partyIndex: number,
    participantCategory: ParticipantCategory,
    backup: CaseRequestViewModel,
    optimistic: boolean = true
  ): Observable<CaseRequestViewModel> {
    const stream$: Observable<CaseRequestViewModel> = of(optimistic).pipe(
      tap((optimistic: boolean) => {
        if (optimistic) {
          caseRequest.parties = caseRequest.parties?.map(
            (party: CasePartyViewModel, index: number) => {
              return partyIndex === index
                ? ({
                    ...party,
                    participantCategory,
                  } as CasePartyViewModel)
                : party;
            }
          );

          this.caseRequestDataService.setCaseRequestData(caseRequest);
        }
      }),
      switchMap(() => {
        const path = `/parties/${partyIndex}/participantCategory`;
        const jsonPatchOperations: JsonPatchOperation[] = [
          {
            value: participantCategory,
            path,
            op: JsonPatchOperationEnum.replace,
          },
        ];

        return this.patchOrRestore(
          filingId,
          caseRequest,
          backup,
          jsonPatchOperations,
          optimistic
        );
      })
    );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }

  optimisticPatchAdditionalFieldsOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    partyIndex: number,
    additionalFieldValues: AdditionalFieldValue[],
    backup: CaseRequestViewModel,
    optimistic: boolean = true
  ): Observable<CaseRequestViewModel> {
    const stream$: Observable<CaseRequestViewModel> = of(optimistic).pipe(
      tap((optimistic: boolean) => {
        if (optimistic) {
          caseRequest.parties = caseRequest.parties?.map(
            (party: CasePartyViewModel, index: number) => {
              return partyIndex === index
                ? ({
                    ...party,
                    additionalFieldValues,
                  } as CasePartyViewModel)
                : party;
            }
          );

          this.caseRequestDataService.setCaseRequestData(caseRequest);
        }
      }),
      switchMap(() => {
        const path = `/parties/${partyIndex}/additionalFieldValues`;
        const jsonPatchOperations: JsonPatchOperation[] = [
          {
            value: additionalFieldValues,
            path,
            op: JsonPatchOperationEnum.replace,
          },
        ];

        return this.patchOrRestore(
          filingId,
          caseRequest,
          backup,
          jsonPatchOperations,
          optimistic
        );
      }),
      catchError((err) => {
        console.error('caught $stream error', err);
        return EMPTY;
      })
    );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }

  optimisticPatchRepresentationTypeOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    caseParty: CasePartyViewModel,
    representation: RequestParticipantRepresentationViewModel,
    participantCategory: ParticipantCategory | null,
    backup: CaseRequestViewModel,
    optimistic = false
  ): Observable<CaseRequestViewModel> {
    const partyIndex = caseRequest.parties?.findIndex(
      (party: CasePartyViewModel) => {
        return party.participantName === caseParty.participantName;
      }
    );

    const representationIndex = caseParty.representation?.findIndex(
      (rep: RequestParticipantRepresentationViewModel) => {
        return rep.participantName === representation.participantName;
      }
    );

    const stream$: Observable<CaseRequestViewModel> = of(optimistic).pipe(
      tap((optimistic: boolean) => {
        if (optimistic) {
          caseRequest.parties = caseRequest.parties?.map(
            (party: CasePartyViewModel, pIndex: number) => {
              return partyIndex === pIndex
                ? ({
                    ...party,
                    representation: party.representation?.map(
                      (
                        rep: RequestParticipantRepresentationViewModel,
                        rIndex: number
                      ) => {
                        return representationIndex === rIndex
                          ? ({
                              ...rep,
                              participantCategory,
                            } as RequestParticipantRepresentationViewModel)
                          : rep;
                      }
                    ),
                  } as CasePartyViewModel)
                : party;
            }
          );

          this.caseRequestDataService.setCaseRequestData(caseRequest);
        }
      }),
      switchMap(() => {
        const path = `/parties/${partyIndex}/representation/${representationIndex}/participantCategory`;

        const jsonPatchOperations: JsonPatchOperation[] = [
          {
            value: participantCategory,
            path,
            op: JsonPatchOperationEnum.replace,
          },
        ];

        return this.patchOrRestore(
          filingId,
          caseRequest,
          backup,
          jsonPatchOperations,
          optimistic
        );
      })
    );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }

  optimisticPatchRepresentationAdditionalFieldsOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    caseParty: CasePartyViewModel,
    representation: RequestParticipantRepresentationViewModel,
    additionalFields: AdditionalFieldValue[],
    backup: CaseRequestViewModel,
    optimistic = false
  ): Observable<CaseRequestViewModel> {
    const partyIndex = caseRequest.parties?.findIndex(
      (party: CasePartyViewModel) => {
        return party.participantName === caseParty.participantName;
      }
    );

    const representationIndex = caseParty.representation?.findIndex(
      (rep: RequestParticipantRepresentationViewModel) => {
        return rep.participantName === representation.participantName;
      }
    );

    const stream$: Observable<CaseRequestViewModel> = of(optimistic).pipe(
      tap((optimistic: boolean) => {
        if (optimistic) {
          caseRequest.parties = caseRequest.parties?.map(
            (party: CasePartyViewModel, pIndex: number) => {
              return partyIndex === pIndex
                ? ({
                    ...party,
                    representation: party.representation?.map(
                      (
                        rep: RequestParticipantRepresentationViewModel,
                        rIndex: number
                      ) => {
                        return representationIndex === rIndex
                          ? ({
                              ...rep,
                              additionalFields,
                            } as RequestParticipantRepresentationViewModel)
                          : rep;
                      }
                    ),
                  } as CasePartyViewModel)
                : party;
            }
          );

          this.caseRequestDataService.setCaseRequestData(caseRequest);
        }
      }),
      switchMap(() => {
        const path = `/parties/${partyIndex}/representation/${representationIndex}/additionalFieldValues`;

        const jsonPatchOperations: JsonPatchOperation[] = [
          {
            value: additionalFields,
            path,
            op: JsonPatchOperationEnum.replace,
          },
        ];

        return this.patchOrRestore(
          filingId,
          caseRequest,
          backup,
          jsonPatchOperations,
          optimistic
        );
      })
    );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }

  optimisticPatchCaseSummaryOrRestore(params: {
    filingId: string;
    caseRequest: CaseRequestViewModel;
    value: AdditionalFieldValue | AdditionalFieldValue[] | string | null;
    path: string;
    backup: CaseRequestViewModel;
    optimistic: boolean;
    jsonPatchOperation?: JsonPatchOperationEnum;
  }): Observable<CaseRequestViewModel> {
    const { filingId, caseRequest, optimistic, value, path, backup } = params;

    if (optimistic) {
      this.caseRequestDataService.setCaseRequestData(caseRequest);
    }

    const jsonPatchOperation =
      params.jsonPatchOperation || JsonPatchOperationEnum.replace;

    const jsonPatchOperations: JsonPatchOperation[] = [
      {
        value,
        path,
        op: jsonPatchOperation,
      },
    ];

    const stream$: Observable<CaseRequestViewModel> = this.filingApiService
      .patchCaseRequest(params.filingId, jsonPatchOperations)
      .pipe(
        take(1),
        map(() => caseRequest),
        catchError(() => {
          return of(backup).pipe(
            tap((backupCaseRequest: CaseRequestViewModel) => {
              this.caseRequestDataService.setCaseRequestData(backupCaseRequest);
            })
          );
        }),
        tap(() => {
          if (!optimistic) {
            this.caseRequestDataService.setCaseRequestData(caseRequest);
          }
        })
      );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }

  private patchOrRestore(
    filingId: string,
    caseRequest: CaseRequestViewModel,
    backup: CaseRequestViewModel,
    jsonPatchOperations: JsonPatchOperation[],
    optimistic: boolean
  ): Observable<CaseRequestViewModel> {
    const stream$: Observable<CaseRequestViewModel> = this.filingApiService
      .patchCaseRequest(filingId, jsonPatchOperations)
      .pipe(
        take(1),
        map(() => caseRequest),
        catchError(() => {
          return of(backup).pipe(
            tap((backupCaseRequest: CaseRequestViewModel) => {
              this.caseRequestDataService.setCaseRequestData(backupCaseRequest);
            })
          );
        }),
        tap((outputCaseRequest: CaseRequestViewModel) => {
          if (!optimistic) {
            this.caseRequestDataService.setCaseRequestData(outputCaseRequest);
          }
        }),
        catchError((err, caught) => {
          console.log('Error patching case request', err);
          return EMPTY;
        })
      );

    this.etagDependentStream$$.next(stream$);
    return of(caseRequest);
  }
}
