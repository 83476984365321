import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { MatListModule } from '@angular/material/list';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {
  MatTooltipModule,
  MAT_TOOLTIP_DEFAULT_OPTIONS,
} from '@angular/material/tooltip';
import { MatIconModule } from '@angular/material/icon';
import { MatDividerModule } from '@angular/material/divider';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
} from '@angular/material/core';
import { MatSelectModule } from '@angular/material/select';

import { AutoSizeInputModule } from 'ngx-autosize-input';

import {
  CustomFSXDateAdapter,
  FSX_DATE_FORMAT,
} from './components/date/date.component.config';
import {
  FsxBooleanComponent,
  FsxCurrencyComponent,
  FsxDateComponent,
  FsxTimeComponent,
  FsxNumberComponent,
  FsxTextComponent,
  FsxAdditionalFieldsComponent,
  FsxAddressComponent,
  FsxAliasComponent,
  FsxPersonNameComponent,
  FsxTextBoxComponent,
  FsxSidePanelComponent,
  FsxSidePanelHeaderComponent,
  FsxSidePanelFooterComponent,
  FsxPhoneComponent,
  FsxEmailComponent,
  FsxEfmSearchComponent,
  FsxIdentificationComponent,
  FsxCourtSelectionComponent,
  FsxBaseSearchComponent,
  FsxProfileSearchComponent,
  FsxCourtsSearchComponent,
  FsxCaseSearchComponent,
  FsxSingleSelectionBaseSelectionComponent,
  FsxProfileSingleSelectionComponent,
  FsxBasicSingleSelectionComponent,
  FsxProfileSelectionComponent,
  FileUploadLargeComponent,
  FileUploadInlineComponent,
  FileUploadSmallComponent,
  FsxFileUploadWrapperComponent,
  FsxContactComponent,
  FsxParticipantComponent,
  ParticipantTableComponent,
  FsxInstructionComponent,
  FsxSubcategoryComponent,
} from './components';
import { SharedModule } from '../../../shared/src/lib/shared.module';
import { FileUploadDropTargetDirective } from './components/file-upload/file-drag-drop.directive';
import { FileUploadZoneComponent } from './components/file-upload/file-upload-zone.component';
import { ParticipantSpecSelectionComponent } from './components/participant-spec-selection/participant-spec-selection.component';

const FSX_COMPONENTS = [
  FsxTextComponent,
  FsxNumberComponent,
  FsxCurrencyComponent,
  FsxBooleanComponent,
  FsxDateComponent,
  FsxTimeComponent,
  FsxAdditionalFieldsComponent,
  FsxAliasComponent,
  FsxAddressComponent,
  FsxPersonNameComponent,
  FsxTextBoxComponent,
  FsxSidePanelComponent,
  FsxSidePanelHeaderComponent,
  FsxSidePanelFooterComponent,
  FsxPhoneComponent,
  FsxEmailComponent,
  FsxEfmSearchComponent,
  FsxIdentificationComponent,
  FsxCourtSelectionComponent,
  FsxBaseSearchComponent,
  FsxProfileSearchComponent,
  FsxCourtsSearchComponent,
  FsxCaseSearchComponent,
  FsxSingleSelectionBaseSelectionComponent,
  FsxProfileSingleSelectionComponent,
  FsxBasicSingleSelectionComponent,
  FsxProfileSelectionComponent,
  FileUploadDropTargetDirective,
  FileUploadLargeComponent,
  FileUploadInlineComponent,
  FsxFileUploadWrapperComponent,
  FileUploadSmallComponent,
  FileUploadZoneComponent,
  FileUploadSmallComponent,
  FsxContactComponent,
  FsxParticipantComponent,
  ParticipantTableComponent,
  FsxInstructionComponent,
  FsxSubcategoryComponent,
  ParticipantSpecSelectionComponent,
];

@NgModule({
  declarations: [FSX_COMPONENTS],
  providers: [
    {
      provide: DateAdapter,
      useClass: CustomFSXDateAdapter,
      deps: [MAT_DATE_LOCALE],
    },
    { provide: MAT_DATE_FORMATS, useValue: FSX_DATE_FORMAT },
    {
      provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
      useValue: {
        showDelay: 500,
        hideDelay: 0,
        touchendHideDelay: 500,
        position: 'below',
        disableTooltipInteractivity: true,
      },
    },
  ],
  exports: [FSX_COMPONENTS],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    MatInputModule,
    MatListModule,
    MatFormFieldModule,
    MatTooltipModule,
    MatIconModule,
    MatDividerModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatAutocompleteModule,
    MatSelectModule,
    AutoSizeInputModule,
    SharedModule,
  ],
})
export class FsxUiComponentsModule {}
