import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  ContactViewModel,
  FilingProfile,
  ParticipantSpec,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
  RequestParticipantViewModel,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import { Observable, Subject, switchMap, tap, withLatestFrom } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxParticipantDataService,
  IParticipantDataService,
} from '../../filing-editor/services/participant-data.service';
import { PartyAndParticipant } from '../../filing-editor/services/party-and-participant-data.service';
import { v4 as uuidv4 } from 'uuid';

export const FsxAddRepresentationOrchestrationService =
  new InjectionToken<IAddRepresentationOrchestrationService>(
    'FsxAddRepresentationOrchestrationService'
  );

export interface IAddRepresentationParams {
  filingId: string;

  caseRequest: CaseRequestViewModel;

  /**
   * The CaseParty and RequestParticipant objects for the party that the
   * representation is to be added to.
   */
  partyAndParticipant: PartyAndParticipant;

  contact: ContactViewModel;

  participantSpec: ParticipantSpec;

  filingProfile: FilingProfile;
}

export interface IAddRepresentationOrchestrationService {
  addRepresentationToCaseRequest$: Observable<CaseRequestViewModel>;
  addRepresentation(params: IAddRepresentationParams): void;
}

@Injectable()
export class AddRepresentationOrchestrationService
  implements IAddRepresentationOrchestrationService
{
  private addRepresentation$$ = new Subject<IAddRepresentationParams>();

  addRepresentationToCaseRequest$: Observable<CaseRequestViewModel> =
    this.addRepresentation$$.pipe(
      withLatestFrom(this.participantDataService.participants$),
      switchMap(
        ([params, participants]: [
          IAddRepresentationParams,
          RequestParticipantViewModel[]
        ]) => {
          const caseRequestBackup: CaseRequestViewModel = {
            ...params.caseRequest,
          } as CaseRequestViewModel;
          const { caseRequest, partyAndParticipant } = params;
          const { party, participant } = partyAndParticipant;
          return this.caseRequestBuilderService
            .checkAddParty({ caseRequest, party })
            .pipe(
              switchMap(() => {
                return this.caseRequestBuilderService
                  .checkAddParticipant({ caseRequest, participant })
                  .pipe(
                    switchMap(() => {
                      // Add representation RequestParticipants
                      const partyRepresentation: RequestParticipantRepresentationViewModel[] =
                        party.representation || [];
                      const representationParticipantNames: string[] =
                        partyRepresentation.map((r) => {
                          return r.participantName;
                        });
                      const representationParticipants: RequestParticipantViewModel[] =
                        participants.filter(
                          (participant: RequestParticipantViewModel) => {
                            return (
                              representationParticipantNames.indexOf(
                                participant.name
                              ) > -1
                            );
                          }
                        );

                      const caseRequestParticipants2: RequestParticipantViewModel[] =
                        params.caseRequest.participants || [];
                      representationParticipants.forEach(
                        (participant: RequestParticipantViewModel) => {
                          const participantExistsOnCaseRequest: boolean =
                            caseRequestParticipants2.filter(
                              (
                                caseReqParticipants: RequestParticipantViewModel
                              ) => {
                                return (
                                  caseReqParticipants.name === participant.name
                                );
                              }
                            ).length > 0;
                          if (!participantExistsOnCaseRequest) {
                            params.caseRequest.participants = [
                              ...caseRequestParticipants2,
                              participant,
                            ];
                          }
                        }
                      );

                      // The unique identifier to use as the new RequestParticipantRepresentation object's participantname.
                      const uniqueIdentifier1: string = uuidv4();

                      return this.caseRequestBuilderService
                        .createRepresentationAndParticipantThenSetInCaseRequest(
                          { ...params, uniqueIdentifier1 }
                        )
                        .pipe(
                          switchMap(() => {
                            return this.caseRequestUpdateService
                              .optimisticPutOrRestore(
                                params.filingId,
                                params.caseRequest,
                                caseRequestBackup
                              )
                              .pipe(
                                tap(() => {
                                  // Revalidate the parent party and the newly created representation only.
                                  const parentParticipantName =
                                    participant.name;
                                  const includedParticipantNames = [
                                    parentParticipantName,
                                    uniqueIdentifier1,
                                  ];
                                  this.validatePartiesOrchestrationService.validateParties(
                                    { includedParticipantNames }
                                  );
                                })
                              );
                          })
                        );
                    })
                  );
              })
            );
        }
      )
    );

  constructor(
    @Inject(FsxParticipantDataService)
    private readonly participantDataService: IParticipantDataService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  addRepresentation(params: IAddRepresentationParams): void {
    this.addRepresentation$$.next(params);
  }
}
