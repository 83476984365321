import { Injectable, InjectionToken } from '@angular/core';
import {
  AddressProfile,
  AddressProfileSpec,
  ContactProfile,
  NamedList,
} from '@fsx/fsx-shared';
import { BehaviorSubject, Observable, filter, map, switchMap } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IContactProfileDataService to use at runtime.
 */
export const FsxContactProfileDataService =
  new InjectionToken<IContactProfileDataService>(
    'FsxContactProfileDataService'
  );

/**
 * A blueprint for a state service, which stores a ContactProfile object.
 */
export interface IContactProfileDataService {
  /**
   * A public member, which exposes the ContactProfile object as an Observable
   */
  contactProfile$: Observable<ContactProfile>;

  /**
   * A public method, which sets the ContactProfile object
   */
  setContactProfileData(caseRequest: ContactProfile): void;

  /**
   * A helper function to lookup a NamedList object (akn "additional list") on a
   * previously loaded ContactProfile object,
   *
   * @param listReference The name to lookup the NameList object with.
   *
   * @returns The NamedList object that matches the listReference name.
   */
  getAdditionalList(listReference: string): Observable<NamedList>;

  /**
   * A helper function to lookup the ContactProfile's identification region NamedList object.
   *
   * @returns The identification region NamedList object.
   */
  getIdentificationRegionAdditionalList(): Observable<NamedList>;

  /**
   * A helper method to lookup an AddressProfileSpec object on the previously loaded
   * ContactProfile object.
   *
   * @param addressProfileName The name to lookup the AddressProfileSpec with.
   *
   * @returns The AddressProfileSpec object that matches the listReference name.
   */
  getAddressProfile(addressProfileName: string): Observable<AddressProfileSpec>;

  /**
   * A helper method to lookup the ContactProfile's default AddressProfileSpec object.
   *
   * @returns The default AddressProfileSpec object.
   */
  getDefaultAddressProfile(): Observable<AddressProfileSpec>;
}

/**
 * A concrete implementation of a state service, which stores a ContactProfile object.
 */
@Injectable()
export class ContactProfileDataService implements IContactProfileDataService {
  /**
   * A private member, which stores the ContactProfile object in a BehaviorSubject
   */
  private contactProfile$$ = new BehaviorSubject<ContactProfile | null>(null);

  /**
   * A public member, which exposes the ContactProfile object as an Observable
   */
  contactProfile$: Observable<ContactProfile> = this.contactProfile$$
    .asObservable()
    .pipe(
      filter((caseRequest) => caseRequest !== null),
      map((caseRequest) => caseRequest as ContactProfile)
    );

  /**
   * A public method, which sets the ContactProfile object
   *
   * @param contactProfile The ContactProfile object to store
   */
  setContactProfileData(contactProfile: ContactProfile): void {
    const newContactProfileObj = JSON.parse(JSON.stringify(contactProfile));
    this.contactProfile$$.next(newContactProfileObj);
  }

  /**
   * A helper function to lookup a NamedList object (akn "additional list") on a
   * previously loaded ContactProfile object,
   *
   * @param listReference The name to lookup the NameList object with.
   *
   * @returns The NamedList object that matches the listReference name.
   */
  getAdditionalList(listReference: string): Observable<NamedList> {
    return this.contactProfile$.pipe(
      map((contactProfile: ContactProfile) => {
        // Lookup the NamedList in the ContactProfile object's additionalLists array.
        const additionalList: NamedList | undefined =
          contactProfile.additionalLists.find((namedList: NamedList) => {
            return namedList.name === listReference;
          });

        // We can't continue to lookup any subsequent value without the NamedList, so throw error
        // to allow consuming streams to handle this unlikelyu situation.
        // (likely something wrong with the ContactProfile data if we ever see this error)
        if (!additionalList) {
          throw `No NamedList found with matching list reference (${listReference})`;
        }

        // We know we have a NamedList if we reach here, so just return it.
        return additionalList;
      })
    );
  }

  /**
   * A helper function to lookup the ContactProfile's identification region NamedList object.
   *
   * @returns The identification region NamedList object.
   */
  getIdentificationRegionAdditionalList(): Observable<NamedList> {
    return this.contactProfile$.pipe(
      switchMap((contactProfile: ContactProfile) => {
        const identificationRegionListName: string =
          contactProfile.contact.identification.region.listReference
            .additionalListName;
        return this.getAdditionalList(identificationRegionListName);
      })
    );
  }

  /**
   * A helper method to lookup an AddressProfileSpec object on the previously loaded
   * ContactProfile object.
   *
   * @param addressProfileName The name to lookup the AddressProfileSpec with.
   *
   * @returns The AddressProfileSpec object that matches the listReference name.
   */
  getAddressProfile(
    addressProfileName: string
  ): Observable<AddressProfileSpec> {
    return this.contactProfile$.pipe(
      map((contactProfile: ContactProfile) => {
        // Lookup the AddressProfileSpec inb the ContactProfile object's addressProfiles array.
        const addressProfileSpec: AddressProfileSpec | undefined =
          contactProfile.addressProfiles.find(
            (addressProfile: AddressProfile) => {
              return addressProfile.name === addressProfileName;
            }
          )?.spec;

        // We can't format the address if we don't have an AddressProfileSpec object, so throw error.
        // (likely something wrong with the ContactProfile data if we ever see this error)
        if (!addressProfileSpec) {
          throw 'No default AddressProfileSpec could be found';
        }

        // We know we have a AddressProfileSpec if we reach here, so just return it.
        return addressProfileSpec;
      })
    );
  }

  /**
   * A helper method to lookup the ContactProfile's default AddressProfileSpec object.
   *
   * @returns The default AddressProfileSpec object.
   */
  getDefaultAddressProfile(): Observable<AddressProfileSpec> {
    return this.contactProfile$.pipe(
      switchMap((contactProfile: ContactProfile) => {
        // Use the ConatctProfile's defaultAddressProfileName.
        return this.getAddressProfile(contactProfile.defaultAddressProfileName);
      })
    );
  }
}
