import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControlWithoutModel } from '../../models/form-control.model';
import { FieldCategory, ParticipantSpec } from '@fsx/fsx-shared';
import { DropdownOption, SelectionFieldType } from '../../types';

@Component({
  selector: 'fsx-participant-spec-selection',
  templateUrl: './participant-spec-selection.component.html',
  styleUrls: ['./participant-spec-selection.component.css'],
})
export class ParticipantSpecSelectionComponent implements OnInit {
  /**
   * The read only value to pass on to the basic-single-selection component.
   */
  @Input() readOnly: boolean = false;

  /**
   * The initial value to pass on to the basic-single-selection component.
   */
  @Input() initialValue: string = '';

  /**
   * The ParticipantSpec objects to derive the dropdown options from.
   */
  @Input() participantSpecs: ParticipantSpec[] = [];

  /**
   * An output event to notify the parent component that a new selected
   * value has been emitted.
   */
  @Output() selectedValueEvent = new EventEmitter<string>();

  /**
   * An output event to notify the parent component that the list was clicked.
   */
  @Output() dropdownClickedEvent = new EventEmitter<void>();

  /**
   * An output event to notify the parent component that the FormControl object
   * has been emitted.
   */
  @Output() formControlEmitter = new EventEmitter<FormControlWithoutModel>();

  /**
   * The field type to pass to the basic-single-selection component.
   */
  fieldType = FieldCategory.Selection;

  /**
   * The selection type to pass to the basic-single-selection component.
   */
  selectionType = SelectionFieldType.StringSelectionFieldDefinition;

  /**
   * The array of dropdown options to populate the dropdown list.
   * (derived from passed in array of ParticipantSpecs)
   */
  dropdownOptions: DropdownOption<void>[] = [];

  ngOnInit(): void {
    this.setDropdownOptions(this.participantSpecs);
  }

  /**
   * A function to derive the dropdown options from a given array of ParticipantSpec objects.
   *
   * @param participantSpecs The Particpant Specs from which to derive the dropdown options.
   */
  private setDropdownOptions(participantSpecs: ParticipantSpec[]): void {
    // Derive the dropdown options from the array of ParticipantSpec objects.
    this.dropdownOptions = participantSpecs.map((pSpec: ParticipantSpec) => {
      return { ...pSpec.participantCategory, selected: false };
    });
  }

  /**
   * A handler method for the fsx-basic-single-selection component's click event.
   * @param event
   */
  onDropdownClicked(event: Event) {
    event.stopPropagation();
    this.dropdownClickedEvent.emit();
  }

  /**
   * A handler method for the fsx-basic-single-selection component's formControlEmitter event.
   *
   * @param controls The FormControl object being emitted.
   */
  onFormControlEmitted(controls: FormControlWithoutModel) {
    this.formControlEmitter.emit(controls);
  }

  /**
   * A handler method for the fsx-basic-single-selection component's selectedValue event.
   *
   * @param value The selected value being emitted.
   */
  onSelected(value: string): void {
    this.selectedValueEvent.emit(value);
  }
}
