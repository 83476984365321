import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnDestroy,
  OnChanges,
} from '@angular/core';
import { FieldCategory, TextFieldDefinition } from '@fsx/fsx-shared';
import { FormControlWithModel } from '../../models';
import { FSXFormControlService } from '../../services';
import { ReferenceResolver } from '../../types';
import {
  Subject,
  debounceTime,
  distinctUntilChanged,
  takeUntil,
  tap,
} from 'rxjs';
import { FsxBaseComponent } from '../base/base.component';

@Component({
  selector: 'fsx-text-component',
  templateUrl: './text.component.html',
  styleUrls: ['./text.component.scss'],
})
export class FsxTextComponent
  extends FsxBaseComponent
  implements OnInit, OnChanges, OnDestroy
{
  @Input() caption!: string;
  @Input() helpText!: string;
  @Input() hint: string = '';
  @Input() width!: string;
  @Input() height: string = '63px';
  @Input() fieldDefinition!: TextFieldDefinition | undefined;
  @Input() initialValue!: string;
  @Input() resolver!: ReferenceResolver;
  @Input() hidden = false;
  @Input() overflowWrap = false;

  /**
   * a boolean input binding to allow consuming components to set the enabled/disabled
   * state of this text component.
   */
  @Input() disabled = false;

  public fieldType = FieldCategory.Text;

  @Output() formControlEmitter = new EventEmitter<
    FormControlWithModel<TextFieldDefinition>
  >();
  @Output() textChanged = new EventEmitter<string>();

  public formControl!: FormControlWithModel<TextFieldDefinition>;
  public inputFocused = false;

  private textChanged$$: Subject<string> = new Subject();
  private destroy$: Subject<unknown> = new Subject();

  constructor(private readonly fsxFormControlService: FSXFormControlService) {
    super();
  }

  ngOnInit(): void {
    if (this.fieldDefinition) {
      this.formControl = this.fsxFormControlService.createFormControl(
        this.fieldDefinition,
        this.fieldType,
        this.initialValue,
        this.resolver
      );

      this.enableOrDisableFormControl();

      this.formControlEmitter.emit(this.formControl);

      this.textChanged$$
        .pipe(
          debounceTime(700),
          distinctUntilChanged(),
          tap((value: string) => {
            this.textChanged.emit(value);
          }),
          takeUntil(this.destroy$)
        )
        .subscribe();
    }
  }

  ngOnChanges() {
    this.enableOrDisableFormControl();
  }

  ngOnDestroy(): void {
    this.destroy$.next(null);
    this.destroy$.complete();
  }

  /**
   * A helper function to set the formControl's enable or disable state based
   * on the incoming [disabled] input binding.
   */
  private enableOrDisableFormControl() {
    if (this.formControl) {
      // Enable/Disable the component nased on the disabled input property binding.]
      if (this.disabled) {
        this.formControl.disable();
      } else {
        this.formControl.enable();
      }
    }
  }

  public clearText(): void {
    if (this.formControl?.disabled) {
      return;
    }

    this.formControl?.setValue(null);
    this.formControl.markAsPristine();
  }

  public focusInput(): void {
    this.inputFocused = true;
  }

  public removeFocus(): void {
    this.inputFocused = false;
    this.formControl.updateValueAndValidity();
  }

  public getErrorMessage(): string {
    return Object.values({ ...this.formControl.errors })[0];
  }

  public formatValue(): void {
    const value: string = this.formControl.value;
    if (value && typeof value === 'string') {
      this.formControl.setValue(value.trim());
    }
  }

  public onFocus() {
    this.formatValue();
    this.focusInput();
  }

  public onTextChanged(event: KeyboardEvent) {
    const target: HTMLInputElement = event.target as HTMLInputElement;
    const value: string = target.value;
    this.textChanged$$.next(value);
  }

  public validate(): void {
    this.formControl.markAsTouched();
    this.formControl.markAsDirty();
    this.formControl.updateValueAndValidity({ emitEvent: false });
  }
}
