import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  ContactViewModel,
  ParticipantCategory,
  ParticipantSpec,
  FilingProfile,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { Observable, of, Subject, switchMap, tap } from 'rxjs';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';

export const FsxAddContactAsParticipantOrchestrationService =
  new InjectionToken<IAddContactAsParticipantOrchestrationService>(
    'FsxAddContactAsParticipantOrchestrationService'
  );

export interface IAddContactAsParticipantParams {
  filingId: string;

  caseRequest: CaseRequestViewModel;

  /**
   * The Contact object from which to derive CaseParty and RequestParticipant objects for.
   */
  contact: ContactViewModel;

  /**
   * The guid of the default CaseParty object that we are attempting to add to.
   */
  participantName: string;

  /**
   * The category to apply once the CaseParty object has been created.
   */
  participantCategory: ParticipantCategory;

  participantSpec: ParticipantSpec;

  filingProfile: FilingProfile;
}

export interface IAddContactAsParticipantOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to transform a contact into both
   * a CaseParty and RequestParticipant objects on the CaseRequest object.
   */
  addContactAsParticipantOrchestration$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  addContactAsParticipant(params: IAddContactAsParticipantParams): void;
}

@Injectable()
export class AddContactAsParticipantOrchestrationService
  implements IAddContactAsParticipantOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private addContactAsParticipant$$ =
    new Subject<IAddContactAsParticipantParams>();

  /**
   * The pipeline of orchestration steps needed to transform a contact into both
   * a CaseParty and RequestParticipant objects on the CaseRequest object.
   */
  addContactAsParticipantOrchestration$: Observable<CaseRequestViewModel> =
    this.addContactAsParticipant$$.pipe(
      switchMap((params: IAddContactAsParticipantParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;

        // Check to see if the participant already exists on the case request.
        const participantExists = params.caseRequest.participants?.find(
          (participant) => participant.linkedContact?.id === params.contact.id
        );

        // Guard clause to return early because we can't add the same contact multiple times.
        if (participantExists) {
          return of(caseRequestBackup);
        }

        // Defer to the CaseRequestBuilderService to do the heavy-lifting.
        return this.caseRequestBuilderService
          .createPartyAndParticipantFromContactThenSetInCaseRequest(params)
          .pipe(
            switchMap(() => {
              return this.caseRequestUpdateService
                .optimisticPutOrRestore(
                  params.filingId,
                  params.caseRequest,
                  caseRequestBackup
                )
                .pipe(
                  tap(() => {
                    /**
                     * When adding a contact as a participant we want to re-validate just this party to clear
                     * any previous validation errors.
                     *
                     * Here we prevent other parties from inadvertently being flagged as invalid by passing
                     * in the participantName of the default party that we are adding to.
                     *
                     * TODO: Set any other flags here to include/exclude cross-party rule checks that adding
                     * a contact as a particpant could possibly violate.
                     */
                    this.validatePartiesOrchestrationService.validateParties({
                      includedParticipantNames: [params.participantName],
                    });
                  })
                );
            })
          );
      })
    );

  constructor(
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  addContactAsParticipant(params: IAddContactAsParticipantParams): void {
    this.addContactAsParticipant$$.next(params);
  }
}
