import { Component } from '@angular/core';
import { ContactsSearchTypeEnum } from '../contacts/contacts.model';
import {
  ContactsListService,
  FsxContactsListService,
} from '../contacts/contacts-list/contacts-list.service';
import {
  FsxPanelService,
  PanelService,
} from '../shared/services/panel.service';
import {
  FsxDefaultPartyService,
  DefaultPartyService,
  FsxCaseRequestUpdateService,
  CaseRequestUpdateService,
  FsxUploadFileService,
  UploadFileService,
  FsxCaseRequestBuilderService,
  CaseRequestBuilderService,
  FsxValidationService,
  ValidationService,
  FsxCreateRequestDocumentService,
  CreateRequestDocumentService,
  ContactApiService,
  FsxContactApiService,
  AddressFormService,
  FsxAddressFormService,
  FsxPhoneFormService,
  PhoneFormService,
  EmailFormService,
  FsxEmailFormService,
  AliasFormService,
  FsxAliasFormService,
  FsxIdentificationsFormService,
  IdentificationsFormService,
  FsxOrganizationFormService,
  OrganizationFormService,
  FsxPersonFormService,
  PersonFormService,
} from '@fsx/fsx-shared';
import {
  FsxAdditionalFieldsValidationService,
  AdditionalFieldsValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/additional-fields-validation.service';
import {
  FsxAddressValidationService,
  AddressValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/address-validation.service';
import {
  FsxAliasValidationService,
  AliasValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/alias-validation.service';
import {
  FsxCaseReviewValidationService,
  CaseReviewValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/case-review-validation.service';
import {
  FsxCaseValidationService,
  CaseValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/case-validation.service';
import {
  FsxContactValidationService,
  ContactValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/contact-validation.service';
import {
  FsxCurrencyValidationService,
  CurrencyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/currency-validation.service';
import {
  FsxDateTimeValidationService,
  DateTimeValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/date-time-validation.service';
import {
  FsxDateValidationService,
  DateValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/date-validation.service';
import {
  FsxDocumentDomainValidationService,
  DocumentDomainValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-domain-validation.service';
import {
  FsxDocumentFilesValidationService,
  DocumentFilesValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-files-validation.service';
import {
  FsxDocumentValidationService,
  DocumentValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/document-validation.service';
import {
  FsxEmailAddressValidationService,
  EmailAddressValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/email-address-validation.service';
import {
  FsxIdentificationValidationService,
  IdentificationValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/identification-validation.service';
import {
  FsxNumberValidationService,
  NumberValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/number-validation.service';
import {
  FsxOrganizationValidationService,
  OrganizationValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/organization-validation.service';
import {
  FsxParticipantValidationService,
  ParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/participant-validation.service';
import {
  FsxPartyValidationService,
  PartyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/party-validation.service';
import {
  FsxPersonNameValidationService,
  PersonNameValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/person-name-validation.service';
import {
  FsxPersonValidationService,
  PersonValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/person-validation.service';
import {
  FsxPhoneValidationService,
  PhoneValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/phone-validation.service';
import {
  FsxRepresentationValidationService,
  RepresentationValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/representation-validation.service';
import {
  FsxSearchFieldValidationService,
  SearchFieldValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/search-field-validation.service';
import {
  FsxSelectionFieldValidationService,
  SelectionFieldValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/selection-field-validation.service';
import {
  FsxSingleParticipantValidationService,
  SingleParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/single-participant-validation.service';
import {
  FsxTextFieldValidationService,
  TextFieldValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/text-field-validation.service';
import {
  FsxTimeValidationService,
  TimeValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/time-validation.service';
import {
  FsxValidationHelperFpService,
  ValidationHelperFpService,
} from 'projects/libs/shared/src/lib/services/core/validation/validation-helper-fp.service';
import {
  FsxValidationHelperService,
  ValidationHelperService,
} from 'projects/libs/shared/src/lib/services/core/validation/validation-helper.service';
import {
  FsxContactFormService,
  ContactFormService,
} from '../contacts/contact-form/contact-form.service';
import {
  FsxContactsListTwoService,
  ContactsListTwoService,
} from '../contacts/contacts-list-two/contacts-list-two.service';
import {
  FsxSelectedContactsService,
  SelectedContactsService,
} from '../contacts/contacts-list/selected-contacts.service';
import {
  FsxAddDocumentOrchestrationService,
  AddDocumentOrchestrationService,
} from '../documents/services/add-document-orchestration.service';
import {
  FsxAddLeadDocumentOrchestrationService,
  AddLeadDocumentOrchestrationService,
} from '../documents/services/add-lead-documents-orchestration.service';
import {
  FsxAddSupportingDocumentOrchestrationService,
  AddSupportingDocumentOrchestrationService,
} from '../documents/services/add-supporting-document-orchestration.service';
import {
  FsxRemoveDocumentOrchestrationService,
  RemoveDocumentOrchestrationService,
} from '../documents/services/remove-document-orchestration.service';
import {
  FsxReplaceDocumentFileOrchestrationService,
  ReplaceDocumentFileOrchestrationService,
} from '../documents/services/replace-document-file-orchestration.service';
import {
  FsxUpdateDocumentOrchestrationService,
  UpdateDocumentOrchestrationService,
} from '../documents/services/update-document-orchestration.service';
import {
  FsxUploadAdditionalFieldFilesOrchestrationService,
  UploadAdditionalFieldFilesOrchestrationService,
} from '../documents/services/upload-additional-field-files-orchestration.service';
import {
  FsxUploadFilesOrchestrationService,
  UploadFilesOrchestrationService,
} from '../documents/services/upload-files-orchestration.service';
import {
  FsxFilingSubTabsFactoryService,
  FilingSubTabsFactoryService,
} from '../filing-editor/filing-sub-tabs-container/filing-sub-tabs-factory.service';
import {
  FsxFilingSubTabsService,
  FilingSubTabsService,
} from '../filing-editor/filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCaseRequestDataService,
  CaseRequestDataService,
} from '../filing-editor/services/case-request-data.service';
import {
  FsxCombinedFilingDataService,
  CombinedFilingDataService,
  FsxCombinedFilingDataService2,
} from '../filing-editor/services/combined-filing-data.service';
import {
  FsxDocumentInfoDataService,
  DocumentInfoDataService,
} from '../filing-editor/services/document-info-data.service';
import {
  FsxFilingDataService,
  FilingDataService,
} from '../filing-editor/services/filing-data.service';
import {
  FsxFilingEditorActionsService,
  FilingEditorActionsService,
} from '../filing-editor/services/filing-editor-actions.service';
import {
  FsxFilingEditorEventService,
  FilingEditorEventService,
} from '../filing-editor/services/filing-editor-events.service';
import {
  FsxFilingFeesDataService,
  FilingFeesDataService,
} from '../filing-editor/services/filing-fees-data.service';
import {
  FsxFilingFeesGetterService,
  FilingFeesGetterService,
} from '../filing-editor/services/filing-fees-getter.service';
import {
  FsxFilingProfileDataService,
  FilingProfileDataService,
} from '../filing-editor/services/filing-profile-data.service';
import {
  FsxCloseFilingOrchestrationService,
  CloseFilingOrchestrationService,
} from '../filing-editor/services/orchestration/close-filing-orchestration.service';
import {
  FsxFirstLoadOrchestrationService,
  FirstLoadOrchestrationService,
} from '../filing-editor/services/orchestration/first-load-orchestration.service';
import {
  FsxLoadFilingFeesOrchestrationService,
  LoadFilingFeesOrchestrationService,
} from '../filing-editor/services/orchestration/load-filing-fees-orchestration.service';
import {
  FsxSubmitFilingOrchestrationService,
  SubmitFilingOrchestrationService,
} from '../filing-editor/services/orchestration/submit-filing-orchestration.service';
import {
  FsxSwitchFilingOrchestrationService,
  SwitchFilingOrchestrationService,
} from '../filing-editor/services/orchestration/switch-filing-orchestration.service';
import {
  FsxValidateCaseOrchestrationService,
  ValidateCaseOrchestrationService,
} from '../filing-editor/services/orchestration/validate-case-orchestration.service';
import {
  FsxValidateDocumentsOrchestrationService,
  ValidateDocumentsOrchestrationService,
} from '../filing-editor/services/orchestration/validate-documents-orchestration.service';
import {
  FsxValidatePartiesOrchestrationService,
  ValidatePartiesOrchestrationService,
} from '../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxValidateReviewOrchestrationService,
  ValidateReviewOrchestrationService,
} from '../filing-editor/services/orchestration/validate-review-orchestration.service';
import {
  FsxPollingStateService,
  PollingStateService,
} from '../filing-editor/services/polling-state.service';
import {
  FsxValidationErrorsService,
  ValidationErrorsService,
} from '../filing-editor/services/validation-errors.service';
import {
  FsxValidationGroupErrorsService,
  ValidationGroupErrorsService,
} from '../filing-editor/services/validation-group-errors.service';
import {
  FsxValidationIndicatorService,
  ValidationIndicatorService,
} from '../filing-editor/services/validation-indicator.service';
import {
  FsxValidationResultMappingService,
  ValidationResultMappingService,
} from '../filing-editor/services/validation-result-mapping.service';
import {
  FsxAddContactAsParticipantOrchestrationService,
  AddContactAsParticipantOrchestrationService,
} from '../parties/orchestration-services/add-contact-as-participant-orchestration.service';
import {
  FsxAddDefaultParticipantOrchestrationService,
  AddDefaultParticipantOrchestrationService,
} from '../parties/orchestration-services/add-default-participant-orchestration.service';
import {
  FsxAddRepresentationOrchestrationService,
  AddRepresentationOrchestrationService,
} from '../parties/orchestration-services/add-representation-orchestration.service';
import {
  FsxAddSelectedContactsAsParticipantsOrchestrationService,
  AddSelectedContactsAsParticipantsOrchestrationService,
} from '../parties/orchestration-services/add-selected-contacts-as-participants-orchestration.service';
import {
  FsxAddSelectedContactsAsRepresentationOrchestrationService,
  AddSelectedContactsAsRepresentationOrchestrationService,
} from '../parties/orchestration-services/add-selected-contacts-as-representatives-orchestration.service';
import {
  FsxClearParticipantOrchestrationService,
  ClearParticipantOrchestrationService,
} from '../parties/orchestration-services/clear-participant-orchestration.service';
import {
  FsxClearRepresentationOrchestrationService,
  ClearRepresentationOrchestrationService,
} from '../parties/orchestration-services/clear-representation-orchestration.service';
import {
  FsxEditParticipantOrchestrationService,
  EditParticipantOrchestrationService,
} from '../parties/orchestration-services/edit-participant-orchestration.service';
import {
  FsxRemoveParticipantOrchestrationService,
  RemoveParticipantOrchestrationService,
} from '../parties/orchestration-services/remove-participant-orchestration.service';
import {
  FsxRemoveRepresentationOrchestrationService,
  RemoveRepresentationOrchestrationService,
} from '../parties/orchestration-services/remove-representation-orchestration.service';
import {
  FsxUpdateCaseRequestOrchestrationService,
  UpdateCaseRequestOrchestrationService,
} from '../parties/orchestration-services/update-case-request-orchestration.service';
import {
  FsxUpdateParticipantOrchestrationService,
  UpdateParticipantOrchestrationService,
} from '../parties/orchestration-services/update-participant-orchestration.service';
import {
  FsxUpdateRepresentationOrchestrationService,
  UpdateRepresentationOrchestrationService,
} from '../parties/orchestration-services/update-representation-orchestration.service';
import {
  FsxApplyParticipantFormUpdatesOrchestrationService,
  ApplyParticipantFormUpdatesOrchestrationService,
} from '../shared/services/apply-participant-form-updates-orchestration.service';
import {
  FsxOpenContactFormOrchestrationService,
  OpenContactFormOrchestrationService,
} from '../shared/services/open-contact-form-orchestration.service';
import {
  FsxOpenContactsListOrchestrationService,
  OpenContactsListOrchestrationService,
} from '../shared/services/open-contact-list-orchestration.service';
import {
  FsxOpenParticipantFormOrchestrationService,
  OpenParticipantFormOrchestrationService,
} from '../shared/services/open-participant-form-orchestration.service';
import {
  FsxFilingChecklistService,
  FilingChecklistService,
} from '../validation/filing-checklist/filing-checklist.service';
import {
  FsxFormGroupTransformService,
  FormGroupTransformService,
} from '../shared/services/form-group-transform.service';
import {
  ContactProfileDataService,
  FsxContactProfileDataService,
} from '../filing-editor/services/contact-profile-data.service';
import {
  FsxAddOrUpdateContactOrchestrationService,
  AddOrUpdateContactOrchestrationService,
} from '../shared/services/add-or-update-contact-orchestration.service';

@Component({
  selector: 'fsx-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [
    {
      provide: FsxFormGroupTransformService,
      useClass: FormGroupTransformService,
    },
    {
      provide: FsxContactsListTwoService,
      useClass: ContactsListTwoService,
    },
    {
      provide: FsxDefaultPartyService,
      useClass: DefaultPartyService,
    },
    {
      provide: FsxUpdateParticipantOrchestrationService,
      useClass: UpdateParticipantOrchestrationService,
    },
    {
      provide: FsxOpenContactFormOrchestrationService,
      useClass: OpenContactFormOrchestrationService,
    },
    {
      provide: FsxOpenParticipantFormOrchestrationService,
      useClass: OpenParticipantFormOrchestrationService,
    },
    {
      provide: FsxContactsListService,
      useClass: ContactsListService,
    },
    {
      provide: FsxPanelService,
      useClass: PanelService,
    },
    {
      provide: FsxCombinedFilingDataService,
      useClass: CombinedFilingDataService,
    },
    {
      provide: FsxCombinedFilingDataService2,
      useClass: CombinedFilingDataService,
    },
    {
      provide: FsxFilingSubTabsService,
      useClass: FilingSubTabsService,
    },
    {
      provide: FsxCaseRequestDataService,
      useClass: CaseRequestDataService,
    },
    {
      provide: FsxFilingDataService,
      useClass: FilingDataService,
    },
    {
      provide: FsxDocumentInfoDataService,
      useClass: DocumentInfoDataService,
    },
    {
      provide: FsxFilingProfileDataService,
      useClass: FilingProfileDataService,
    },
    {
      provide: FsxFilingSubTabsFactoryService,
      useClass: FilingSubTabsFactoryService,
    },
    {
      provide: FsxFilingEditorEventService,
      useClass: FilingEditorEventService,
    },
    {
      provide: FsxCaseRequestUpdateService,
      useClass: CaseRequestUpdateService,
    },
    {
      provide: FsxUploadFileService,
      useClass: UploadFileService,
    },
    {
      provide: FsxFilingEditorActionsService,
      useClass: FilingEditorActionsService,
    },
    {
      provide: FsxFirstLoadOrchestrationService,
      useClass: FirstLoadOrchestrationService,
    },
    {
      provide: FsxValidateCaseOrchestrationService,
      useClass: ValidateCaseOrchestrationService,
    },
    {
      provide: FsxValidatePartiesOrchestrationService,
      useClass: ValidatePartiesOrchestrationService,
    },
    {
      provide: FsxUpdateDocumentOrchestrationService,
      useClass: UpdateDocumentOrchestrationService,
    },
    {
      provide: FsxAddDocumentOrchestrationService,
      useClass: AddDocumentOrchestrationService,
    },
    {
      provide: FsxAddSupportingDocumentOrchestrationService,
      useClass: AddSupportingDocumentOrchestrationService,
    },
    {
      provide: FsxAddLeadDocumentOrchestrationService,
      useClass: AddLeadDocumentOrchestrationService,
    },
    {
      provide: FsxUpdateCaseRequestOrchestrationService,
      useClass: UpdateCaseRequestOrchestrationService,
    },
    {
      provide: FsxReplaceDocumentFileOrchestrationService,
      useClass: ReplaceDocumentFileOrchestrationService,
    },
    {
      provide: FsxAddDefaultParticipantOrchestrationService,
      useClass: AddDefaultParticipantOrchestrationService,
    },
    {
      provide: FsxAddContactAsParticipantOrchestrationService,
      useClass: AddContactAsParticipantOrchestrationService,
    },
    {
      provide: FsxClearParticipantOrchestrationService,
      useClass: ClearParticipantOrchestrationService,
    },
    {
      provide: FsxRemoveParticipantOrchestrationService,
      useClass: RemoveParticipantOrchestrationService,
    },
    {
      provide: FsxUploadFilesOrchestrationService,
      useClass: UploadFilesOrchestrationService,
    },
    {
      provide: FsxUploadAdditionalFieldFilesOrchestrationService,
      useClass: UploadAdditionalFieldFilesOrchestrationService,
    },
    {
      provide: FsxAddSelectedContactsAsParticipantsOrchestrationService,
      useClass: AddSelectedContactsAsParticipantsOrchestrationService,
    },
    {
      provide: FsxAddRepresentationOrchestrationService,
      useClass: AddRepresentationOrchestrationService,
    },
    {
      provide: FsxRemoveRepresentationOrchestrationService,
      useClass: RemoveRepresentationOrchestrationService,
    },
    {
      provide: FsxUpdateRepresentationOrchestrationService,
      useClass: UpdateRepresentationOrchestrationService,
    },
    {
      provide: FsxClearRepresentationOrchestrationService,
      useClass: ClearRepresentationOrchestrationService,
    },
    {
      provide: FsxApplyParticipantFormUpdatesOrchestrationService,
      useClass: ApplyParticipantFormUpdatesOrchestrationService,
    },
    {
      provide: FsxRemoveDocumentOrchestrationService,
      useClass: RemoveDocumentOrchestrationService,
    },
    {
      provide: FsxSwitchFilingOrchestrationService,
      useClass: SwitchFilingOrchestrationService,
    },
    {
      provide: FsxCloseFilingOrchestrationService,
      useClass: CloseFilingOrchestrationService,
    },
    {
      provide: FsxSubmitFilingOrchestrationService,
      useClass: SubmitFilingOrchestrationService,
    },
    {
      provide: FsxValidateDocumentsOrchestrationService,
      useClass: ValidateDocumentsOrchestrationService,
    },
    {
      provide: FsxCaseReviewValidationService,
      useClass: CaseReviewValidationService,
    },
    {
      provide: FsxAddSelectedContactsAsRepresentationOrchestrationService,
      useClass: AddSelectedContactsAsRepresentationOrchestrationService,
    },
    {
      provide: FsxCaseRequestBuilderService,
      useClass: CaseRequestBuilderService,
    },
    {
      provide: FsxValidationService,
      useClass: ValidationService,
    },
    {
      provide: FsxCaseValidationService,
      useClass: CaseValidationService,
    },
    {
      provide: FsxAddressValidationService,
      useClass: AddressValidationService,
    },
    {
      provide: FsxAdditionalFieldsValidationService,
      useClass: AdditionalFieldsValidationService,
    },
    {
      provide: FsxCurrencyValidationService,
      useClass: CurrencyValidationService,
    },
    {
      provide: FsxValidationHelperService,
      useClass: ValidationHelperService,
    },
    {
      provide: FsxValidationHelperFpService,
      useClass: ValidationHelperFpService,
    },
    {
      provide: FsxSelectionFieldValidationService,
      useClass: SelectionFieldValidationService,
    },
    {
      provide: FsxTextFieldValidationService,
      useClass: TextFieldValidationService,
    },
    {
      provide: FsxAliasValidationService,
      useClass: AliasValidationService,
    },
    {
      provide: FsxContactValidationService,
      useClass: ContactValidationService,
    },
    {
      provide: FsxDateValidationService,
      useClass: DateValidationService,
    },
    {
      provide: FsxDateTimeValidationService,
      useClass: DateTimeValidationService,
    },
    {
      provide: FsxDocumentDomainValidationService,
      useClass: DocumentDomainValidationService,
    },
    {
      provide: FsxDocumentFilesValidationService,
      useClass: DocumentFilesValidationService,
    },
    {
      provide: FsxNumberValidationService,
      useClass: NumberValidationService,
    },
    {
      provide: FsxSearchFieldValidationService,
      useClass: SearchFieldValidationService,
    },
    {
      provide: FsxPersonValidationService,
      useClass: PersonValidationService,
    },
    {
      provide: FsxEmailAddressValidationService,
      useClass: EmailAddressValidationService,
    },
    {
      provide: FsxPhoneValidationService,
      useClass: PhoneValidationService,
    },
    {
      provide: FsxIdentificationValidationService,
      useClass: IdentificationValidationService,
    },
    {
      provide: FsxPersonNameValidationService,
      useClass: PersonNameValidationService,
    },
    {
      provide: FsxOrganizationValidationService,
      useClass: OrganizationValidationService,
    },
    {
      provide: FsxTimeValidationService,
      useClass: TimeValidationService,
    },
    {
      provide: FsxDocumentValidationService,
      useClass: DocumentValidationService,
    },
    {
      provide: FsxParticipantValidationService,
      useClass: ParticipantValidationService,
    },
    {
      provide: FsxSingleParticipantValidationService,
      useClass: SingleParticipantValidationService,
    },
    {
      provide: FsxPartyValidationService,
      useClass: PartyValidationService,
    },
    {
      provide: FsxRepresentationValidationService,
      useClass: RepresentationValidationService,
    },
    {
      provide: FsxValidateReviewOrchestrationService,
      useClass: ValidateReviewOrchestrationService,
    },
    {
      provide: FsxCreateRequestDocumentService,
      useClass: CreateRequestDocumentService,
    },
    {
      provide: FsxEditParticipantOrchestrationService,
      useClass: EditParticipantOrchestrationService,
    },
    {
      provide: FsxLoadFilingFeesOrchestrationService,
      useClass: LoadFilingFeesOrchestrationService,
    },
    {
      provide: FsxFilingFeesDataService,
      useClass: FilingFeesDataService,
    },
    {
      provide: FsxFilingChecklistService,
      useClass: FilingChecklistService,
    },
    {
      provide: FsxPollingStateService,
      useClass: PollingStateService,
    },
    {
      provide: FsxValidationErrorsService,
      useClass: ValidationErrorsService,
    },
    {
      provide: FsxValidationResultMappingService,
      useClass: ValidationResultMappingService,
    },
    {
      provide: FsxValidationGroupErrorsService,
      useClass: ValidationGroupErrorsService,
    },
    {
      provide: FsxValidationIndicatorService,
      useClass: ValidationIndicatorService,
    },
    {
      provide: FsxSelectedContactsService,
      useClass: SelectedContactsService,
    },
    {
      provide: FsxFilingFeesGetterService,
      useClass: FilingFeesGetterService,
    },
    {
      provide: FsxOpenContactsListOrchestrationService,
      useClass: OpenContactsListOrchestrationService,
    },
    {
      provide: FsxContactFormService,
      useClass: ContactFormService,
    },
    {
      provide: FsxContactApiService,
      useClass: ContactApiService,
    },
    {
      provide: FsxAddressFormService,
      useClass: AddressFormService,
    },
    {
      provide: FsxEmailFormService,
      useClass: EmailFormService,
    },
    {
      provide: FsxAliasFormService,
      useClass: AliasFormService,
    },
    {
      provide: FsxIdentificationsFormService,
      useClass: IdentificationsFormService,
    },
    {
      provide: FsxOrganizationFormService,
      useClass: OrganizationFormService,
    },
    {
      provide: FsxPhoneFormService,
      useClass: PhoneFormService,
    },
    {
      provide: FsxPersonFormService,
      useClass: PersonFormService,
    },
    {
      provide: FsxContactProfileDataService,
      useClass: ContactProfileDataService,
    },
    {
      provide: FsxAddOrUpdateContactOrchestrationService,
      useClass: AddOrUpdateContactOrchestrationService,
    },
  ],
})
export class HomeComponent {
  public contactsSearchType = ContactsSearchTypeEnum;
}
