import { NgModule } from '@angular/core';
import { FsxUiComponentsModule } from '@fsx/ui-components';
import { SharedModule } from '@fsx/fsx-shared';
import { ContactsComponent } from './contacts.component';
import { ContactsSearchComponent } from './contacts-search/contacts-search.component';
import { ContactLinkedCardComponent } from './contact-link-card/contact-link-card.component';
import { ValidationModule } from '../validation/validation.module';
import { ContactFormPanelComponent } from './contact-form-panel/contact-form-panel.component';
import { ContactsListPanelComponent } from './contacts-list-panel/contacts-list-panel.component';
import { ParticipantFormComponent } from './participant-form/participant-form.component';
import { ParticipantFormPanelComponent } from './participant-form-panel/participant-form-panel.component';
import { ContactsFormComponent } from './contact-form/contact-form.component';
import { FsxPanelComponent } from '../shared/components/fsx-panel/fsx-panel.component';
import { ContactFormPanelHeaderComponent } from './contact-form-panel-header/contact-form-panel-header.component';
import { ParticipantFormPanelHeaderComponent } from './participant-form-panel-header/participant-form-panel-header.component';
import { ContactTypeSelectionComponent } from '../shared/components/contact-type-selection/contact-type-selection.component';
import { ContactsListTwoComponent } from '../contacts/contacts-list-two/contacts-list-two.component';
import { ContactsListActionsComponent } from '../contacts/contacts-list-two/contacts-list-actions/contacts-list-actions.component';
import { ContactsListPanelHeaderComponent } from '../contacts/contacts-list-two/contacts-list-panel-header/contacts-list-panel-header.component';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { ContactsListPanelFooterComponent } from './contacts-list-two/contacts-list-panel-footer/contacts-list-panel-footer.component';
import { ContactDetailComponent } from './contact-detail/contact-detail.component';

@NgModule({
  declarations: [
    ContactsComponent,
    ContactFormPanelComponent,
    ContactFormPanelHeaderComponent,
    ContactsListPanelComponent,
    ContactsFormComponent,
    ContactsSearchComponent,
    ContactLinkedCardComponent,
    ParticipantFormComponent,
    ParticipantFormPanelComponent,
    ParticipantFormPanelHeaderComponent,
    FsxPanelComponent,
    ContactTypeSelectionComponent,
    ContactsListTwoComponent,
    ContactDetailComponent,
    ContactsListActionsComponent,
    ContactsListPanelHeaderComponent,
    ContactsListPanelFooterComponent,
  ],
  imports: [
    SharedModule,
    FsxUiComponentsModule,
    ValidationModule,
    InfiniteScrollModule,
  ],
  exports: [ContactsComponent, ContactsSearchComponent],
})
export class ContactsModule {}
