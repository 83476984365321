import { Component, Inject, Injector, Input } from '@angular/core';
import { ContactsSearchTypeEnum } from './contacts.model';
import {
  FsxPanelService,
  IPanelService,
} from '../shared/services/panel.service';

@Component({
  selector: 'fsx-contacts',
  templateUrl: './contacts.component.html',
  styleUrls: ['./contacts.component.scss'],
})
export class ContactsComponent {
  /**
   * An input binding allowing the parent component to specify the type of search that
   * the form should make when opened "Contacts" or "Attorneys".
   */
  @Input() searchType!: ContactsSearchTypeEnum;

  constructor(
    @Inject(FsxPanelService) private readonly panelService: IPanelService,
    private readonly injector: Injector
  ) {}

  /**
   * A handler function for the button's click event.
   */
  onOpenListClicked(): void {
    this.panelService.openContactsListPanel({
      contactsListConfig: {
        // The search type to open the form in.
        searchType: this.searchType,

        // Empty specs is okay here since this component only exists outside of the
        // filing editor, we don't need to show the "Party Type" / "Attorney Type"
        // dropdown because there is nothing to add to.
        participantSpecs: [],

        // Empty callback is also fine because there is nothing to add to outside
        // of the filing editor, the list is just a read only view.
        addCallback: () => {
          return;
        },
      },
      injector: this.injector,
    });
  }
}
