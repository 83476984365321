<p class="form-title">
  <fsx-row-validation
    [isValid]="validationErrors.length === 0"
    [cssClass]="'inline-heading'"
  ></fsx-row-validation>
  {{ labelText }}
</p>
<ng-container *ngIf="validationErrors.length > 0">
  <div class="validation-error-container">
    <div
      class="validation-error-message"
      *ngFor="let validationError of validationErrors"
    >
      {{ validationError.errorMessage }}
    </div>
  </div>
</ng-container>
