import {
  Component,
  EventEmitter,
  Inject,
  Input,
  OnChanges,
  Output,
} from '@angular/core';
import { ContactsSearchTypeEnum } from '../../contacts.model';
import { map } from 'rxjs';
import { ContactSummaryViewModel } from '@fsx/fsx-shared';
import {
  FsxSelectedContactsService,
  ISelectedContactsService,
} from '../../contacts-list/selected-contacts.service';

/**
 * The input configuration object properties, which the panel footer
 * componnet uses to create the view model for display in the template.
 */
export interface ContactsListPanelFooterConfig {
  searchType: ContactsSearchTypeEnum;
}

/**
 * The view model object properties, which are derived from the input
 * configuration object properties.
 */
export interface ContactsListPanelFooterViewModel {
  /**
   * The text to display next to the count of selected contacts/attorneys.
   */
  selectedText: string;
}

@Component({
  selector: 'fsx-contacts-list-panel-footer',
  templateUrl: './contacts-list-panel-footer.component.html',
  styleUrls: ['./contacts-list-panel-footer.component.scss'],
})
export class ContactsListPanelFooterComponent implements OnChanges {
  /**
   * The input configuration object, which we can create the view model from.
   */
  @Input() config!: ContactsListPanelFooterConfig;

  /**
   * An output event to notify the parent panel component that the cancel button was clicked.
   */
  @Output() cancelButtonClickedEvent = new EventEmitter<void>();

  /**
   * An output event to notify the parent panel component that the add button was clicked.
   */
  @Output() addButtonClickedEvent = new EventEmitter<void>();

  /**
   * The number of selected contacs to display in the footer.
   */
  countOfSelectedContacts$ =
    this.selectedContactsService.selectedContacts$.pipe(
      map((contactSummaries: ContactSummaryViewModel[]) => {
        return contactSummaries.length;
      })
    );

  /**
   * The observable boolean value indicating whether we can submit the form or not.
   * Used in the footer to enable/disable the Add button.
   */
  canSubmitForm$ = this.selectedContactsService.selectedContacts$.pipe(
    map((contactSummaries: ContactSummaryViewModel[]) => {
      const canSubmitForm = contactSummaries.length > 0;
      return canSubmitForm;
    })
  );

  /**
   * The view model object, which we derive from the input configuration object.
   */
  vm!: ContactsListPanelFooterViewModel;

  constructor(
    @Inject(FsxSelectedContactsService)
    readonly selectedContactsService: ISelectedContactsService
  ) {}

  ngOnChanges(): void {
    this.createViewModelFromConfig();
  }

  /**
   * A method to allow the view model to be created from the incoming configuration object.
   */
  private createViewModelFromConfig(): void {
    this.vm = {
      selectedText:
        this.config.searchType === ContactsSearchTypeEnum.contacts
          ? 'Selected Contacts'
          : 'Selected Representation',
    };
  }

  /**
   * A handler function for the Cancel button's click event.
   */
  onCancelButtonClicked(): void {
    this.cancelButtonClickedEvent.emit();
  }

  /**
   * A handler function for the Add button's click event.
   */
  onAddButtonClicked() {
    this.addButtonClickedEvent.emit();
  }
}
