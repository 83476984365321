<div class="grid-container party-grid-container prevent-select">
  <div class="title-row" fxLayout="row" fxLayoutAlign="start center">
    <fsx-row-header-validation
      [errorCount]="errorCount"
      mode="inline"
      (errorFilterChanged)="validationFilterChanged($event)"
    ></fsx-row-header-validation>

    <div fxFlex="nogrow" class="expand-collapse-col">
      <mat-icon (click)="onToggleExpandTitleRow($event)">
        {{ partiesGridOptions.expanded ? "arrow_drop_up" : "arrow_drop_down" }}
      </mat-icon>
    </div>

    <div fxFlex="none">{{ partiesGridOptions.title }}</div>

    <span
      *ngIf="!isMaxAllowed"
      fxFlex="none"
      class="add-new-row-inner material-icons add-icon"
      (click)="onAddParticipantClicked()"
    >
      add_circle_outline
    </span>
  </div>
  <div
    class="header-row parties-header-row"
    *ngIf="partiesGridOptions.expanded"
  >
    <div
      fxFlex="nogrow"
      class="select-participant-col"
      fxLayout="column"
      fxLayoutAlign="center"
    >
      <mat-checkbox color="primary"></mat-checkbox>
    </div>
    <div fxFlex="nogrow" class="party-type-col">PARTY/TYPE</div>
    <div fxFlex="nogrow" class="representation-col">REPRESENTATION</div>
    <div fxFlex class="role-col">ROLE</div>
  </div>
  <div class="content-row" [hidden]="!partiesGridOptions.expanded">
    <div
      *ngFor="
        let row of partiesGridConfig.partiesGridRows;
        let rowIndex = index;
        trackBy: rowIndex
      "
      class="filterable-row"
      [ngClass]="
        (row
        | partyIsValid
          : row.participant.isValid
          : row.party.isValid
          : row.party.isRepresentationValid
          : row.representationGridRows)
          ? validationFilteredClass
          : ''
      "
    >
      <div
        class="data-row"
        [ngClass]="{
          'ghost-row': isGhostRow(row)
        }"
        (click)="onToggleExpandDetailRow($event, row)"
      >
        <div class="inner-data-row">
          <fsx-row-validation
            [isValid]="
              row
                | partyIsValid
                  : row.participant.isValid
                  : row.party.isValid
                  : row.party.isRepresentationValid
                  : row.representationGridRows
            "
          ></fsx-row-validation>
          <div fxFlex="nogrow" class="select-participant-col">
            <mat-checkbox
              color="primary"
              (click)="onParticipantCheckboxClicked($event)"
            ></mat-checkbox>
          </div>
          <div
            fxFlex="nogrow"
            fxLayout="column"
            fxLayoutAlign="center start"
            class="party-type-col"
          >
            <!-- ToDo: Added caption check due to JTI returning null linkedContact on a valid participant -->
            <div
              *ngIf="
                row.participant?.linkedContact || !!row.participant.caption;
                else default
              "
            >
              <span class="participant-boldtext">{{
                row.participant.caption
              }}</span>
              <span
                *ngIf="row.party?.participantCategory?.caption"
                class="participant-subtext"
                >{{ row.party.participantCategory?.caption }}</span
              >
            </div>
            <ng-template #default>
              <span class="participant-boldtext">{{
                this.participantListCaption
              }}</span>
              <span class="participant-subtext">Required</span>
            </ng-template>
          </div>
          <div
            fxFlex="nogrow"
            class="representation-col"
            fxLayout="column"
            fxLayoutAlign="center start"
          >
            <div
              class="inner-representation-col"
              fxLayout="column"
              fxLayoutAlign="start start"
            >
              <div *ngIf="row.party.isSelfRepresented; else notSelf">
                Self-represented
              </div>
              <ng-template #notSelf>
                <div *ngFor="let rep of row.party.representation">
                  <p class="caption">{{ rep.caption }}</p>
                </div>
              </ng-template>
              <div
                class="plus-n-more"
                fxFlex
                fxLayout="column"
                fxLayoutAlign="end start"
              >
                <p *ngIf="row.party.representation?.length as length">
                  <span [hidden]="length <= 3">+{{ length - 3 }} more</span>
                </p>
              </div>
            </div>
          </div>
          <div
            fxFlex="nogrow"
            class="role-col"
            fxLayout="column"
            fxLayoutAlign="center start"
          >
            <p *ngFor="let repItem of row.party.representation | slice: 0:3">
              {{ repItem.participantCategory?.caption }}
            </p>
          </div>
          <div fxFlex fxLayout="column" fxLayoutAlign="center end">
            <div class="hover-icons" fxLayout="row" fxLayoutAlign="end center">
              <!-- ToDo: Calculate if read only here -->
              <button
                *ngIf="!row.party.efmKey"
                class="icon-button delete-icon-button"
                fsxPreventKeyboardEventBubbling
                (keydown.enter)="onRemoveParticipantClicked($event, row)"
                (click)="onRemoveParticipantClicked($event, row)"
              >
                <div class="form-delete-button"></div>
              </button>
              <span
                id="toggle-expand-detail-row-icon"
                class="material-icons-outlined"
                (click)="onToggleExpandDetailRow($event, row)"
              >
                {{
                  expandedRowIndex === row.rowIndex
                    ? "expand_less"
                    : "expand_more"
                }}
              </span>
            </div>
          </div>
        </div>
      </div>
      <div *ngIf="expandedRowIndex === row.rowIndex">
        <fsx-parties-grid-detail
          [row]="row"
          [partiesMap]="partiesMap"
          [isInitiating]="isInitiating"
          [resolver]="resolver"
          [partiesGridConfig]="partiesGridConfig"
          [existingPartiesContactIds]="existingPartiesContactIds"
          (attorneySelectedEvent)="attorneySelectedEventHandler($event)"
          (contactSummariesSelectedEvent)="
            contactSummariesSelectedEventHandler($event)
          "
          (addRepresentationEvent)="addRepresentationEventHandler($event)"
          (validateParentPartyEvent)="validateParticipant($event)"
        >
        </fsx-parties-grid-detail>
      </div>
    </div>
    <div class="add-new-row" fxLayout="column">
      <button
        class="add-new-row-inner"
        (click)="onAddParticipantClicked()"
        fxLayout="row"
        fxFlex="nogrow"
        fxLayoutAlign="start center"
      >
        <span class="material-icons add-icon"> add_circle_outline </span>
        Add
        {{
          partiesGridConfig.partiesGridRows.length
            ? combinedFilingData.filing.mode === FilingMode.Subsequent
              ? "Sending"
              : "Another " + partiesGridOptions.descriptor
            : ""
        }}
        Party
      </button>
    </div>
  </div>
</div>
