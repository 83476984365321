<div *ngIf="vm$ | async as vm">
  <div
    #container
    id="contacts-container"
    [ngClass]="{ 'half-height': vm.selectedContacts.length > 0 }"
  >
    <mat-table
      class="contact-table prevent-select"
      matSort
      matSortActive="created"
      matSortDisableClear
      matSortDirection="desc"
      [dataSource]="vm.availableContacts"
      (matSortChange)="onSortChanged($event)"
      multiTemplateDataRows
      infinite-scroll
      [infiniteScrollDistance]="2"
      [infiniteScrollThrottle]="300"
      [infiniteScrollContainer]="'#contacts-container'"
      [fromRoot]="true"
      (scrolled)="onScrollDown()"
    >
      <!-- This is where we declare the column template for the checkbox selection column on the main contact summary row-->
      <ng-container matColumnDef="select-contact">
        <mat-header-cell fxFlex="8" *matHeaderCellDef disableClear>
          <input
            type="checkbox"
            class="checkbox"
            (click)="onSelectAllCheckboxClicked()"
          />
        </mat-header-cell>
        <mat-cell fxFlex="8" *matCellDef="let row">
          <input
            type="checkbox"
            class="checkbox"
            [(ngModel)]="row.isSelected"
            (click)="onContactCheckboxClicked(row)"
          />
        </mat-cell>
      </ng-container>

      <!-- This is where we declare the column template for the contact's name on the main contact summary row -->
      <ng-container matColumnDef="caption">
        <mat-header-cell
          fxFlex="25"
          *matHeaderCellDef
          mat-sort-header
          disableClear
        >
          Name
        </mat-header-cell>
        <mat-cell class="name" fxFlex="25" *matCellDef="let row">{{
          row.caption
        }}</mat-cell>
      </ng-container>

      <!-- This is where we declare the column template for the entity on the main contact summary row -->
      <ng-container matColumnDef="type">
        <mat-header-cell
          fxFlex="12"
          *matHeaderCellDef
          mat-sort-header
          disableClear
        >
          Entity
        </mat-header-cell>
        <mat-cell fxFlex="12" *matCellDef="let row">{{ row.type }}</mat-cell>
      </ng-container>

      <!-- This is where we declare the column template for the email address on the main contact summary row -->
      <ng-container matColumnDef="primary-email">
        <mat-header-cell fxFlex *matHeaderCellDef mat-sort-header disableClear>
          Email
        </mat-header-cell>
        <mat-cell fxFlex *matCellDef="let row">{{
          row.primaryEmailAddress
        }}</mat-cell>
      </ng-container>

      <!-- This is where we declare the column template for the client/nar number on the main contact summary row -->
      <ng-container matColumnDef="client-or-bar-number">
        <mat-header-cell
          fxFlex="8"
          *matHeaderCellDef
          mat-sort-header
          disableClear
        >
          <span *ngIf="searchType === searchTypeEnum.contacts">Client No.</span>
          <span *ngIf="searchType === searchTypeEnum.attorneys">Bar No.</span>
        </mat-header-cell>
        <mat-cell fxFlex="8" *matCellDef="let row">
          <span *ngIf="searchType === searchTypeEnum.contacts">{{
            row.clientNameText
          }}</span>
          <span *ngIf="searchType === searchTypeEnum.attorneys">{{
            row.barNumber
          }}</span>
        </mat-cell>
      </ng-container>

      <!-- This is where we declare the column template for the hover icons on the main contact summary row -->
      <ng-container matColumnDef="hover-icons">
        <mat-header-cell fxFlex="11" *matHeaderCellDef disableClear>
          &nbsp;
        </mat-header-cell>
        <mat-cell
          fxFlex="11"
          fxLayoutAlign="end center"
          *matCellDef="let row"
          class="hover-icons"
        >
          <span
            id="toggle-expand-detail-row-icon"
            class="material-icons-outlined"
            (click)="onToggleExpandRowClicked($event, row)"
          >
            {{ expandedRowId === row.id ? "expand_less" : "expand_more" }}
          </span>
        </mat-cell>
      </ng-container>

      <!-- This is where we declare the template for the column in the detail row-->
      <ng-container matColumnDef="expandedDetail">
        <mat-cell
          *matCellDef="let contactSummary"
          [attr.colspan]="10"
          style="width: 750px"
        >
          <div
            fxLayout="column"
            fxFlex
            fxFill
            class="detail-container"
            *ngIf="vm.expandedContactId === contactSummary.id"
          >
            <fsx-contact-detail
              [contactId]="vm.expandedContactId"
              (editContactEvent)="editContactEventHandler(contactSummary, 0)"
            >
            </fsx-contact-detail>
          </div>
        </mat-cell>
      </ng-container>

      <!-- This is where we render the header row -->
      <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>

      <!-- 
        This is where we render the contact summary rows.
        - The displayedColumns array dictates which column templates to use.
       -->
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>

      <!-- 
        This is where we render the expandable detail row.
        - The columns array restricts the rendered columns to those relevant to the expanded detail view
      -->
      <tr
        mat-row
        *matRowDef="let row; columns: ['expandedDetail']"
        class="detail-row"
      ></tr>
    </mat-table>
  </div>

  <div *ngIf="vm.selectedContacts.length > 0">
    <hr />
    <div
      id="selected-contacts-container"
      *ngIf="vm.selectedContacts.length > 0"
    >
      <mat-table
        class="selected-contact-table prevent-select"
        matSort
        matSortActive="created"
        matSortDisableClear
        matSortDirection="desc"
        [dataSource]="vm.selectedContacts"
        (matSortChange)="onSortChanged($event)"
        (scrolled)="onScrollDown()"
      >
        <ng-container matColumnDef="select-contact">
          <mat-header-cell fxFlex="8" *matHeaderCellDef disableClear>
            <input
              type="checkbox"
              class="checkbox"
              checked
              (click)="onUnselectAllCheckboxClicked()"
            />
          </mat-header-cell>
          <mat-cell fxFlex="8" *matCellDef="let row">
            <input
              type="checkbox"
              class="checkbox"
              [(ngModel)]="row.isSelected"
              (click)="onContactCheckboxClicked(row)"
            />
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="caption">
          <mat-header-cell
            fxFlex="25"
            *matHeaderCellDef
            disableClear
          ></mat-header-cell>
          <mat-cell class="name" fxFlex="25" *matCellDef="let row">{{
            row.caption
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="type">
          <mat-header-cell
            fxFlex="12"
            *matHeaderCellDef
            disableClear
          ></mat-header-cell>
          <mat-cell fxFlex="12" *matCellDef="let row">
            <fsx-participant-spec-selection
              [participantSpecs]="participantSpecs"
              [readOnly]="false"
              [initialValue]="''"
            >
            </fsx-participant-spec-selection>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="primary-email">
          <mat-header-cell
            fxFlex
            *matHeaderCellDef
            disableClear
          ></mat-header-cell>
          <mat-cell fxFlex *matCellDef="let row">{{
            row.primaryEmailAddress
          }}</mat-cell>
        </ng-container>

        <ng-container matColumnDef="client-or-bar-number">
          <mat-header-cell
            fxFlex="8"
            *matHeaderCellDef
            disableClear
          ></mat-header-cell>
          <mat-cell fxFlex="8" *matCellDef="let row">
            <span *ngIf="searchType === searchTypeEnum.contacts">{{
              row.clientNameText
            }}</span>
            <span *ngIf="searchType === searchTypeEnum.attorneys">{{
              row.barNumber
            }}</span>
          </mat-cell>
        </ng-container>

        <ng-container matColumnDef="hover-icons">
          <mat-header-cell
            fxFlex="11"
            *matHeaderCellDef
            disableClear
          ></mat-header-cell>
          <mat-cell
            fxFlex="11"
            fxLayoutAlign="end center"
            *matCellDef="let row"
            class="hover-icons"
          >
          </mat-cell>
        </ng-container>

        <!-- <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row> -->

        <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>
      </mat-table>
    </div>
  </div>
</div>
