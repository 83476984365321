import { Observable, Subject } from 'rxjs';
import {
  DocumentInfo,
  CaseRequestViewModel,
  RequestCaseViewModel,
  CaseSpec,
  RequestDocumentViewModel,
  DocumentSpec,
  ValidationResult,
  FilingProfile,
  FilingModeSpec,
  Filing,
} from '../../../types';
import {
  FsxCaseReviewValidationService,
  ICaseReviewValidationService,
} from './case-review-validation.service';
import {
  FsxCaseValidationService,
  ICaseValidationService,
} from './case-validation.service';
import {
  FsxDocumentFilesValidationService,
  IDocumentFilesValidationService,
} from './document-files-validation.service';
import {
  FsxDocumentValidationService,
  IDocumentValidationService,
} from './document-validation.service';
import { Inject, Injectable, InjectionToken } from '@angular/core';

export const FsxValidationService = new InjectionToken<IValidationService>(
  'FsxValidationService'
);

export interface ValidationResultsViewModel {
  location: string;
  errors: string[];
}

export interface IValidationService {
  serverValidationErrors$: Observable<ValidationResultsViewModel[]>;

  validateCase(
    requestCase: RequestCaseViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel,
    caseSpec?: CaseSpec | null | undefined
  ): boolean;

  setDocumentFiles(documentFiles: DocumentInfo[] | undefined): void;

  validateAllDocuments(
    caseRequest: CaseRequestViewModel,
    modeSpec: FilingModeSpec,
    filingProfile: FilingProfile
  ): void;

  validateDocument(
    document: RequestDocumentViewModel,
    spec: DocumentSpec,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean;

  validateCaseReview(
    filing: Filing,
    caseRequest: CaseRequestViewModel,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec
  ): void;

  setServerValidationErrors(validationResult: ValidationResult): void;
}

@Injectable()
export class ValidationService implements IValidationService {
  private validationErrors$$ = new Subject<ValidationResultsViewModel[]>();
  public serverValidationErrors$ = this.validationErrors$$.asObservable();

  constructor(
    @Inject(FsxCaseValidationService)
    private readonly caseValidationService: ICaseValidationService,
    @Inject(FsxDocumentFilesValidationService)
    private readonly documentFilesValidationService: IDocumentFilesValidationService,
    @Inject(FsxDocumentValidationService)
    private readonly documentValidationService: IDocumentValidationService,
    @Inject(FsxCaseReviewValidationService)
    private readonly caseReviewValidation: ICaseReviewValidationService
  ) {}

  public validateCase(
    requestCase: RequestCaseViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel,
    caseSpec?: CaseSpec | null | undefined
  ): boolean {
    return this.caseValidationService.validateCase(
      requestCase,
      filingProfile,
      caseRequest,
      caseSpec
    );
  }

  public setDocumentFiles(documentFiles: DocumentInfo[] | undefined): void {
    this.documentFilesValidationService.setDocumentFiles(documentFiles);
  }

  public validateAllDocuments(
    caseRequest: CaseRequestViewModel,
    modeSpec: FilingModeSpec,
    filingProfile: FilingProfile
  ): void {
    this.documentValidationService.validateAllDocuments(
      caseRequest,
      modeSpec,
      filingProfile,
      []
    );
  }

  public validateDocument(
    document: RequestDocumentViewModel,
    spec: DocumentSpec,
    scope: CaseRequestViewModel,
    filingProfile: FilingProfile,
    caseRequest: CaseRequestViewModel
  ): boolean {
    return this.documentValidationService.validateDocument(
      document,
      spec,
      scope,
      filingProfile,
      caseRequest
    );
  }

  public validateCaseReview(
    filing: Filing,
    caseRequest: CaseRequestViewModel,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec
  ): void {
    this.caseReviewValidation.validateCaseReview(
      filing,
      caseRequest,
      filingProfile,
      modeSpec
    );
  }

  public setServerValidationErrors(validationResult: ValidationResult): void {
    if (validationResult.errors === 0) {
      return;
    }

    const errors: ValidationResultsViewModel[] = [];

    const validations = validationResult.validations;
    if (!!validations) {
      const keys = Object.keys(validations);

      for (let key of keys) {
        const res: ValidationResultsViewModel = {
          location: this.getServerValidationDescriptiveKey(key),
          errors: [],
        };

        for (let error of validations[key]) {
          res.errors.push(error.caption);
        }

        errors.push(res);
      }

      this.validationErrors$$.next(errors);
    }
  }

  private getServerValidationDescriptiveKey(key: string): string {
    let descriptiveKey = key;

    const parts = key.split('.');

    if (parts.length > 1) {
      const indexStart = parts[1].indexOf('[');

      if (indexStart > -1) {
        const indexEnd = parts[1].indexOf(']', indexStart);
        const indexNumber = parts[1].substring(indexStart + 1, indexEnd);
        const entity = parts[1].substring(0, indexStart);
        const entityIndex = parseInt(indexNumber) + 1;
        descriptiveKey = `${entity} number ${entityIndex}`;
      } else {
        descriptiveKey = parts[1];
      }
    }

    return descriptiveKey;
  }
}
