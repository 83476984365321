<div class="contact-detail-container" *ngIf="vm$ | async as vm">
  <div fxLayout="column" *ngIf="vm.contact">
    <div fxLayout="row" fxLayoutAlign="space-between" fxLayoutGap="15px">
      <div id="column-one" fxFlex="50">
        <p id="contact-name" fxLayout="column" fxLayoutAlign="start start">
          <span id="contact-caption">{{ vm.contact.caption }}</span>
          <label class="readonly-label">{{ vm.contact.type }}</label>
        </p>
        <div
          class="data-group"
          *ngFor="let contactAddress of vm.contact.addresses"
        >
          {{ contactAddress | generalAddressCaption: vm.addressProfileSpec }}
          <label>{{ contactAddress.category?.caption }}</label>
        </div>
        <div
          class="data-group"
          *ngFor="let stateAndBarNumber of vm.stateAndBarNumbers"
        >
          {{ stateAndBarNumber.state }} -
          {{ stateAndBarNumber.barNumber.identificationKey }}
          <label
            >State - {{ stateAndBarNumber.barNumber.category.caption }}</label
          >
        </div>
      </div>
      <div id="column-two" fxFlex="50">
        <div class="data-group" *ngFor="let phone of vm.contact.phones">
          {{ phone.caption }}
          <label>{{ phone.category?.caption }}</label>
        </div>
        <div class="data-group" *ngFor="let emailAddress of vm.contact.emails">
          {{ emailAddress.caption }}
          <label>{{ emailAddress.category?.caption }}</label>
        </div>
      </div>
      <div fxLayout="row" fxLayoutAlign="end start">
        <mat-icon
          matTooltip="Edit"
          [matTooltipShowDelay]="200"
          [matTooltipHideDelay]="0"
          [matTooltipPosition]="'above'"
          [matTooltipClass]="'fsx-tooltip'"
          class="hover-icon edit-icon"
          (click)="onEditIconClicked()"
        >
        </mat-icon>
      </div>
    </div>
  </div>
</div>
