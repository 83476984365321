import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartySummary,
  CasePartySummaryGridResult,
  CasePartyViewModel,
  CombinedFilingData,
  FsxCombinedFilingApiService,
  FsxFilingApiService,
  FsxFilingTabsService,
  ICombinedFilingApiService,
  IFilingApiService,
  IFilingTabsService,
  RECENT_TRANSACTION_ID,
  RequestParticipantSummary,
  RequestParticipantSummaryGridResult,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  combineLatest,
  forkJoin,
  map,
  merge,
  of,
  switchMap,
  tap,
} from 'rxjs';
import {
  FsxFilingDataService,
  IFilingDataService,
} from '../filing-data.service';
import {
  FsxFilingProfileDataService,
  IFilingProfileDataService,
} from '../filing-profile-data.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';
import {
  FsxDocumentInfoDataService,
  IDocumentInfoDataService,
} from '../document-info-data.service';
import { FsxPartyDataService, IPartyDataService } from '../party-data.service';
import {
  FsxParticipantDataService,
  IParticipantDataService,
} from '../participant-data.service';
import {
  FsxContactProfileDataService,
  IContactProfileDataService,
} from '../contact-profile-data.service';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IFirstLoadOrchestrationService to use at runtime.
 */
export const FsxFirstLoadOrchestrationService =
  new InjectionToken<IFirstLoadOrchestrationService>(
    'FsxFirstLoadOrchestrationService'
  );

/**
 * A blueprint for an orchestration service, which handles the loading of data when first
 * entering the filing editor.
 */
export interface IFirstLoadOrchestrationService {
  /**
   * The pipeline of orchestration steps to load and store the data for the filing being edited.
   */
  loadCombinedFilingData$: Observable<void>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  loadForFilingId(filingId: string): void;
}

/**
 * A concrete implementation of an orchestration service, which handles the loading of data
 * when first entering the filing editor.
 */
@Injectable()
export class FirstLoadOrchestrationService
  implements IFirstLoadOrchestrationService
{
  private loadForFilingId$$ = new Subject<string>();

  /**
   * The pipeline of orchestration steps to load and store the data for the filing being edited.
   */
  private filingIdToLoadFor$ = merge(
    this.loadForFilingId$$.pipe(
      tap((filingId: string) => {
        localStorage.setItem(RECENT_TRANSACTION_ID, filingId);
      })
    )
  );

  /**
   * The pipeline of orchestration steps to load and store the data for the filing being edited.
   */
  loadCombinedFilingData$: Observable<void> = this.filingIdToLoadFor$.pipe(
    switchMap((filingId: string) => {
      // First we retrieve the CombinedFIlingData (Filing, FilingProfile and CaseRequest)
      return this.combinedFilingApiService.getCombinedFilingData(filingId).pipe(
        switchMap((combinedFilingData: CombinedFilingData) => {
          // Second we add a tab for the filing (to a stored array of filing tabs)
          return this.filingTabsService.addTab(combinedFilingData.filing).pipe(
            switchMap(() => {
              // Next we need to retrieve the CaseParty and Request Participant objects.
              // (1/2) This is the first of two steps where we retrieve the summaries...

              const { caseRequest, filing, filingProfile, contactProfile } =
                combinedFilingData;
              const filingId: string = filing.id;
              const efmKey: string | undefined | null =
                filing.courtCases[0].efmKey;

              // Conditionally get CasePartySummary objects
              const casePartySummaries$ = efmKey
                ? this.filingApiService.getParties(filingId, efmKey).pipe(
                    map((gridResult: CasePartySummaryGridResult) => {
                      return gridResult.data;
                    })
                  )
                : of([]); // Return empty array if no efmKey

              // Conditionally get RequestParticipantSummary objects
              const requestParticipantSummaries$ = efmKey
                ? this.filingApiService.getParticipants(filingId, efmKey).pipe(
                    map((gridResult: RequestParticipantSummaryGridResult) => {
                      const requestParticipantSummaries: RequestParticipantSummary[] =
                        gridResult.data;

                      // We want to exclude any RequestParticipantSummary objects that come back from the server without a participantName.
                      const filteredRequestParticipantSummaries: RequestParticipantSummary[] =
                        requestParticipantSummaries.filter(
                          (participantSummary: RequestParticipantSummary) => {
                            const hasParticipantName =
                              !!participantSummary.name;
                            return hasParticipantName;
                          }
                        );
                      return filteredRequestParticipantSummaries;
                    })
                  )
                : of([]); // Return empty array if no efmKey

              return forkJoin([
                casePartySummaries$,
                requestParticipantSummaries$,
              ]).pipe(
                switchMap(
                  ([casePartySummaries, requestParticipantSummaries]: [
                    CasePartySummary[],
                    RequestParticipantSummary[]
                  ]) => {
                    // (2/2) This is the second step where we lookup the full CaseParty and RequestParticipant objects...

                    // Filter out any CasePartySummary objects that don't have an efmKey.
                    // In the next step we must have the efmKey to make the getParty() call.
                    const casePartySummariesWithEfmKey =
                      casePartySummaries.filter(
                        (casePartySummary: CasePartySummary) => {
                          const hasEfmKey = !!casePartySummary.efmKey;
                          return hasEfmKey;
                        }
                      );

                    // Conditional observable to determine if/how to retrieve CaseParty objects for the case.
                    // - For SubF, we look them up via an array of API calls.
                    // - For OPF, we don't look them up, we just return empty array.
                    const parties$ = efmKey
                      ? combineLatest([
                          ...casePartySummariesWithEfmKey.map((party) => {
                            return this.filingApiService.getParty(
                              filingId,
                              efmKey,
                              party.efmKey!
                            );
                          }),
                        ])
                      : of([]);

                    // Conditional observable to determine if/how to retrieve RequestParticipant objects for the case.
                    // - For SubF, we look them up via an array of API calls.
                    // - For OPF, we don't look them up, we just return empty array.
                    const participants$ = efmKey
                      ? combineLatest([
                          ...requestParticipantSummaries.map(
                            (participantSummary) => {
                              return this.filingApiService.getParticipant(
                                filingId,
                                efmKey,
                                participantSummary.name
                              );
                            }
                          ),
                        ])
                      : of([]);

                    // The switchMap subscribes to the above observables and emits the CaseParty and RequestParticipant arrays...
                    return combineLatest([
                      parties$.pipe(
                        map((parties: (CasePartyViewModel | null)[]) => {
                          // Remove any nulls that may have been returned from a failed API call.
                          return parties.filter(
                            (party: CasePartyViewModel | null) => {
                              return party !== null;
                            }
                          ) as CasePartyViewModel[];
                        })
                      ),
                      participants$.pipe(
                        map(
                          (
                            participants: (RequestParticipantViewModel | null)[]
                          ) => {
                            // Remove any nulls that may have been returned from a failed API call.
                            return participants.filter(
                              (
                                participant: RequestParticipantViewModel | null
                              ) => {
                                return participant !== null;
                              }
                            ) as RequestParticipantViewModel[];
                          }
                        )
                      ),
                    ]).pipe(
                      map(
                        ([parties, participants]: [
                          CasePartyViewModel[],
                          RequestParticipantViewModel[]
                        ]) => {
                          // Store all of the previously retrieved data to their respective data services.
                          this.partyDataService.setCaseParties(parties);
                          this.participantDataService.setParticipants(
                            participants
                          );
                          this.caseRequestDataService.setCaseRequestData(
                            caseRequest
                          );
                          this.filingDataService.setFilingData(filing);
                          this.filingProfileDataService.setFilingProfileData(
                            filingProfile
                          );
                          this.documentInfoDataService.setDocumentInfos(
                            combinedFilingData.documentInfos
                          );
                          this.contactProfileDataService.setContactProfileData(
                            contactProfile
                          );
                          return;
                        }
                      )
                    );
                  }
                )
              );
            })
          );
        })
      );
    })
  );

  public constructor(
    @Inject(FsxFilingTabsService)
    private readonly filingTabsService: IFilingTabsService,
    @Inject(FsxCombinedFilingApiService)
    private readonly combinedFilingApiService: ICombinedFilingApiService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxPartyDataService)
    private readonly partyDataService: IPartyDataService,
    @Inject(FsxParticipantDataService)
    private readonly participantDataService: IParticipantDataService,
    @Inject(FsxFilingProfileDataService)
    private readonly filingProfileDataService: IFilingProfileDataService,
    @Inject(FsxContactProfileDataService)
    private readonly contactProfileDataService: IContactProfileDataService,
    @Inject(FsxDocumentInfoDataService)
    private readonly documentInfoDataService: IDocumentInfoDataService,
    @Inject(FsxFilingDataService)
    private readonly filingDataService: IFilingDataService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  loadForFilingId(filingId: string): void {
    this.loadForFilingId$$.next(filingId);
  }
}
