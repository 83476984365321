import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CasePartyViewModel,
  CaseRequestViewModel,
  CombinedFilingData,
  FILING_SUB_TABS,
  FilingSubTabItem,
  ParticipantCommonCategory,
  RequestParticipantRepresentationViewModel,
} from '@fsx/fsx-shared';
import {
  Observable,
  Subject,
  filter,
  map,
  merge,
  pairwise,
  withLatestFrom,
} from 'rxjs';
import {
  FsxFilingSubTabsService,
  IFilingSubTabsService,
} from '../../filing-sub-tabs-container/filing-sub-tabs.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../combined-filing-data.service';
import {
  FsxParticipantValidationService,
  IParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/participant-validation.service';
import {
  FsxPartyValidationService,
  IPartyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/party-validation.service';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../case-request-data.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from '../validation-errors.service';
import { ValidationGroupConstants } from '../validation-group-errors.service';

export const FsxValidatePartiesOrchestrationService =
  new InjectionToken<IValidatePartiesOrchestrationService>(
    'FsxValidatePartiesOrchestrationService'
  );

export interface ValidatePartiesParams {
  /**
   * An array of the participant GUID strings to include in the checks.
   */
  includedParticipantNames?: string[];

  /**
   * An array of the ParticipantCommonCategory to identify ParticipantSpecs to include in the checks.
   */
  includedParticipantCommonCatgeories?: ParticipantCommonCategory[];
}

export interface IValidatePartiesOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to validate parties.
   */
  validateParties$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  validateParties(params: ValidatePartiesParams): void;
}

@Injectable()
export class ValidatePartiesOrchestrationService
  implements IValidatePartiesOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private validateParties$$ = new Subject<ValidatePartiesParams>();

  /**
   * An observable stream that emits when the user navigates away from the "Parties" tab.
   * We use this to automatically trigger the party validation.
   */
  private partiesTabDeactivated$: Observable<ValidatePartiesParams> =
    this.filingSubTabsService.activeSubTabItem$.pipe(
      pairwise(),
      filter(([previous, current]: [FilingSubTabItem, FilingSubTabItem]) => {
        return (
          previous.name === FILING_SUB_TABS.PARTIES &&
          current.name !== FILING_SUB_TABS.PARTIES
        );
      }),
      map(() => {
        return {};
      })
    );

  /**
   * The pipeline of orchestration steps needed to validate parties.
   */
  validateParties$: Observable<CaseRequestViewModel> = merge(
    this.partiesTabDeactivated$,
    this.validateParties$$
    // TODO: Add anymore parties validation triggers here
  ).pipe(
    withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
    map(
      ([params, combinedFilingData]: [
        ValidatePartiesParams,
        CombinedFilingData
      ]) => {
        const { caseRequest, filingProfile, modeSpec } = combinedFilingData;

        // Validate Parties and Representation (includes checks against FilingProfile rules)
        const includedParticipantNames = params.includedParticipantNames || [];
        const includedParticipantCommonCatgeories =
          params.includedParticipantCommonCatgeories || [];
        const isPartiesAndRepresentationValid: boolean =
          this.partyValidationService.validateParties(
            caseRequest,
            modeSpec,
            filingProfile,
            includedParticipantNames,
            includedParticipantCommonCatgeories
          );

        // Validate Participants (includes checks against FilingProfile rules)
        const isParticipantsValid: boolean =
          this.participantValidationService.validateParticipants(
            caseRequest.participants,
            modeSpec.participant,
            caseRequest,
            filingProfile,
            caseRequest,
            includedParticipantNames
          );

        // Search for invalid parties and generate any validation errors here...
        const caseRequestParties = caseRequest.parties || [];
        caseRequestParties.forEach((party: CasePartyViewModel) => {
          let errorCode = `invalidParty${party.participantName}`;
          if (party.isValid === false) {
            const caption = party.caption || 'A party';
            this.validationErrorsService.addValidationError({
              errorCode: errorCode,
              errorMessage: `${caption} requires further attention`,
              group: ValidationGroupConstants.parties,
              metadata: {
                entityId: party.participantName,
              },
            });
          } else {
            this.validationErrorsService.removeValidationError(errorCode);
          }
        });

        // Search for invalid representation and generate any validation errors here...
        const representation: RequestParticipantRepresentationViewModel[] =
          caseRequestParties.flatMap((party: CasePartyViewModel) => {
            const partyRepresentation = party.representation || [];
            return partyRepresentation;
          });
        representation.forEach(
          (rep: RequestParticipantRepresentationViewModel) => {
            let errorCode = `invalidRepresentation${rep.participantName}`;
            if (rep.isValid === false) {
              const caption = rep.caption || 'A representing attorney';
              this.validationErrorsService.addValidationError({
                errorCode: errorCode,
                errorMessage: `${caption} requires further attention`,
                group: ValidationGroupConstants.parties,
                metadata: {
                  entityId: rep.participantName,
                },
              });
            } else {
              this.validationErrorsService.removeValidationError(errorCode);
            }
          }
        );

        // Consolidate 'Parties' validation into a single flag nn the caseRequest object)
        caseRequest.isPartiesValid =
          isPartiesAndRepresentationValid && isParticipantsValid;

        // Trigger a filing-editor instance-wide update of the validated caseRequest object
        this.caseRequestDataService.setCaseRequestData(caseRequest);

        return caseRequest;
      }
    )
  );

  public constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxParticipantValidationService)
    private readonly participantValidationService: IParticipantValidationService,
    @Inject(FsxPartyValidationService)
    private readonly partyValidationService: IPartyValidationService,
    @Inject(FsxFilingSubTabsService)
    private readonly filingSubTabsService: IFilingSubTabsService,
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   *
   * @param params The parameters needed to run this orchestration stream.
   */
  validateParties(params: ValidatePartiesParams): void {
    this.validateParties$$.next(params);
  }
}
