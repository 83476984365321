import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  RequestDocumentViewModel,
  CombinedFilingData,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import {
  Subject,
  Observable,
  switchMap,
  concatMap,
  withLatestFrom,
  tap,
} from 'rxjs';
import {
  FsxValidateDocumentsOrchestrationService,
  IValidateDocumentsOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-documents-orchestration.service';
import {
  FsxCombinedFilingDataService,
  ICombinedFilingDataService,
} from '../../filing-editor/services/combined-filing-data.service';

export const FsxUpdateDocumentOrchestrationService =
  new InjectionToken<IUpdateDocumentOrchestrationService>(
    'FsxUpdateDocumentOrchestrationService'
  );

export interface UpdateDocumentParams {
  /**
   * The original (unmodified) RequestDocument object to which the updates are to be applied.
   */
  requestDocument: RequestDocumentViewModel;

  /**
   * the subset of RequestDocument properties to be updated.
   */
  partialRequestDocument: Partial<RequestDocumentViewModel>;
}

export interface IUpdateDocumentOrchestrationService {
  /**
   * The pipeline of orchestration steps needed to apply updates to a RequestDocument object
   * in the caseRequest.documents array.
   */
  updateDocumentStream$: Observable<CaseRequestViewModel>;

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateDocument(params: UpdateDocumentParams): void;
}

@Injectable()
export class UpdateDocumentOrchestrationService
  implements IUpdateDocumentOrchestrationService
{
  /**
   * A subject to use as the trigger for the orchestration.
   */
  private updateDocumentParams$$ = new Subject<UpdateDocumentParams>();

  /**
   * The pipeline of orchestration steps needed to apply updates to a RequestDocument object
   * in the caseRequest.documents array.
   */
  updateDocumentStream$: Observable<CaseRequestViewModel> =
    this.updateDocumentParams$$.pipe(
      withLatestFrom(this.combinedFilingDataService.combinedFilingData$),
      concatMap(
        ([updateDocumentParams, combinedFilingData]: [
          UpdateDocumentParams,
          CombinedFilingData
        ]) => {
          const { filing, caseRequest } = combinedFilingData;
          const filingId: string = filing.id;
          const caseRequestBackup = { ...caseRequest } as CaseRequestViewModel;
          return this.caseRequestBuilderService
            .updateRequestDocument({
              caseRequest,
              combinedFilingData,
              ...updateDocumentParams,
            })
            .pipe(
              switchMap(() => {
                // Make the PUt request to apply the update.
                return this.caseRequestUpdateService
                  .optimisticPutOrRestore(
                    filingId,
                    caseRequest,
                    caseRequestBackup,
                    true
                  )
                  .pipe(
                    tap(() => {
                      const includedDocumentIds = [
                        updateDocumentParams.requestDocument.id!,
                      ];
                      this.validateDocumentsOrchestrationService.validateDocuments(
                        { includedDocumentIds }
                      );
                    })
                  );
              })
            );
        }
      )
    );

  /**
   * @param combinedFilingDataService Needed to retrieve the CaseRequest object, which we subsequently update.
   *
   * @param caseRequestBuilderService Needed to build the CaseRequest object with the RequestDocument updates.
   *
   * @param validateDocumentsOrchestrationService  Needed to initiate validation against RequestDocument objects.
   *
   * @param caseRequestUpdateService Needed to PUT the updated CaseRequest object back to the server.
   */
  constructor(
    @Inject(FsxCombinedFilingDataService)
    private readonly combinedFilingDataService: ICombinedFilingDataService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxValidateDocumentsOrchestrationService)
    private readonly validateDocumentsOrchestrationService: IValidateDocumentsOrchestrationService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService
  ) {}

  /**
   * A public method to allow the orchestration to be triggered.
   */
  updateDocument(params: UpdateDocumentParams): void {
    this.updateDocumentParams$$.next(params);
  }
}
