import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  IValidatable,
  EmailAddressSpec,
  EmailAddressViewModel,
  FilingProfile,
} from '../../../types';
import {
  FsxTextFieldValidationService,
  ITextFieldValidationService,
} from './text-field-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-errors.service';
import { ValidationGroupConstants } from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-group-errors.service';

export const FsxEmailAddressValidationService =
  new InjectionToken<IEmailAddressValidationService>(
    'FsxEmailAddressValidationService'
  );

export interface IEmailAddressValidationService {
  validateEmailAddresses(
    emailAddresses: EmailAddressViewModel[],
    spec: EmailAddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string
  ): boolean;
}

@Injectable()
export class EmailAddressValidationService
  implements IEmailAddressValidationService
{
  constructor(
    @Inject(FsxTextFieldValidationService)
    private readonly textFieldValidationService: ITextFieldValidationService,
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService
  ) {}

  public validateEmailAddresses(
    emailAddresses: EmailAddressViewModel[],
    spec: EmailAddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile,
    parentEntityId?: string
  ): boolean {
    let result = true;

    if (!spec) {
      return true;
    }

    if (!emailAddresses) {
      emailAddresses = [];
    }

    const minEmailsErrorCode = 'minEmailsErrorCode';
    if (emailAddresses.length < spec.minRequired) {
      scope.isValid = false;
      result = false;
      this.validationErrorsService.addValidationError({
        errorCode: minEmailsErrorCode,
        errorMessage: `Must provide at least ${spec.minRequired}`,
        group: ValidationGroupConstants.parties,
        metadata: {
          entityId: parentEntityId,
          parentEntityId: parentEntityId,
          formSectionName: 'Email',
        },
      });
    } else {
      this.validationErrorsService.removeValidationError(
        minEmailsErrorCode,
        parentEntityId
      );
    }

    const maxEmailsErrorCode = 'maxEmailsErrorCode';
    if (emailAddresses.length > spec.maxAllowed) {
      scope.isValid = false;
      result = false;
      this.validationErrorsService.addValidationError({
        errorCode: maxEmailsErrorCode,
        errorMessage: `Must not provide more than ${spec.maxAllowed}`,
        group: ValidationGroupConstants.parties,
        metadata: {
          entityId: parentEntityId,
          parentEntityId: parentEntityId,
          formSectionName: 'Email',
        },
      });
    } else {
      this.validationErrorsService.removeValidationError(
        maxEmailsErrorCode,
        parentEntityId
      );
    }

    for (let index = 0; index < emailAddresses.length; index++) {
      const emailAddress = emailAddresses[index];

      if (
        !this.validateEmailAddress(emailAddress, spec, scope, filingProfile)
      ) {
        return this.validationHelperService.markItemAsInvalid(scope);
      }
    }

    return result;
  }

  public validateEmailAddress(
    emailAddress: EmailAddressViewModel,
    spec: EmailAddressSpec | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    emailAddress.isValid = true;

    return this.textFieldValidationService.validateTextField(
      scope,
      filingProfile,
      spec.address,
      emailAddress.address
    );
  }
}
