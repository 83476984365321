import { SelectionFieldDefinition } from '@fsx/ui-components';
import {
  FilingProfile,
  CaseRequestViewModel,
  CasePartyViewModel,
  ParticipantSpec,
  IValidatable,
  FilingModeSpec,
  FilingMode,
  CaseParty,
  ParticipantCommonCategory,
} from '../../../types';
import {
  FsxAdditionalFieldsValidationService,
  IAdditionalFieldsValidationService,
} from './additional-fields-validation.service';
import {
  FsxRepresentationValidationService,
  IRepresentationValidationService,
} from './representation-validation.service';
import {
  FsxValidationHelperService,
  IValidationHelperService,
} from './validation-helper.service';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  FsxValidationErrorsService,
  IValidationErrorsService,
} from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-errors.service';
import { ValidationGroupConstants } from 'projects/apps/fsx-ui/src/app/filing-editor/services/validation-group-errors.service';

export const FsxPartyValidationService =
  new InjectionToken<IPartyValidationService>('FsxPartyValidationService');

export interface IPartyValidationService {
  validateParties(
    caseRequest: CaseRequestViewModel, // Needed to pass the parties that we want to validate
    modeSpec: FilingModeSpec, // Needed for the participantSpecs that we want to validate against
    filingProfile: FilingProfile, // Only needed down stream by other validation services (Do they really need it?)
    includedParticipantNames: string[], // An array of GUIDS to determine which parties to validate.
    includedCategories: ParticipantCommonCategory[]
  ): boolean;

  validateParty(
    party: CasePartyViewModel,
    spec: ParticipantSpec,
    caseRequest: CaseRequestViewModel,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec
  ): boolean;
}

@Injectable()
export class PartyValidationService implements IPartyValidationService {
  constructor(
    @Inject(FsxValidationHelperService)
    private readonly validationHelperService: IValidationHelperService,
    @Inject(FsxRepresentationValidationService)
    private readonly representationValidationService: IRepresentationValidationService,
    @Inject(FsxAdditionalFieldsValidationService)
    private readonly additionalFieldsValidationService: IAdditionalFieldsValidationService,
    @Inject(FsxValidationErrorsService)
    private readonly validationErrorsService: IValidationErrorsService
  ) {}

  public validateParties(
    caseRequest: CaseRequestViewModel, // Needed to pass the parties that we want to validate
    modeSpec: FilingModeSpec, // Needed for the participantSpecs that we want to validate against
    filingProfile: FilingProfile, // Only needed down stream by other validation services (Do they really need it?)
    includedParticipantNames: string[], // An array of GUIDS to determine which parties to validate.
    includedCategories: ParticipantCommonCategory[]
  ): boolean {
    let isPartiesValid = true;

    // Check against profile rules first...

    const participantSpecs: ParticipantSpec[] = modeSpec.participant || [];

    const caseRequestParties: CasePartyViewModel[] = caseRequest.parties || [];

    if (participantSpecs.length > 0) {
      // Use the includedCategories array to determine which ParticopantSpecs we can validate against.
      const filteredPartiicpantSpecs = participantSpecs.filter((x) => {
        const isIncludedCatgeory =
          includedCategories.indexOf(x.participantCategory.commonCategory) > -1;
        return isIncludedCatgeory;
      });

      if (
        !this.validatePartiesAgainstParticipantSpecs(
          caseRequestParties, // We must pass all parties (no filter) when validating against participantSpec min/max rules
          filteredPartiicpantSpecs // The filtered participantSpecs that we want to validate against.
        )
      ) {
        caseRequest.isValid = false;
        isPartiesValid = false;
      }
    }

    if (
      !this.validatePartiesAgainstEachother(caseRequestParties, caseRequest)
    ) {
      caseRequest.isValid = false;
      isPartiesValid = false;
    }

    // Use the includedParticipantNames array of GUIDs to determine which parties to validate.
    const partiesToValidate: CasePartyViewModel[] =
      includedParticipantNames.length > 0
        ? caseRequestParties.filter((party: CasePartyViewModel) => {
            const isPartyToValidate: boolean =
              includedParticipantNames.indexOf(party.participantName) > -1;
            return isPartyToValidate;
          })
        : caseRequestParties;

    // Iterate over the parties validating each one in turn.
    for (let party of partiesToValidate) {
      // Important. We do not want to return early inside of this for loop.
      // We want to iterate over all parties and flag all invalid parties (not just the first one it finds)

      // We start with the assumption that everything is valid until proven otherwise.
      party.isValid = true;
      party.isRepresentationValid = true;

      // No efmKey means that this is existing party in a SubF.
      if (!!party.efmKey) {
        // In a SubF, existing parties should not be validated
        continue;
      }

      // If there is no "Party Type" selected we can't lookup the spec to validate it against.
      if (!party.participantCategory?.name) {
        isPartiesValid = false; // Just set the return value (do not return)
        this.validationHelperService.markItemAsInvalid(party, caseRequest);
      }

      // Try to lookup the spec in the array of ParticipantSpec objects.
      const spec = participantSpecs.find(
        (s) => party.participantCategory?.name === s.participantCategory.name
      );
      if (!spec) {
        // If there is no spec we can't validate it so it's flagged as invalid.
        isPartiesValid = false; // Just set the return value (do not return)
        this.validationHelperService.markItemAsInvalid(party, caseRequest);
      } else {
        // If we have a spec then we validate the party against it.
        if (
          !this.validateParty(party, spec, caseRequest, filingProfile, modeSpec)
        ) {
          isPartiesValid = false; // Just set the return value (do not return)
          this.validationHelperService.markItemAsInvalid(party, caseRequest);
        }
      }
    }

    // Will be false if there is even just one invalid party.
    return isPartiesValid;
  }

  private validatePartiesAgainstEachother(
    parties: CasePartyViewModel[],
    caseRequest: CaseRequestViewModel
  ): boolean {
    const seenParticipantNames: string[] = [];
    return parties.reduce(
      (accIsValid: boolean, curParty: CasePartyViewModel) => {
        if (seenParticipantNames.includes(curParty.participantName)) {
          accIsValid = false;
          this.validationHelperService.markItemAsInvalid(curParty, caseRequest);
        }
        seenParticipantNames.push(curParty.participantName);
        return accIsValid;
      },
      true
    );
  }

  private validatePartiesAgainstParticipantSpecs(
    parties: CaseParty[],
    participantSpecs: ParticipantSpec[]
  ): boolean {
    return participantSpecs.reduce(
      (acc: boolean, spec: ParticipantSpec, specIndex: number) => {
        const partiesForSpec: CasePartyViewModel[] = parties.filter(
          (party: CasePartyViewModel) => {
            return (
              spec.participantCategory.name === party.participantCategory?.name
            );
          }
        );

        const pSpecMinErrorCode: string = 'pSpecMin' + specIndex;
        const partyOrParties: string =
          spec.minRequired === 1 ? 'Party' : 'Parties';
        if (partiesForSpec.length < spec.minRequired) {
          acc = false;
          this.validationErrorsService.addValidationError({
            errorCode: pSpecMinErrorCode,
            errorMessage: `Must provide at least ${spec.minRequired} ${partyOrParties} of type ${spec.participantCategory.caption}`,
            group: ValidationGroupConstants.parties,
          });
        } else {
          this.validationErrorsService.removeValidationError(pSpecMinErrorCode);
        }

        const pSpecMaxErrorCode: string = 'pSpecMax' + specIndex;
        if (partiesForSpec.length > spec.maxAllowed) {
          acc = false;
          this.validationErrorsService.addValidationError({
            errorCode: pSpecMaxErrorCode,
            errorMessage: `Must not provide more than ${spec.maxAllowed} ${partyOrParties} of type ${spec.participantCategory.caption}`,
            group: ValidationGroupConstants.parties,
          });
        } else {
          this.validationErrorsService.removeValidationError(pSpecMaxErrorCode);
        }

        return acc;
      },
      true
    );
  }

  public validateParty(
    party: CasePartyViewModel,
    spec: ParticipantSpec,
    caseRequest: CaseRequestViewModel,
    filingProfile: FilingProfile,
    modeSpec: FilingModeSpec
  ): boolean {
    // party.isValid = true;

    if (!!party.efmKey) {
      // in a SubF, existing parties should not be validated
      return true;
    }

    if (
      !this.representationValidationService.validateRepresentations(
        party,
        spec.representation,
        caseRequest,
        filingProfile,
        modeSpec,
        caseRequest,
        party.participantName
      )
    ) {
      party.isRepresentationValid = false;
      return this.validationHelperService.markItemAsInvalid(party, caseRequest);
    }

    if (
      !this.validateSubCategories(
        party.participantSubCategoryNames,
        spec.subCategory,
        party,
        filingProfile
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(party, caseRequest);
    }

    if (
      !this.additionalFieldsValidationService.validateAdditionalFields(
        party.additionalFieldValues,
        spec.additionalFields,
        party.caseId,
        caseRequest,
        filingProfile,
        caseRequest
      )
    ) {
      return this.validationHelperService.markItemAsInvalid(party, caseRequest);
    }

    return true;
  }

  private validateSubCategories(
    subCategoryNames: string[] | null | undefined,
    spec: SelectionFieldDefinition | null | undefined,
    scope: IValidatable,
    filingProfile: FilingProfile
  ): boolean {
    if (!spec) {
      return true;
    }

    const countOfNames = subCategoryNames?.length ?? 0;

    if (countOfNames < spec.minRequired) {
      return this.validationHelperService.markItemAsInvalid(scope, null);
    }

    if (countOfNames > spec.maxAllowed) {
      return this.validationHelperService.markItemAsInvalid(scope, null);
    }

    for (let subCategoryName in subCategoryNames) {
      this.validationHelperService.validateListReference(
        spec.listReference,
        subCategoryName,
        scope,
        filingProfile
      );
    }

    return true;
  }
}
