import {
  Component,
  EventEmitter,
  Inject,
  Injector,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AdditionalFieldValue,
  CasePartyViewModel,
  CombinedFilingData,
  ContactSummaryViewModel,
  ContactType,
  ContactViewModel,
  DocumentInfo,
  FieldCategory,
  FilingMode,
  FilingProfile,
  FsxCaseRequestUpdateService,
  FsxContactApiService,
  FsxFilingApiService,
  ICaseRequestUpdateService,
  IContactApiService,
  IFilingApiService,
  ParticipantCategory,
  ParticipantCommonCategory,
  ParticipantSpec,
  RequestParticipantRepresentationViewModel,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { DropdownOption, FormControlWithoutModel } from '@fsx/ui-components';
import {
  FsxAdditionalFieldsComponent,
  FsxBasicSingleSelectionComponent,
} from '@fsx/ui-components';
import { ContactsSearchTypeEnum } from '../../contacts/contacts.model';
import {
  RemoveRepresentationEventParams,
  UpdateRepresentationEventParams,
} from '../representation-grid-item/representation-grid-item.component';
import {
  AttorneySelectedEventParams,
  ContactSummariesSelectedEventParams,
  ParticipantSelectedEventParams,
} from '../representation-grid/representation-grid.component';
import { PartiesGridRow } from './parties-grid.model';
import { Subject, take, tap } from 'rxjs';
import { FsxReferenceResolver } from '../../shared/resolvers/list-reference.resolver';
import {
  FsxParticipantValidationService,
  IParticipantValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/participant-validation.service';
import {
  FsxPartyValidationService,
  IPartyValidationService,
} from 'projects/libs/shared/src/lib/services/core/validation/party-validation.service';
import {
  FsxAddDefaultParticipantOrchestrationService,
  IAddDefaultParticipantOrchestrationService,
} from '../orchestration-services/add-default-participant-orchestration.service';
import {
  FsxRemoveParticipantOrchestrationService,
  IRemoveParticipantOrchestrationService,
} from '../orchestration-services/remove-participant-orchestration.service';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import {
  FsxOpenContactsListOrchestrationService,
  IOpenContactsListOrchestrationService,
} from '../../shared/services/open-contact-list-orchestration.service';

/**
 * An interface defining the config object for the parties grids.
 */
export interface PartiesGridConfig {
  /**
   * The ParticipantCommonCatgeory relevant to the parties grid instance.
   * e.g. InitiatingParty / AdditionalParty / Attorney
   */
  participantCommonCategory?: ParticipantCommonCategory;

  /**
   * An array of row-level view models to iterate over in the template.
   */
  partiesGridRows: PartiesGridRow[];

  /**
   * The array of ParticipantSpec objects which we need to derive the "Party Type" dropdown
   * options on each parties grid row.
   */
  participantSpecs: ParticipantSpec[];

  /**
   * The array of PartiicipantSpec objects which we need to derive the "Role" dropdown options
   * on each representation grid row.
   */
  attorneySpecs: ParticipantSpec[];
}

export interface PartiesGridOptions {
  title: string;
  descriptor: string;
  expanded: boolean;
}

export interface ContactSelectedEventParams {
  contact: ContactViewModel;
  participantName: string;
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
}

export interface AddParticipantEventParams {
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
}

export interface EditParticipantEventParams {
  participant: RequestParticipantViewModel;
  participantCategory: ParticipantCategory;
}

export interface EditRepresentationEventParams {
  participant: RequestParticipantViewModel;
  representation: RequestParticipantRepresentationViewModel;
  participantCategory: ParticipantCategory | undefined | null;
}

export interface UpdateParticipantEventParams {
  participantCategory: ParticipantCategory;
  caseParty: CasePartyViewModel;
  partyIndex: number;
}

export interface ParticipantViewMinMaxValues {
  isReadOnly: boolean;
  minRequired: number;
  maxAllowed: number;
}

@Component({
  selector: 'fsx-parties-grid',
  templateUrl: './parties-grid.component.html',
  styleUrls: ['./parties-grid.component.scss'],
})
export class PartiesGridComponent implements OnChanges, OnInit, OnDestroy {
  /**
   * The config object for the parties grid.
   */
  @Input() partiesGridConfig!: PartiesGridConfig;

  @Input() partiesGridOptions!: PartiesGridOptions;
  @Input() combinedFilingData!: CombinedFilingData;
  @Input() combinedGridRows!: PartiesGridRow[] | null;
  @Input() validationFilteredClass!: string;
  @Input() isInitiating!: boolean;

  @Output() addParticipantEvent = new EventEmitter<AddParticipantEventParams>();
  @Output() contactSelectedEvent =
    new EventEmitter<ContactSelectedEventParams>();
  @Output() removeParticipantEvent = new EventEmitter<CasePartyViewModel>();
  @Output() editParticipantEvent =
    new EventEmitter<EditParticipantEventParams>();
  @Output() attorneySelectedEvent =
    new EventEmitter<AttorneySelectedEventParams>();
  @Output() contactSummariesSelectedEvent =
    new EventEmitter<ContactSummariesSelectedEventParams>();
  @Output() removeRepresentationEvent =
    new EventEmitter<RemoveRepresentationEventParams>();
  @Output() updateRepresentationEvent =
    new EventEmitter<UpdateRepresentationEventParams>();
  @Output() editRepresentationEvent =
    new EventEmitter<EditRepresentationEventParams>();
  @Output() updateParticipantEvent =
    new EventEmitter<UpdateParticipantEventParams>();
  @Output() addRepresentationEvent =
    new EventEmitter<AttorneySelectedEventParams>();
  @Output() validationFilteredEvent = new EventEmitter<string>();

  @ViewChildren('partyTypeField')
  partyTypeFields!: QueryList<FsxBasicSingleSelectionComponent>;
  @ViewChildren('additionalFields')
  additionalFields!: QueryList<FsxAdditionalFieldsComponent>;

  public contactsSearchType = ContactsSearchTypeEnum;
  public attorneysListFormControl!: FormControlWithoutModel;

  public attorneysList: DropdownOption<void>[] = [];
  public participantCategory!: ParticipantCategory;
  public showHoverButtons: boolean = false;
  public hoverRowIndex: number = 0;
  public expandedRowIndex: number | null = null;
  public isMaxAllowed: boolean = false;

  public fieldType = FieldCategory;
  public attorneyListDefault!: string;
  public filingProfile!: FilingProfile | undefined;
  public participantListCaption!: string;
  public existingPartiesContactIds!: string[] | undefined;
  public additionalFieldValues: AdditionalFieldValue[][] = [[]];
  public resolver!: FsxReferenceResolver;
  public partiesMap = new Map<string, ParticipantViewMinMaxValues>();
  public subCategoriesInitialValues: string[] = [];
  public subcategoriesSelected: string[] = [];

  errorCount!: number;
  partyFileUploadDocumentInfos!: DocumentInfo[];

  private destroy$: Subject<void> = new Subject<void>();

  protected readonly FilingMode = FilingMode;

  constructor(
    @Inject(FsxContactApiService)
    private readonly contactApiService: IContactApiService,
    @Inject(FsxFilingApiService)
    private readonly filingApiService: IFilingApiService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxOpenContactsListOrchestrationService)
    private readonly openContactsListOrchestrationService: IOpenContactsListOrchestrationService,
    @Inject(FsxParticipantValidationService)
    private readonly participantValidationService: IParticipantValidationService,
    @Inject(FsxPartyValidationService)
    private readonly partyValidationService: IPartyValidationService,
    @Inject(FsxRemoveParticipantOrchestrationService)
    private readonly removeParticipantOrchestrationService: IRemoveParticipantOrchestrationService,
    @Inject(FsxAddDefaultParticipantOrchestrationService)
    private readonly addDefaultParticipantOrchestrationService: IAddDefaultParticipantOrchestrationService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService,
    private readonly injector: Injector
  ) {}

  ngOnChanges(): void {
    this.filingProfile = this.combinedFilingData?.filingProfile;
    this.existingPartiesContactIds = this.combinedGridRows?.map(
      (row) => row.participant.linkedContact?.id as string
    );
    this.setParticipantsMap();
    this.validate();

    if (this.combinedFilingData) {
      const documentInfos = this.combinedFilingData.documentInfos || [];
      const caseRequestParties =
        this.combinedFilingData.caseRequest.parties || [];
      if (caseRequestParties.length > 0) {
        const partyAdditionalFieldValues =
          caseRequestParties[0].additionalFieldValues || [];
        const partyFileValues: string[] = partyAdditionalFieldValues.flatMap(
          (addlFieldValue: AdditionalFieldValue) => {
            return addlFieldValue.fileValues || [];
          }
        );

        // Should refresh the documentInfos for this party.
        // Does not work, no file size displayed after file uploads.
        // This probably won't work until polling is moved, or extended to work across all tabs.
        this.partyFileUploadDocumentInfos = documentInfos.filter(
          (docInfo: DocumentInfo) => {
            return partyFileValues.includes(docInfo.id);
          }
        );
      }
    }

    this.checkvalidateLastTouchedParty();
  }

  /**
   * A private method to validate the previously expanded (last touched) party.
   */
  private checkvalidateLastTouchedParty() {
    // Get the current expanded row.
    const expandedRow: PartiesGridRow | undefined =
      this.partiesGridConfig.partiesGridRows.find((partyGridRow) => {
        const isExpanded = partyGridRow.rowIndex === this.expandedRowIndex;
        return isExpanded;
      });

    if (expandedRow) {
      // Get the participantname of the currently expanded row.
      const expandedParticipantName: string = expandedRow.participant.name;

      // If a previousParticipantName was set in the previous iteration then we consider validating it.
      if (this.previousParticipantName) {
        // If the previousParticipantName is not the same as the expandedPrticipantName then
        // the user has navigated away from it; meaning we need to validate it.
        if (this.previousParticipantName !== expandedParticipantName) {
          // Validate the party that was naviagted away from.
          this.validatePartiesOrchestrationService.validateParties({
            includedParticipantNames: [this.previousParticipantName],
          });
        }
      }

      // After the conditional validation (above) we set the previousParticipantName to the
      // currentParticipantName ready for the next iteration.
      this.previousParticipantName = expandedParticipantName;
    }
  }

  ngOnInit(): void {
    this.setParticipantsMap();
    if (this.filingProfile) {
      this.resolver = new FsxReferenceResolver(this.filingProfile, {
        filingApi: this.filingApiService,
        filingId: this.combinedFilingData?.filing.id,
        cfd: this.combinedFilingData ?? undefined,
        caseRequestUpdateService: this.caseRequestUpdateService,
      });
    }
    this.setParticipantListCaption();
  }

  setParticipantListCaption() {
    // Derive the dropdown options from the array of ParticipantSpec objects.
    const partyTypeDropdownOptions =
      this.partiesGridConfig.participantSpecs.map((pSpec: ParticipantSpec) => {
        return { ...pSpec.participantCategory, selected: false };
      });

    // set caption to display available party type when only 1 option
    if (partyTypeDropdownOptions.length === 1) {
      this.participantListCaption = partyTypeDropdownOptions[0].caption;
    } else {
      this.participantListCaption = '(no party selected)';
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  rowIndex(index: number): number {
    return index;
  }

  onToggleExpandTitleRow(event: Event) {
    event.stopPropagation();
    this.partiesGridOptions.expanded = !this.partiesGridOptions.expanded;
  }

  editParticipantEventHandler(participant: RequestParticipantViewModel) {
    this.editParticipantEvent.emit({
      participant,
      participantCategory: this.participantCategory,
    });
  }

  editRepresentationEventHandler(params: EditRepresentationEventParams) {
    this.editRepresentationEvent.emit(params);
  }

  onAddParticipantClicked() {
    this.optimisticallySetExpandedRowIndex();
    this.addDefaultParticipantOrchestrationService.addDefaultParticipant({
      participantCommonCategory:
        this.partiesGridConfig.participantCommonCategory!,
    });
  }

  private optimisticallySetExpandedRowIndex() {
    const newRowIndex = this.partiesGridConfig.partiesGridRows
      ? this.partiesGridConfig.partiesGridRows.length
      : 0;
    this.expandedRowIndex = newRowIndex;
  }

  private previousParticipantName: string | null = null;

  /**
   * A handler function for when the user clicks on the PartiesGridRow.
   *
   * @param event The click event needed to stop propgating the event up the DOM.
   * @param index The index of the row that was clicked.
   * @param row The PartiesGridRow that was clicked.
   */
  onToggleExpandDetailRow(event: Event, row: PartiesGridRow) {
    event.stopPropagation();
    this.expandedRowIndex =
      this.expandedRowIndex !== row.rowIndex ? row.rowIndex : null;
    this.validateFormFields(row.rowIndex);

    /**
     * When expanding/collapsing a row on the parties grid we have 'touched' the
     * participant record an should therefore validate it.
     *
     * Here we prevent other parties from inadvertently being flagged as invalid
     * by passing in the participantName of the party being toggled into the
     * inclusions array.
     */
    this.validatePartiesOrchestrationService.validateParties({
      includedParticipantNames: [row.participant.name],
    });

    this.previousParticipantName = row.party.participantName;
  }

  /**
   * A method to trigger validation of the participant (RequestParticipant) and party (CaseParty)
   * on the caseRequest.
   *
   * @param row The PartiesGridRow containing the participant and party to which the validation should be run against.
   * z
   */
  validateParticipant(row: PartiesGridRow): void {
    this.participantValidationService.validateParticipant(
      row.participant,
      [row.participantSpec],
      this.combinedFilingData.caseRequest, // Passed in once for the scope (not always guarenteed to be caseRequest, but is in this instance)
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.caseRequest // Passed in again for the caseRequest proper, used in dependent services
    );

    this.partyValidationService.validateParty(
      row.party,
      row.participantSpec,
      this.combinedFilingData.caseRequest,
      this.combinedFilingData.filingProfile,
      this.combinedFilingData.modeSpec
    );
  }

  private validateFormFields(index: number): void {
    setTimeout(() => {
      if (this.partyTypeFields) {
        this.partyTypeFields
          .toArray()
          .filter((fld) => fld.id === index)
          .forEach((fld) => fld.validate());
      }

      if (this.additionalFields) {
        this.additionalFields
          .toArray()
          .filter((fld) => fld.id === index)
          .forEach((fld) => fld.validate());
      }
    });
  }

  onDataRowHoverStateChanged(show: boolean, rowIndex: number) {
    this.showHoverButtons = show;
    this.hoverRowIndex = rowIndex;
  }

  onParticipantCheckboxClicked(event: Event) {
    event.stopPropagation();
  }

  public setValues(values: string[], partyIndex: number) {
    this.subcategoriesSelected = values;
    if (
      this.combinedFilingData?.caseRequest &&
      this.combinedFilingData.caseRequest.parties
    ) {
      this.combinedFilingData.caseRequest.parties[
        partyIndex
      ].participantSubCategoryNames = values;
      this.resolver.updateCaseRequestPut(
        this.combinedFilingData.caseRequest,
        this.combinedFilingData.filing.id
      );
    }
  }

  attorneySelectedEventHandler(params: AttorneySelectedEventParams): void {
    this.attorneySelectedEvent.emit(params);
  }

  contactSummariesSelectedEventHandler(
    params: ContactSummariesSelectedEventParams
  ) {
    this.contactSummariesSelectedEvent.emit(params);
  }

  isGhostRow(row: PartiesGridRow): boolean {
    return (
      !!row.participant.contactType &&
      row.participant.contactType === ContactType.Unknown
    );
  }

  // TODO: Handle this closer to source...
  // Represnetation-grid.component.html:80
  // addRepresentationBtnClicked()
  addRepresentationEventHandler(_params: ParticipantSelectedEventParams): void {
    this.openContactsListOrchestrationService.openContactsList({
      searchType: ContactsSearchTypeEnum.attorneys,
      commonCategory: ParticipantCommonCategory.Attorney,
      addCallback: (contactSummaries: ContactSummaryViewModel[]) => {
        contactSummaries.forEach((contactSummary) => {
          if (contactSummary.id) {
            this.contactApiService
              .getContact(contactSummary.id)
              .pipe(
                tap((_contact: ContactViewModel) => {
                  // let attorneySelectedEventParams: AttorneySelectedEventParams = {
                  //   contact: contact,
                  //   participantSpec: params.participantSpec,
                  //   partyToAddTo: params.partyToAddTo
                  // }
                  // this.attorneySelectedEvent.emit(attorneySelectedEventParams);
                }),
                take(1)
              )
              .subscribe();
          }
        });
      },
    });
  }

  private setParticipantsMap(): void {
    this.partiesGridConfig.partiesGridRows?.forEach((partyGridRow) => {
      const spec = this.partiesGridConfig.participantSpecs.find(
        (spec) =>
          spec.participantCategory.name ===
          partyGridRow.party.participantCategory?.name
      );
      if (!!spec) {
        const partyVals: ParticipantViewMinMaxValues = {
          isReadOnly: !!partyGridRow.party.efmKey,
          minRequired: spec?.minRequired as number,
          maxAllowed: spec?.maxAllowed as number,
        };
        this.partiesMap.set(partyGridRow.participant.name, partyVals);

        partyGridRow.representationGridRows.forEach((representationGridRow) => {
          const repVals: ParticipantViewMinMaxValues = {
            isReadOnly: !!representationGridRow.representation.efmKey,
            minRequired: spec.representation?.minRequired as number,
            maxAllowed: spec.representation?.maxAllowed as number,
          };
          this.partiesMap.set(
            representationGridRow.representation.participantName,
            repVals
          );
        });
      }
    });
  }

  private validate(): void {
    if (this.partiesGridConfig.partiesGridRows) {
      this.errorCount = this.partiesGridConfig.partiesGridRows.reduce(
        (acc, curr) =>
          curr.participant.isValid === false ||
          curr.party.isValid == false ||
          curr.party.isRepresentationValid === false
            ? acc + 1
            : acc,
        0
      );
    }
  }

  validationFilterChanged(filteredClass: string) {
    this.validationFilteredClass = filteredClass;
    this.validationFilteredEvent.emit(filteredClass);
  }

  /**
   * The handler function for when the user clicks the trash can icon
   * on a participant row in the grid.
   *
   * @param event The remove button's click event.
   * @param row The row containing the case party to remove.
   */
  onRemoveParticipantClicked(event: Event, row: PartiesGridRow) {
    event.stopPropagation();
    this.removeParticipantOrchestrationService.removeParticipant({
      partyToRemove: row.party,
      participantCommonCategory:
        this.partiesGridConfig.participantCommonCategory,
    });
  }
}
