import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpResponse,
} from '@angular/common/http';
import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  Observable,
  switchMap,
  shareReplay,
  map,
  catchError,
  EMPTY,
  tap,
  take,
  of,
} from 'rxjs';
import { ApiV2Service } from '../core/api/api.v2.service';
import { Operation as JsonPatchOperation } from 'fast-json-patch';
import {
  EnvConfig,
  NewFiling,
  Filing,
  CaseRequestViewModel,
  FilingMetrics,
  OLD_ROUTE,
  FsxApiSearchQuery,
  FilingProfile,
  DocumentInfo,
  CaseRequest,
  SearchResultItemGridResult,
  Operator,
  RequestCaseViewModel,
  RequestDocumentViewModel,
  RequestDocumentCaseViewModel,
  FilingFees,
  ValidationResultViewModel,
  CaseRequestSnapshot,
  ProblemDetails,
  FilingUpdate,
  DocumentReviewRequestEvent,
  RequestParticipantViewModel,
  FilingGridResult,
  CasePartySummaryGridResult,
  RequestParticipantSummaryGridResult,
  CasePartyViewModel,
} from '@fsx/fsx-shared';
import { AppConfig, ENV_CONFIG } from '@fsx/fsx-shared';

export const FsxFilingApiService = new InjectionToken<IFilingApiService>(
  'FsxFilingApiService'
);

export interface IFilingApiService {
  createFiling(filing: NewFiling): Observable<Filing>;

  deleteFiling(filing: Filing): Observable<void>;

  putCaseRequest(
    requestCase: RequestCaseViewModel,
    filingId: string
  ): Observable<CaseRequestViewModel>;

  putCaseRequest2(
    filingId: string,
    caseRequest: CaseRequestViewModel
  ): Observable<CaseRequestViewModel>;

  patchCaseRequest(
    filingId: string,
    jsonPatchOperations: JsonPatchOperation[]
  ): Observable<CaseRequestViewModel | null>;

  getTransactionCategoryMetrics(): Observable<FilingMetrics>;

  getDraftFilingEntitiyGridResult(
    queryData: FsxApiSearchQuery
  ): Observable<FilingGridResult>;

  getFilingProfile(filing: Filing): Observable<FilingProfile>;

  getFiling(filingId: string): Observable<Filing>;

  getCaseRequest(filingId: string): Observable<CaseRequestViewModel>;

  validateRequest(filingId: string): Observable<any>;

  getDocumentInfos(filingId: string): Observable<DocumentInfo[]>;

  deleteDocumentInfo(
    filingId: string,
    documentId: string
  ): Observable<DocumentInfo[]>;

  submitFiling(filingId: string): Observable<ProblemDetails>;

  search(
    filingId: string,
    searchValue: string,
    searchName: string
  ): Observable<SearchResultItemGridResult>;

  getFees(filingId: string): Observable<FilingFees>;

  getDocumentRendering(
    filingId: string,
    documentId: string,
    renderingName: string
  ): Observable<ArrayBuffer>;

  bookmark(filing: Filing): Observable<Object>;

  unbookmark(filing: Filing): Observable<Object>;

  updateFiling(
    filingId: string,
    filingUpdate: FilingUpdate
  ): Observable<Filing>;

  getDocumentHistory(
    filingId: string,
    documentId: string,
    reviewRequestName: string
  ): Observable<DocumentReviewRequestEvent[]>;

  getDocumentInfo(
    filingId: string,
    documentId: string
  ): Observable<DocumentInfo>;

  getParties(
    filingId: string,
    efmKey: string
  ): Observable<CasePartySummaryGridResult>;

  getParty(
    filingId: string,
    efmKey: string,
    partyKey: string
  ): Observable<CasePartyViewModel | null>;

  getParticipant(
    filingId: string,
    efmKey: string,
    participantName: string
  ): Observable<RequestParticipantViewModel | null>;

  getParticipants(
    filingId: string,
    efmKey: string
  ): Observable<RequestParticipantSummaryGridResult>;
}

@Injectable()
export class FilingApiService implements IFilingApiService {
  // Storing the etag (string) using a key of filingId (string)
  // to ensure PATCH continues to work across multiple filings
  private etagsMap = new Map<string, string>();

  public constructor(
    @Inject(ENV_CONFIG) public envConfig: Observable<EnvConfig>,
    private readonly appConfig: AppConfig,
    private readonly api: ApiV2Service,
    private readonly http: HttpClient
  ) {}

  getParticipant(
    filingId: string,
    efmKey: string,
    participantName: string
  ): Observable<RequestParticipantViewModel | null> {
    const url = this.appConfig.fsxApi.filing.participant(
      filingId,
      efmKey,
      participantName
    );
    return this.http.get<RequestParticipantViewModel>(url).pipe(
      catchError((err) => {
        console.error(
          `Error attempting to retrieve CaseParty object (participantName: ${participantName})`,
          err
        );
        return of(null);
      })
    );
  }

  getParty(
    filingId: string,
    efmKey: string,
    partyKey: string
  ): Observable<CasePartyViewModel | null> {
    const url = this.appConfig.fsxApi.filing.party(filingId, efmKey, partyKey);
    return this.http.get<CasePartyViewModel>(url).pipe(
      catchError((err) => {
        console.error(
          `Error attempting to retrieve CaseParty object (partyKey: ${partyKey})`,
          err
        );
        return of(null);
      })
    );
  }

  getParticipants(
    filingId: string,
    efmKey: string
  ): Observable<RequestParticipantSummaryGridResult> {
    const url = this.appConfig.fsxApi.filing.participants(filingId, efmKey);
    return this.http.post<RequestParticipantSummaryGridResult>(url, {});
  }

  getParties(
    filingId: string,
    efmKey: string
  ): Observable<CasePartySummaryGridResult> {
    const url = this.appConfig.fsxApi.filing.parties(filingId, efmKey);
    return this.http.post<CasePartySummaryGridResult>(url, {});
  }

  createFiling(filing: NewFiling): Observable<Filing> {
    const url = this.appConfig.fsxApi.filing.filings();
    return this.api.post<NewFiling, Filing>(url, filing);
  }

  deleteFiling(filing: Filing): Observable<void> {
    const url = this.appConfig.fsxApi.filing.deleteFiling(filing.id);
    return this.http.delete<void>(url).pipe(
      catchError(() => {
        console.error(`Attempt to delete filing with id ${filing.id} failed`);
        return EMPTY;
      })
    );
  }

  putCaseRequest(
    requestCase: RequestCaseViewModel,
    filingId: string
  ): Observable<CaseRequestViewModel> {
    const caseRequest: CaseRequest = {
      participants: [],
      parties: [],
      documents: [],
      noteToClerk: '',
      cases: [requestCase],
    };

    // Create a deep copy of the caseRequest before sanatizing to prevent inadvertently mutating the original object
    // (which otherwise interferes with orchestration, where we do safely mutate the object with intent)
    const caseRequestCopy = JSON.parse(JSON.stringify(caseRequest));
    this.sanitizeCaseRequest(caseRequestCopy);

    return this.envConfig.pipe(
      // switchMap((envConfig: EnvConfig) => {
      //   return this.get
      // }),
      switchMap((envConfig: EnvConfig) => {
        const headers = new HttpHeaders();

        const url = `${envConfig.Endpoints.filing.caseRequest(filingId)}`;

        return this.http
          .put<CaseRequestViewModel>(url, caseRequestCopy, {
            headers: headers,
            observe: 'response',
          })
          .pipe(
            tap((response: HttpResponse<Object>) => {
              const etag = response.headers.get('etag') as string;
              this.etagsMap.set(filingId, etag);
            }),
            map((response: HttpResponse<CaseRequest>) => {
              return response.body as CaseRequestViewModel;
            })
          );
      }),
      shareReplay()
    );
  }

  putCaseRequest2(
    filingId: string,
    caseRequest: CaseRequestViewModel
  ): Observable<CaseRequestViewModel> {
    // Create a deep copy of the caseRequest before sanatizing to prevent inadvertently mutating the original object
    // (which otherwise interferes with orchestration, where we do safely mutate the object with intent)
    const caseRequestCopy = JSON.parse(JSON.stringify(caseRequest));
    this.sanitizeCaseRequest(caseRequestCopy);

    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        let headers = new HttpHeaders();

        const url = `${envConfig.Endpoints.filing.caseRequest(filingId)}`;

        return this.http
          .put<CaseRequestViewModel>(url, caseRequestCopy, {
            headers: headers,
            observe: 'response',
          })
          .pipe(
            tap((response: HttpResponse<Object>) => {
              const etag = response.headers.get('etag') as string;
              this.etagsMap.set(filingId, etag);
            }),
            map((response: HttpResponse<CaseRequest>) => {
              return response.body as CaseRequestViewModel;
            })
          );
      }),
      shareReplay()
    );
  }

  patchCaseRequest(
    filingId: string,
    jsonPatchOperations: JsonPatchOperation[]
  ): Observable<CaseRequestViewModel | null> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        let headers = new HttpHeaders();
        const etag = this.etagsMap.get(filingId) as string;
        headers = headers.append('etag', etag);
        headers = headers.append('if-match', etag);

        const url = `${envConfig.Endpoints.filing.caseRequest(filingId)}`;
        return this.http
          .patch(url, jsonPatchOperations, {
            headers,
            observe: 'response',
            responseType: 'json',
          })
          .pipe(
            tap((response: HttpResponse<Object>) => {
              const etag = response.headers.get('etag') as string;
              this.etagsMap.set(filingId, etag);
            }),
            map((response: HttpResponse<CaseRequest>) => {
              return response.body as CaseRequestViewModel;
            }),
            catchError((err) => {
              console.error('Error on Req to Patch CaseRequest:\n', err);
              return of(null);
            })
          );
      })
    );
  }

  getTransactionCategoryMetrics(): Observable<FilingMetrics> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.ApiServer.BaseURL}/${envConfig.API_VERSION}/${OLD_ROUTE.FILING}/metrics`;
        return this.api.get<FilingMetrics>(url);
      }),
      shareReplay()
    );
  }

  // Used downstream to populate the transactions grid
  getDraftFilingEntitiyGridResult(
    queryData: FsxApiSearchQuery
  ): Observable<FilingGridResult> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.ApiServer.BaseURL}/${envConfig.API_VERSION}/${OLD_ROUTE.FILING}/query`;
        return this.api
          .post<FsxApiSearchQuery, FilingGridResult>(url, queryData)
          .pipe(
            catchError(() => {
              return EMPTY;
            })
          );
      }),
      shareReplay()
    );
  }

  getFilingProfile(filing: Filing | NewFiling): Observable<FilingProfile> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.ApiServer.BaseURL}/${envConfig.API_VERSION}/courts/${filing.courtId}/profiles/${filing.profileId}`;
        return this.api.get<FilingProfile>(url);
      }),
      shareReplay()
    );
  }

  getFiling(filingId: string): Observable<Filing> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.ApiServer.BaseURL}/${envConfig.API_VERSION}/filings/${filingId}`;
        return this.api.get<Filing>(url);
      }),
      shareReplay()
    );
  }

  getCaseRequest(filingId: string): Observable<CaseRequestViewModel> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.ApiServer.BaseURL}/${envConfig.API_VERSION}/filings/${filingId}/request`;
        return this.http.get(url, { observe: 'response' }).pipe(
          tap((response: HttpResponse<Object>) => {
            const etag = response.headers.get('etag') as string;
            this.etagsMap.set(filingId, etag);
          }),
          map((response: HttpResponse<Object>) => {
            return response.body as CaseRequestViewModel;
          })
        );
      }),
      shareReplay()
    );
  }

  validateRequest(filingId: string): Observable<any> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url: string = `${envConfig.ApiServer.BaseURL}/${envConfig.API_VERSION}/filings/${filingId}/validate`;
        return this.api.get<Filing>(url);
      }),
      shareReplay()
    );
  }

  getDocumentInfos(filingId: string): Observable<DocumentInfo[]> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.documents(filingId)}`;
        return this.api.get<DocumentInfo[]>(url);
      })
    );
  }

  deleteDocumentInfo(
    filingId: string,
    documentId: string
  ): Observable<DocumentInfo[]> {
    return this.envConfig.pipe(
      take(1),
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.documents(
          filingId
        )}/${documentId}`;
        return this.http.delete<DocumentInfo[]>(url).pipe(
          // TODO - validation - deleteDocument
          catchError((err: HttpErrorResponse) => {
            if (err.error.status === 404) {
              console.error('Delete DocumentInfo Failed.');
            }
            return EMPTY;
          })
        );
      }),
      shareReplay()
    );
  }

  submitFiling(filingId: string): Observable<ValidationResultViewModel> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.submit(filingId)}`;
        return this.http.put<ValidationResultViewModel>(url, null).pipe(
          catchError((err: HttpErrorResponse) => {
            return of(err.error as ValidationResultViewModel);
          })
        );
      }),
      shareReplay()
    );
  }

  search(
    filingId: string,
    searchValue: string,
    searchName: string
  ): Observable<SearchResultItemGridResult> {
    return this.api
      .post<{}, SearchResultItemGridResult>(
        this.appConfig.fsxApi.filing.search(filingId, searchName),
        {
          data: [],
          filters: [
            {
              column: 'searchText',
              operator: Operator.Equal,
              value1: searchValue,
            },
          ],
        }
      )
      .pipe(shareReplay());
  }

  /**
   * A private method to strip out the isValid property to ensure json is valid.
   * setting to undefined means they won't be serialized back to the API
   * (or more accurately, passed on to any third party)
   *
   * @param caseRequest The case request object we want to sanatize
   */
  private sanitizeCaseRequest(caseRequest: CaseRequestViewModel) {
    caseRequest.isValid = undefined;
    caseRequest.isReviewValid = undefined;
    caseRequest.cases?.forEach((cr: RequestCaseViewModel) => {
      cr.isValid = undefined;
    });
    caseRequest.documents?.forEach((doc: RequestDocumentViewModel) => {
      doc.isValid = undefined;
      doc.cases?.forEach((dc: RequestDocumentCaseViewModel) => {
        dc.isValid = undefined;
        dc.filedAsTo?.forEach((asTo) => (asTo.isValid = undefined));
        dc.filedBy?.forEach((asTo) => (asTo.isValid = undefined));
      });
    });
    caseRequest.participants?.forEach((p) => {
      p.isValid = undefined;
      p.addresses.forEach((a) => (a.isValid = undefined));
      p.aliases?.forEach((a) => {
        a.isValid = undefined;
        a.addresses?.forEach((a) => (a.isValid = undefined));
        a.emails?.forEach((a) => (a.isValid = undefined));
        a.identifications?.forEach((a) => (a.isValid = undefined));
        a.phones?.forEach((a) => (a.isValid = undefined));

        if (a.organization) {
          a.organization.isValid = undefined;
        }

        if (a.person) {
          a.person.isValid = undefined;
        }
      });
      p.emails?.forEach((a) => (a.isValid = undefined));
      p.identifications?.forEach((a) => (a.isValid = undefined));
      p.phones?.forEach((a) => (a.isValid = undefined));
    });
    caseRequest.parties?.forEach((p) => {
      p.isValid = undefined;
      p.representation?.forEach((p) => (p.isValid = undefined));
    });
  }

  /**
   * Api call to get the filing fees.
   * @param filingId
   */
  public getFees(filingId: string): Observable<FilingFees> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.fees(filingId)}`;
        return this.api.get<FilingFees>(url);
      }),
      shareReplay()
    );
  }

  /**
   * Api call to get the document rendering.
   * @param filingId
   * @param documentId
   * @param renderingName
   */
  public getDocumentRendering(
    filingId: string,
    documentId: string,
    renderingName: string
  ): Observable<ArrayBuffer> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.documentRendering(
          filingId,
          documentId,
          renderingName
        )}`;
        return this.api.get<ArrayBuffer>(url, { responseType: 'arraybuffer' });
      }),
      shareReplay()
    );
  }

  /**
   * Api call to get the document history.
   * @param filingId
   * @param documentId
   * @param reviewRequestName
   */
  public getDocumentHistory(
    filingId: string,
    documentId: string,
    reviewRequestName: string
  ): Observable<DocumentReviewRequestEvent[]> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.documentHistory(
          filingId,
          documentId,
          reviewRequestName
        )}`;
        return this.api.get<DocumentReviewRequestEvent[]>(url);
      }),
      shareReplay()
    );
  }

  /**
   * Api call to bookmark a filing.
   * @param filing
   */
  public bookmark(filing: Filing): Observable<Object> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.bookmark(filing.id)}`;
        return this.http.post(url, null);
      }),
      shareReplay()
    );
  }

  /**
   * Api call to unbookmark a filing.
   * @param filing
   */
  public unbookmark(filing: Filing): Observable<Object> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.bookmark(filing.id)}`;
        return this.http.delete(url);
      }),
      shareReplay()
    );
  }

  /**
   * Api call to get the case request snapshot.
   * @param filingId
   * @param snapshotKey
   */
  public getCaseRequestSnapshot(
    filingId: string,
    snapshotKey: string
  ): Observable<CaseRequestSnapshot> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.caseRequestSnapshot(
          filingId,
          snapshotKey
        )}`;
        return this.http.put<CaseRequestSnapshot>(url, null);
      }),
      shareReplay()
    );
  }

  /**
   * Updates the filing by sending a FilingUpdate object to the API endpoint.
   * @param filingId
   * @param filingUpdate
   */
  public updateFiling(
    filingId: string,
    filingUpdate: FilingUpdate
  ): Observable<Filing> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.updateFiling(
          filingId,
          filingUpdate
        )}`;
        return this.http.post<Filing>(url, filingUpdate);
      }),
      shareReplay()
    );
  }

  /**
   * Api call to get a single document info.
   * @param filingId
   * @param documentId
   */
  public getDocumentInfo(
    filingId: string,
    documentId: string
  ): Observable<DocumentInfo> {
    return this.envConfig.pipe(
      switchMap((envConfig: EnvConfig) => {
        const url = `${envConfig.Endpoints.filing.document(
          filingId,
          documentId
        )}`;
        return this.api.get<DocumentInfo>(url);
      }),
      shareReplay()
    );
  }
}
