import { Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  DEFAULT_PARTY,
  ParticipantCategory,
  CasePartyViewModel,
  ParticipantCommonCategory,
  ParticipantSpec,
  ParticipantCommonCategoryDomainCategoryValue,
} from '@fsx/fsx-shared';
import { map, Observable, of } from 'rxjs';

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IDefaultPartyService to use at runtime.
 */
export const FsxDefaultPartyService = new InjectionToken<IDefaultPartyService>(
  'FsxDefaultPartyService'
);

/**
 * A blueprint of a worker service with methods for adding and resetting the
 * default CaseParty object on a CaseRequest object in observable stream.
 */
export interface IDefaultPartyService {
  /**
   * A method for adding a default CaseParty object to a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object to add the default CaseParty object to.
   *
   * @param participantName The unique identifier to apply to the default CaseParty object.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to filter the participantSpec by.
   *
   * @param participantSpecs An array of ParticipantSpec objects to filter by the participantCommonCategory
   */
  addDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory,
    participantSpecs?: ParticipantSpec[]
  ): Observable<CaseRequestViewModel>;

  /**
   * A method for resetting a CaseParty object back to a default caseParty object on a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object which contains the CaseParty object to reset.
   *
   * @param participantName The unique identifier to identify the CaseParty object in the CaseRequest.parties collection.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to filter the participantSpec by.
   *
   * @param participantSpecs An array of ParticipantSpec objects to filter by the participantCommonCategory
   */
  setAsDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory,
    participantSpecs?: ParticipantSpec[]
  ): Observable<CaseRequestViewModel>;
}

@Injectable()
export class DefaultPartyService implements IDefaultPartyService {
  /**
   * A private helper method for setting a default caseParty object.
   *
   * @param participantName The unique identifier to apply to the default CaseParty object.
   *
   * @param participantCommonCategory The ParticipantCommonCatgeory to filter the ParticipantSpecs for when determining a default ParticipantCategory.
   * .
   * @param participantSpecs An array of ParticipantSpec objects to filter by participantCpmmonCatgeory to determine a default ParticipantCategory.
   *
   * @returns The CaseParty object as an Observable.
   */
  private getDefaultParty(
    participantName: string,
    caseId: string,
    participantCommonCategory?: ParticipantCommonCategory,
    participantSpecs?: ParticipantSpec[]
  ): Observable<CasePartyViewModel> {
    const participantSpecsForCommonCatgeory =
      participantSpecs?.filter((pSpec: ParticipantSpec) => {
        return (
          pSpec.participantCategory.commonCategory === participantCommonCategory
        );
      }) || [];

    const participantCategory: ParticipantCategory =
      participantSpecsForCommonCatgeory.length === 1
        ? (participantSpecsForCommonCatgeory[0]
            .participantCategory as ParticipantCategory)
        : {
            name: '',
            caption: '',
            commonCategory: participantCommonCategory!,
          };

    return of({
      ...DEFAULT_PARTY,
      participantName: participantName,
      participantCategory,
      caseId,
    } as CasePartyViewModel);
  }

  /**
   * A method for adding a default CaseParty object to a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object to add the default CaseParty object to.
   *
   * @param participantName The unique identifier to apply to the default CaseParty object.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to filter the participantSpec by.
   *
   * @param participantSpecs An array of ParticipantSpec objects to filter by the participantCommonCategory
   */
  addDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory,
    participantSpecs?: ParticipantSpec[]
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParty(
      participantName,
      caseRequest.cases![0].caseId!,
      participantCommonCategory,
      participantSpecs
    ).pipe(
      map((defaultParty: CasePartyViewModel) => {
        const caseRequestParties: CasePartyViewModel[] =
          caseRequest.parties || [];
        caseRequestParties.push(defaultParty);
        caseRequest.parties = caseRequestParties;
        return caseRequest;
      })
    );
  }

  /**
   * A method for resetting a CaseParty object back to a default caseParty object on a CaseRequest object.
   *
   * @param caseRequest The CaseRequest object which contains the CaseParty object to reset.
   *
   * @param participantName The unique identifier to identify the CaseParty object in the
   * CaseRequest.parties collection.
   *
   * @param participantCommonCategory The ParticipantCommonCategory to filter the participantSpec by.
   *
   * @param participantSpecs An array of ParticipantSpec objects to filter by the participantCommonCategory
   */
  setAsDefaultParty(
    caseRequest: CaseRequestViewModel,
    participantName: string,
    participantCommonCategory?: ParticipantCommonCategory,
    participantSpecs?: ParticipantSpec[]
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParty(
      participantName,
      caseRequest.cases![0].caseId!,
      participantCommonCategory,
      participantSpecs
    ).pipe(
      map((defaultParticipant: CasePartyViewModel) => {
        const caseRequestParties: CasePartyViewModel[] =
          caseRequest.parties || [];
        caseRequest.parties = caseRequestParties.map((p, i) => {
          return p.participantName === participantName ? defaultParticipant : p;
        });
        return caseRequest;
      })
    );
  }
}
