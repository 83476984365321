<!-- todo - add aria roles -->
<div
  class="review-container"
  *ngIf="combinedFilingData$ | async as combinedFilingData"
>
  <div class="section-title-container">
    <div
      class="title-container"
      fxLayout="row"
      (click)="expandCollapseSection(sections[sectionType.CaseInformation])"
    >
      <div class="title">
        {{ sections[sectionType.CaseInformation].title }}
      </div>
    </div>

    <button
      class="icon-button edit-button"
      (click)="goToCaseInformation()"
      (keypress.enter)="goToCaseInformation()"
    >
      <img
        class="edit-icon"
        src="../../assets/icons/edit_default.svg"
        alt="edit case information"
      />
    </button>
  </div>

  <div
    class="table-detail-section"
    *ngIf="sections[sectionType.CaseInformation].isExpanded"
  >
    <div class="table-row basic">
      <div class="caption">{{ summary.courtName }}</div>
      <div class="label">Court</div>
    </div>
    <div class="table-row basic" *ngFor="let detail of classification">
      <div class="caption">{{ detail.caption }}</div>
      <div class="label">{{ detail.label }}</div>
    </div>
    <div class="table-row basic">
      <div class="caption">{{ summary.caseNumber }}</div>
      <div class="label">Case Number</div>
    </div>
    <div class="table-row basic">
      <div class="caption">{{ summary.caseName }}</div>
      <div class="label">Case Name</div>
    </div>
    <div class="table-row basic">
      <div class="caption">{{ summary.clientMatter }}</div>
      <div class="label">Client/Matter Number</div>
    </div>
  </div>

  <div class="section-title-container">
    <div
      class="title-container"
      fxLayout="row"
      (click)="expandCollapseSection(sections[sectionType.CaseParties])"
    >
      <div class="title">
        {{ sections[sectionType.CaseParties].title }} ({{ parties?.length }})
      </div>
    </div>

    <button
      class="icon-button edit-button"
      (click)="goToPartiesTab()"
      (keypress.enter)="goToPartiesTab()"
    >
      <img
        class="edit-icon"
        src="../../assets/icons/edit_default.svg"
        alt="edit participants"
      />
    </button>
  </div>

  <div
    class="table-detail-section"
    *ngIf="sections[sectionType.CaseParties].isExpanded"
  >
    <div class="grid-container parties-grid">
      <div class="table-header">
        <div fxFlex="nogrow" class="header-column party-type-col">
          PARTY/TYPE
        </div>
        <div fxFlex="nogrow" class="header-column representation-col">
          REPRESENTATION
        </div>
        <div fxFlex="nogrow" class="header-column role-col">ROLE</div>
      </div>

      <div
        class="table-row participants"
        *ngFor="let pgr of partiesGridRows$ | async"
      >
        <div fxFlex="nogrow" class="row-data party-type-col">
          <div class="caption bold">{{ pgr.party.caption }}</div>
          <div class="label">{{ pgr.party.participantCategory?.caption }}</div>
        </div>
        <div fxFlex="nogrow" class="row-data representation-col">
          <div *ngIf="pgr.party.isSelfRepresented; else notSelf">
            Self-represented
          </div>
          <ng-template #notSelf>
            <div *ngFor="let rep of pgr.party.representation">
              <div class="caption">{{ rep.caption }}</div>
            </div>
          </ng-template>
        </div>
        <div fxFlex="nogrow" class="row-data role-col">
          <div *ngIf="!pgr.party.isSelfRepresented">
            <div *ngFor="let rep of pgr.party.representation">
              <div class="caption">{{ rep.participantCategory?.caption }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    class="table-detail-section"
    *ngIf="sections[sectionType.CourtesyCopies].isExpanded"
  >
    <div
      class="table-row basic courtesy-copies"
      *ngFor="let party of courtesyCopies"
    >
      <div fxFlex="nogrow" class="row-data recipient-name-col">
        <div class="caption bold">TODO - Participant</div>
        <div class="label">Recipient Name</div>
      </div>
      <div fxFlex class="row-data recipient-address-col">
        <div class="caption bold">TODO - Participant Address</div>
        <div class="label">Recipient Address</div>
      </div>
    </div>
  </div>

  <div class="section-title-container documents">
    <div
      class="title-container"
      fxLayout="row"
      (click)="expandCollapseSection(sections[sectionType.Documents])"
    >
      <div class="title">
        {{ sections[sectionType.Documents].title }} ({{
          documents?.length || 0
        }})
      </div>
    </div>

    <button
      class="icon-button edit-button"
      (click)="editDocuments()"
      (keypress.enter)="editDocuments()"
    >
      <img
        class="edit-icon"
        src="../../assets/icons/edit_default.svg"
        alt="edit participants"
      />
    </button>
  </div>

  <div
    class="table-detail-section"
    *ngIf="sections[sectionType.Documents].isExpanded"
  >
    <div class="grid-container documents">
      <!-- START: Documents Header -->
      <div
        class="table-header"
        fxLayout="row"
        fxLayoutAlign="start end"
        fxLayoutGap="30px"
      >
        <div fxFlex="nogrow" class="header-column title-col documents">
          TITLE
        </div>
        <div fxFlex="nogrow" class="header-column access-col">ACCESS</div>
        <div fxFlex="nogrow" class="header-column pdf-col">FILE</div>
        <div fxFlex class="header-column fees-col">STATUTORY FEES</div>
      </div>
      <!-- END: Documents Header -->

      <!-- START: Documents Rows -->
      <ng-container *ngFor="let row of documentsGridRows$ | async">
        <div
          class="table-row documents"
          [ngClass]="{ sub: !row.requestDocument.isLeadDocument }"
          fxLayout="row"
          fxLayoutAlign="start center"
          fxLayoutGap="30px"
        >
          <div fxFlex="none" class="doc-row-number-col">
            <span>{{
              requestDocuments | documentOrderNumber: row.rowIndex
            }}</span>
          </div>
          <div fxFlex="nogrow" class="row-data title-col">
            <div class="caption bold">
              {{ row.requestDocument.title || "(New Document)" }}
            </div>
            <div class="label">{{ row.requestDocument.category?.caption }}</div>
          </div>
          <div fxFlex="nogrow" class="access-col title-col">
            <div class="caption">
              <span *ngIf="combinedFilingData?.filingProfile as filingProfile">
                {{
                  filingProfile
                    | documentAccessCatgeoryCaption
                      : row.requestDocument.accessCategoryName!
                }}
              </span>
            </div>
          </div>
          <div fxFlex="nogrow" class="access-col pdf-col" fxLayout="row">
            <!-- START: PDF Page Count -->
            <div
              *ngIf="
                row.documentInfo | convertedPdf as convertedPdf;
                else emptySpace
              "
              fxFlex="60px"
            >
              <div class="caption">{{ convertedPdf.size | fileSize }}</div>
              <div class="label">Pages: {{ convertedPdf.pageCount }}</div>
            </div>
            <ng-template #emptySpace>
              <div fxFlex="60px"></div>
            </ng-template>
            <!-- END: PDF Page Count -->

            <!-- START: PDF Icon & Link -->
            <div fxFlex="60px" class="pdf-icon">
              <img src="../../assets/icons/view_default.svg" alt="pdf" />
            </div>
            <!-- END: PDF Icon & Link -->
          </div>
          <div fxFlex class="fees-col title-col">-</div>
        </div>
      </ng-container>
      <!-- END: Documents Rows -->
    </div>
  </div>

  <div class="section-title-container">
    <div
      class="title-container"
      fxLayout="row"
      (click)="expandCollapseSection(sections[sectionType.Fees])"
    >
      <div class="title">{{ sections[sectionType.Fees].title }}</div>
    </div>
  </div>

  <div
    *ngIf="sections[sectionType.Fees].isExpanded"
    class="table-detail-section"
  >
    <div
      class="fees"
      fxLayout="column"
      fxLayoutGap="start end"
      *ngIf="filingFeesData$ | async as filingFeesData"
    >
      <div
        class="inner-row refresh-row"
        fxFlex="row"
        fxLayout="end center"
        fxFlexAlign="end"
        [ngClass]="{ disabled: filingFeesData.isRefreshing }"
      >
        <mat-icon
          fxFlex="none"
          class="icon refresh"
          (click)="!filingFeesData.isRefreshing && onUpdateFeesClicked()"
          >refresh</mat-icon
        >
        <span
          fxFlex="none"
          class="label"
          (click)="!filingFeesData.isRefreshing && onUpdateFeesClicked()"
          >Update Fees</span
        >
        <div fxFlex="none" class="icon info"></div>
      </div>

      <!-- START: Fees ToDo: Perhaps run the currency value in the item through a currency pipe instead of assuming '$'-->
      <ng-container *ngIf="filingFeesData.filingFees as filingFees">
        <div
          *ngFor="let feeItem of filingFees.items"
          class="inner-row"
          fxLayout="row"
          fxLayoutAlign="end center"
        >
          <div fxFlex="auto" class="caption">{{ feeItem.caption }}</div>
          <div fxFlex="60px" class="fee">
            $ {{ feeItem.amount.amount.toFixed(2) }}
          </div>
        </div>
        <div
          class="inner-row total-row"
          fxLayout="row"
          fxLayoutAlign="end center"
        >
          <div fxFlex="auto" class="caption total-label">Total</div>
          <div fxFlex="60px" class="fee total">
            $ {{ filingFees.total.amount.toFixed(2) }}
          </div>
        </div>
      </ng-container>
      <!-- END: Fees -->
    </div>

    <fsx-textbox-component
      #noteToClerkField
      *ngIf="notesToClerkFieldDefinition && allowNoteToClerk"
      [caption]="'Include Note to Clerk'"
      [labelClasses]="'note-to-clerk'"
      [height]="'60px'"
      [initialValues]="[caseRequest.noteToClerk]"
      [fieldDefinitions]="[notesToClerkFieldDefinition]"
      [placeholder]="'Enter Note'"
      (textChanged)="setNotesToClerk($event)"
    >
    </fsx-textbox-component>
  </div>
</div>
