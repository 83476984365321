<section *ngIf="user$ | async as user">
  <fsx-shared-sidenav
    *ngIf="(isIFrame$ | async) === false"
  ></fsx-shared-sidenav>
  <main
    class="content-area"
    [class.iframe-padding]="(isIFrame$ | async) === true"
  >
    <router-outlet class="auth-outlet"></router-outlet>
  </main>
  <fsx-loading-spinner *ngIf="isLoading$ | async"></fsx-loading-spinner>
</section>
