import { Pipe, PipeTransform } from '@angular/core';
import { AddressProfileSpec, AddressViewModel } from '../types';

@Pipe({
  name: 'generalAddressCaption',
})
export class CustomAddressCaptionPipe implements PipeTransform {
  /**
   * A pipe transform method to convert an AddressViewModel object into an address
   * caption (string) using the rules in a given AddressProfileSpec object.
   *
   * @param address The AddressViewModel object to transform.
   *
   * @param addressProfile The AddressProfileSpec object containing the transformation rules.
   *
   * @returns The address caption as a string.
   */
  transform(
    address: AddressViewModel,
    addressProfile: AddressProfileSpec
  ): string {
    if (!addressProfile) {
      throw 'addressProfile could not be found';
    }

    let personAddress = '';
    personAddress += addressProfile?.addressLine1.visible
      ? address.addressLine1
      : '';

    personAddress +=
      addressProfile?.addressLine2?.visible && address.addressLine2
        ? ', ' + address.addressLine2
        : '';

    personAddress +=
      addressProfile?.locality.visible && address.locality
        ? ', ' + address.locality
        : '';

    personAddress +=
      addressProfile?.administrativeArea?.visible && address.administrativeArea
        ? ', ' + address.administrativeArea.toUpperCase()
        : '';

    personAddress +=
      addressProfile?.postalCode.visible && address.postalCode
        ? ', ' + address.postalCode
        : '';

    return personAddress;
  }
}
