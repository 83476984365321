import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  CaseRequestViewModel,
  ContactViewModel,
  ContactSummaryViewModel,
  CreateContactsService,
  FilingProfile,
  ParticipantCategory,
  ParticipantSpec,
  ICaseRequestUpdateService,
  ICaseRequestBuilderService,
  FsxCaseRequestBuilderService,
  FsxCaseRequestUpdateService,
} from '@fsx/fsx-shared';
import { from, Observable, Subject, switchMap, tap } from 'rxjs';
import { PartyAndParticipant } from '../../filing-editor/services/party-and-participant-data.service';
import {
  FsxValidatePartiesOrchestrationService,
  IValidatePartiesOrchestrationService,
} from '../../filing-editor/services/orchestration/validate-parties-orchestration.service';
import { v4 as uuidv4 } from 'uuid';

export const FsxAddSelectedContactsAsRepresentationOrchestrationService =
  new InjectionToken<IAddSelectedContactsAsRepresentationOrchestrationService>(
    'FsxAddSelectedContactsAsRepresentationOrchestrationService'
  );

export interface IAddSelectedContactsAsRepresentativesParams {
  filingId: string;
  caseRequest: CaseRequestViewModel;
  contactSummaries: ContactSummaryViewModel[];
  participantCategory: ParticipantCategory;
  participantSpec: ParticipantSpec;
  filingProfile: FilingProfile;

  /**
   * The CaseParty and RequestParticipant objects that the representation
   * is to be added to.
   */
  partyAndParticipant: PartyAndParticipant;
}

export interface IAddSelectedContactsAsRepresentationOrchestrationService {
  addContactsAsRepresentativesStream$: Observable<CaseRequestViewModel>;
  addSelectedContactsAsRepresentatives(
    params: IAddSelectedContactsAsRepresentativesParams
  ): void;
}

@Injectable()
export class AddSelectedContactsAsRepresentationOrchestrationService
  implements IAddSelectedContactsAsRepresentationOrchestrationService
{
  private addSelectedContactsAsRepresentatives$$ =
    new Subject<IAddSelectedContactsAsRepresentativesParams>();

  addContactsAsRepresentativesStream$: Observable<CaseRequestViewModel> =
    this.addSelectedContactsAsRepresentatives$$.pipe(
      switchMap((params: IAddSelectedContactsAsRepresentativesParams) => {
        const caseRequestBackup: CaseRequestViewModel = {
          ...params.caseRequest,
        } as CaseRequestViewModel;
        return this.createContactsService
          .createContactsFromContactSummaries(params.contactSummaries)
          .pipe(
            switchMap((contacts: ContactViewModel[]) => {
              return from(contacts).pipe(
                switchMap((contact: ContactViewModel) => {
                  // The unique identifier to use as the new RequestParticipantRepresentation object's participantname.
                  const uniqueIdentifier1: string = uuidv4();

                  return this.caseRequestBuilderService
                    .createRepresentationAndParticipantThenSetInCaseRequest({
                      ...params,
                      contact,
                      uniqueIdentifier1,
                    })
                    .pipe(
                      switchMap(() => {
                        return this.caseRequestUpdateService
                          .optimisticPutOrRestore(
                            params.filingId,
                            params.caseRequest,
                            caseRequestBackup
                          )
                          .pipe(
                            tap(() => {
                              // Revalidate the parent party and the newly created representation only.
                              const parentParticipantName =
                                params.partyAndParticipant.participant.name;
                              const includedParticipantNames = [
                                parentParticipantName,
                                uniqueIdentifier1,
                              ];
                              this.validatePartiesOrchestrationService.validateParties(
                                { includedParticipantNames }
                              );
                            })
                          );
                      })
                    );
                })
              );
            })
          );
      })
    );

  constructor(
    private readonly createContactsService: CreateContactsService,
    @Inject(FsxCaseRequestBuilderService)
    private readonly caseRequestBuilderService: ICaseRequestBuilderService,
    @Inject(FsxCaseRequestUpdateService)
    private readonly caseRequestUpdateService: ICaseRequestUpdateService,
    @Inject(FsxValidatePartiesOrchestrationService)
    private readonly validatePartiesOrchestrationService: IValidatePartiesOrchestrationService
  ) {}

  addSelectedContactsAsRepresentatives(
    params: IAddSelectedContactsAsRepresentativesParams
  ): void {
    this.addSelectedContactsAsRepresentatives$$.next(params);
  }
}
