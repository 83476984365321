import { Injectable } from '@angular/core';
import {
  CaseRequestViewModel,
  DEFAULT_PARTICIPANT,
  RequestParticipantViewModel,
} from '@fsx/fsx-shared';
import { map, Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DefaultParticipantService {
  /**
   * A private method to help get a default RequestParticipant object with the minimum properties set.
   *
   * @param uniqueIdentifier The GUID to use as the participantName
   *
   * @returns A default RequestParticipant object with the minimum properties set.
   */
  private getDefaultParticipant(
    uniqueIdentifier: string
  ): Observable<RequestParticipantViewModel> {
    return of({
      ...DEFAULT_PARTICIPANT,
      name: uniqueIdentifier,
    } as RequestParticipantViewModel);
  }

  /**
   * A public method to allow for the adding of a CaseParty object in the CaseRequest.parties array.
   *
   * @param uniqueIdentifier The GUID to use as the participantName
   * @param caseRequest The CaseRequest object on which we want to add it.
   *
   * @returns The CaseRequest object containing the bew default RequestParticipant object.
   */
  public addDefaultParticipant(
    uniqueIdentifier: string,
    caseRequest: CaseRequestViewModel
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParticipant(uniqueIdentifier).pipe(
      map((defaultParticipant: RequestParticipantViewModel) => {
        const caseRequestParticipants: RequestParticipantViewModel[] =
          caseRequest.participants || [];
        caseRequestParticipants.push(defaultParticipant);
        caseRequest.participants = caseRequestParticipants;
        return caseRequest;
      })
    );
  }

  /**
   * A public method to allow for the resetting of a RequestParticipant object in the CaseRequest.parties array.
   *
   * @param uniqueIdentifier The GUID to use as the participantName
   * @param caseRequest The CaseRequest object on which we want to reset it.
   *
   * @returns The CaseRequest object containing the reset RequestParticipant object.
   */
  setAsDefaultParticipant(
    uniqueIdentifier: string,
    caseRequest: CaseRequestViewModel
  ): Observable<CaseRequestViewModel> {
    return this.getDefaultParticipant(uniqueIdentifier).pipe(
      map((defaultParticipant: RequestParticipantViewModel) => {
        const caseRequestParticipants: RequestParticipantViewModel[] =
          caseRequest.participants || [];
        caseRequest.participants = caseRequestParticipants.map((p, i) => {
          return p.name === uniqueIdentifier ? defaultParticipant : p;
        });
        return caseRequest;
      })
    );
  }
}
