import { Inject, Injectable, InjectionToken } from '@angular/core';
import {
  AdditionalFieldValue,
  CaseRequestViewModel,
  DocumentCategory,
  RequestDocumentParticipant,
} from '@fsx/fsx-shared';
import { Observable, map } from 'rxjs';
import {
  FsxCaseRequestDataService,
  ICaseRequestDataService,
} from '../../filing-editor/services/case-request-data.service';

/**
 * An interface to define the structure of the "filedBy" and "asTo"
 * constants in the AssociatedPartyConstants abstract class.
 */
export interface AssociatedPartyNameAndCaption {
  /**
   * A string to store the name that will be used as the key in the key/value pair.
   */
  associatedPartyName: string;

  /**
   * A string to store the caption that will be used as the value in the key/value pair.
   */
  caption: string;
}

/**
 * An abstract class containing some constants to help setup an AdditionalFieldValue object
 * for parties that are selected as Associated Parties (Filed By/As To).
 *
 * NOTE: Associated Parties are not stored as AdditionalFieldValue objects so we
 * derive our own in this service to return them in a consistent format.
 */
export abstract class AssociatedPartyConstants {
  static filedBy = {
    associatedPartyName: 'filed_by',
    caption: 'Filed By',
  };

  static asTo = {
    associatedPartyName: 'filed_as_to',
    caption: 'As To',
  };
}

/**
 * An interface defining the return type of the main getAdditionalFieldValues() method in this service.
 * This is An extension of the AdditionalFieldValue interface, with the addition of a documentCategory
 * property.
 *
 * We need to return the documentCategory along with each AdditionalFieldvalue object so that other
 * parts of the app know which DocumentSpec object to search for ParticipantFieldSpec objects in.
 */
export interface AdditionalFieldValuePlusDocumentCategory
  extends AdditionalFieldValue {
  /**
   * The DocumentCategory for which the AdditionalFieldValue was added for.
   */
  category?: DocumentCategory | null;
}

/**
 * An interface defining the return type for the getAdditionalFieldNames() method in this service.
 *
 * We need to return the documentCategory along with each additionalFieldName string so that other
 * parts of the app know which DocumentSpec object to search for ParticipantFieldSpec objects in.
 */
export interface AdditionalFieldNamePlusDocumentCategory
  extends AdditionalFieldValue {
  /**
   * A
   */
  additionalFieldName: string;

  /**
   * The DocumentCategory for which the AdditionalFieldValue was added for.
   */
  category?: DocumentCategory | null;
}

/**
 * An interface to store the accumulated AdditionalFieldValue objects along with a latest known document category,
 * which we use in subsequent loops attach to any subsequently derived AdditionalFieldValue objects.
 */
export interface RecursiveAdditionalFieldValuePlusDocumentCategory {
  /**
   * The accumulated additionalFieldValues, each with the document category as it was at the time of the loookup.
   */
  additionalFieldValuesWithDocumentCategories: AdditionalFieldValuePlusDocumentCategory[];

  /**
   * The latest know DocumentCategory used in the recursive lookup.
   */
  latestDocumentCategory?: DocumentCategory | null;

  /**
   * An array of the object's keys as the recursive function sees them when traversing the object graph.
   * Originally brought in to check that the recursive function was traversing the object graph correctly.
   * - Not really needed anynmore, but kept in as may be useful for debugging in the future.
   */
  breadcrumbs: string[];
}

/**
 * The InjectionToken to use in the providers array to specify a concrete-implementation
 * of the IAdditionalFieldLookupService to use at runtime.
 */
export const FsxAdditionalFieldValueLookupService =
  new InjectionToken<IAdditionalFieldValueLookupService>(
    'FsxAdditionalFieldValueLookupService'
  );

/**
 * A blueprint for a ui service, which looks up AdditionalFieldValue objects on the
 * CaseRequest object.
 */
export interface IAdditionalFieldValueLookupService {
  /**
   * A function for looking up AdditionalFieldValue objects that contain values for a
   * given participantName on the CaseRequest object.
   *
   * @param participantName The name (id) of the participant to lookup AdditionalFieldValue
   * objects for.
   *
   * @returns An array of all AdditionalFieldValue objects that contain a value for the
   * given participantName.
   */
  getAdditionalFieldValues(
    participantName: string
  ): Observable<AdditionalFieldValuePlusDocumentCategory[]>;

  /**
   * A function for looking up the additionalFieldName strings of AdditionalFieldValue
   * objects containing values for a given participantName the CaseRequest object.
   *
   * @param participantName The name (id) of the participant to lookup additionalFieldName
   * strings for.
   *
   * @returns An array of additionalFieldName strings of AdditionalFieldValue objects that
   * contain a value for the given participantName.
   */
  getAdditionalFieldNames(
    participantName: string
  ): Observable<AdditionalFieldNamePlusDocumentCategory[]>;
}

/**
 * A concrete-implementation for a ui service, which looks up AdditionalFieldValue
 * objects on the CaseRequest object.
 */
@Injectable()
export class AdditionalFieldValueLookupService
  implements IAdditionalFieldValueLookupService
{
  /**
   * All of the additionalFieldValues (wherever they come from) in a single array.
   */
  private additionalFieldValues$: Observable<
    AdditionalFieldValuePlusDocumentCategory[]
  > = this.caseRequestDataService.caseRequest$.pipe(
    map((caseRequest: CaseRequestViewModel) => {
      const whereToStart = caseRequest.documents;
      return this.findAdditionalFieldValues(whereToStart, {
        additionalFieldValuesWithDocumentCategories: [],
        latestDocumentCategory: null,
        breadcrumbs: ['documents'],
      }).additionalFieldValuesWithDocumentCategories;
    })
  );

  /**
   * @param caseRequestDataService The service containing caseRequest which we lookup everything from.
   */
  constructor(
    @Inject(FsxCaseRequestDataService)
    private readonly caseRequestDataService: ICaseRequestDataService
  ) {}

  /**
   * A recursive function to find all AdditionalFieldValue objects nested with a given object.
   *
   * @param obj The object we want to search through.
   *
   * @param acc An object containing an array of accumulated array of AdditionalFieldValue objects (to optionally append to on each iteration)
   *
   * @returns An object containing an array of accumulated array of AdditionalFieldValue objects with the latest know document category.
   */
  /* eslint-disable @typescript-eslint/no-explicit-any */
  private findAdditionalFieldValues(
    obj: any,
    acc: RecursiveAdditionalFieldValuePlusDocumentCategory
  ): RecursiveAdditionalFieldValuePlusDocumentCategory {
    const keys: string[] = Object.keys(obj);

    // We work with a copy of the recursive function's acc parameter. This helps prevent any
    // 'funnies' arising from subsequent function calls inadvertently updating the object.
    // (The breadcrumb won't work correctly if mutated by nested function calls)
    const accCopy: RecursiveAdditionalFieldValuePlusDocumentCategory = {
      ...acc,
      breadcrumbs: [...acc.breadcrumbs],
    };

    // Set the category at the earliest possible opportunity.
    // (Had several bugs where the document category was not set, trying to solve here at source)
    const hasCategory = Object.hasOwn(obj, 'category');
    if (hasCategory) {
      accCopy.latestDocumentCategory = obj.category;
    }

    // Iterate over each key in the obj to check each property in turn.
    keys.forEach((key: string) => {
      // The property value used in every subsequent check
      /* eslint-disable @typescript-eslint/no-explicit-any */
      const thisPropertyValue = obj[key] as any;

      // State properties to assist us with our checks.
      const isObject = typeof thisPropertyValue === 'object';
      const isArray = Array.isArray(thisPropertyValue);

      // These strings are the property names as they are returned by the API at time of writing.
      // (if these properties are renamed here or on the server, this will break!)
      const isAdditionalFieldValues = key === 'additionalFieldValues';
      const isFiledBy = key === 'filedBy';
      const isFiledAsTo = key === 'filedAsTo';

      // If this property is a genuine object (and not an array) then we want to search through
      // that object's properties as well (this condition triggers the recursive lookup)
      if (isObject && !isArray) {
        if (thisPropertyValue) {
          // We pass the acc array of accuumulated AdditionalFieldValue objects  to the next function call
          // to guarantee that we're always appending to the same acc array (passed by reference)
          // accCopy.breadcrumbs.push(key);
          const accCopy2: RecursiveAdditionalFieldValuePlusDocumentCategory = {
            ...acc,
            breadcrumbs: [...acc.breadcrumbs, key],
          };
          this.findAdditionalFieldValues(thisPropertyValue, accCopy2);
        }
      }

      // If this property is an array of objects then we want to search through each object
      // individually (this is another condition that triggers the recursive lookup)
      if (isArray) {
        const isArrayOfObjects: boolean =
          typeof thisPropertyValue[0] === 'object';
        if (isArrayOfObjects) {
          const arrayOfAny = thisPropertyValue as any[];
          accCopy.breadcrumbs.push(key);
          arrayOfAny.forEach((arrObj: any, index: number) => {
            accCopy.breadcrumbs.push(`${index}`);
            this.findAdditionalFieldValues(arrObj, accCopy);
          });
        }
      }

      // If this property is an additionalFieldValues property then we want to append each
      // AdditionalFieldValue object to the acc array of accumulated AdditionalFieldValue objects .
      if (isAdditionalFieldValues) {
        const addFieldValues =
          thisPropertyValue as AdditionalFieldValuePlusDocumentCategory[];

        // Map each AdditionalFieldValuePlusDocumentCategory object with the latestDocumentCategory held from a previous iteration.
        const mappedAddlvalues = addFieldValues.map(
          (addFieldValue: AdditionalFieldValuePlusDocumentCategory) => {
            addFieldValue.category = acc.latestDocumentCategory;
            return addFieldValue;
          }
        );

        if (mappedAddlvalues) {
          acc.additionalFieldValuesWithDocumentCategories.push(
            ...mappedAddlvalues
          );
        }
      }

      // The filedBy/filedAsTo properties are not stored in AdditionalFieldValue objects .
      // Here we derive an AdditionalFieldValue object for both filedBy/filedAsTo
      // along with an additionalFieldName property for consistency.
      if (isFiledBy || isFiledAsTo) {
        const addFieldValue: AdditionalFieldValuePlusDocumentCategory = {
          additionalFieldName: isFiledBy
            ? AssociatedPartyConstants.filedBy.associatedPartyName
            : AssociatedPartyConstants.asTo.associatedPartyName,
          participantValues: obj[key],
          category: acc.latestDocumentCategory,
        };

        acc.additionalFieldValuesWithDocumentCategories.push(addFieldValue);
      }
    });

    // We return the acc array of accumulated AdditionalFieldValue objects, which will have been updated
    // through each and every function call before finally returning.
    return accCopy;
  }

  /**
   * A function for looking up AdditionalFieldValue objects that contain values for a
   * given participantName on the CaseRequest object.
   *
   * @param participantName The name (id) of the participant to lookup AdditionalFieldValue
   * objects for.
   *
   * @returns An array of all AdditionalFieldValue objects that contain a value for the
   * given participantName.
   */
  getAdditionalFieldValues(
    participantName: string
  ): Observable<AdditionalFieldValuePlusDocumentCategory[]> {
    return this.additionalFieldValues$.pipe(
      map(
        (additionalFieldValues: AdditionalFieldValuePlusDocumentCategory[]) => {
          const filteredAdditionalFieldValues: AdditionalFieldValuePlusDocumentCategory[] =
            additionalFieldValues.filter(
              (addlFieldValue: AdditionalFieldValuePlusDocumentCategory) => {
                const participantValues =
                  addlFieldValue.participantValues || [];
                const participantNames = participantValues.map(
                  (participantValue: RequestDocumentParticipant) => {
                    return participantValue.participantName;
                  }
                );
                return participantNames.indexOf(participantName) > -1;
              }
            );
          return filteredAdditionalFieldValues;
        }
      )
    );
  }

  /**
   * A function for looking up the additionalFieldName strings of AdditionalFieldValue
   * objects containing values for a given participantName the CaseRequest object.
   *
   * @param participantName The name (id) of the participant to lookup additionalFieldName
   * strings for.
   *
   * @returns An array of additionalFieldName strings of AdditionalFieldValue objects that
   * contain a value for the given participantName.
   */
  getAdditionalFieldNames(
    participantName: string
  ): Observable<AdditionalFieldNamePlusDocumentCategory[]> {
    return this.getAdditionalFieldValues(participantName).pipe(
      map(
        (additionalFieldValues: AdditionalFieldValuePlusDocumentCategory[]) => {
          return additionalFieldValues.map(
            (addlFieldValue: AdditionalFieldValuePlusDocumentCategory) => {
              const additionalFieldNamePlusDocumentCategory: AdditionalFieldNamePlusDocumentCategory =
                {
                  additionalFieldName: addlFieldValue.additionalFieldName,
                  category: addlFieldValue.category,
                };
              return additionalFieldNamePlusDocumentCategory;
            }
          );
        }
      )
    );
  }
}
