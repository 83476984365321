<div class="grid-container prevent-select">
  <div class="title-row">
    <span class="title-text">Case Parties</span>
  </div>
  <div class="header-row parties-header-row">
    <div
      fxFlex="nogrow"
      class="select-participant-col"
      fxLayout="column"
      fxLayoutAlign="center"
    >
      <mat-checkbox color="primary"></mat-checkbox>
    </div>
    <div fxFlex="nogrow" class="party-type-col">PARTY/TYPE</div>
    <div fxFlex="nogrow" class="representation-col">REPRESENTATION</div>
    <div fxFlex="nogrow" class="role-col">ROLE</div>
    <div fxFlex class="firm-col">FIRM</div>
    <div fxFlex="nogrow" class="filing-role-col">FILING ROLE</div>
    <div fxFlex="nogrow" class="hover-icon-col"></div>
  </div>
  <div class="content-row">
    <div *ngFor="let row of config.partiesGridRows">
      <div
        class="parties-grid-row"
        (click)="onToggleExpandDetailRow($event, row)"
      >
        <div
          fxFlex="nogrow"
          class="select-participant-col"
          fxLayout="column"
          fxLayoutAlign="center"
        >
          <mat-checkbox color="primary"></mat-checkbox>
        </div>
        <div fxFlex="nogrow" class="party-type-col">
          <div class="party-caption">
            ({{ row.party.efmKey ? "Existing" : "New" }})
            {{ row.party.caption }}
          </div>
          <div class="participant-category-caption">
            {{ row.party.participantCategory?.caption }}
          </div>
        </div>
        <div fxFlex="nogrow" class="representation-col">
          <div *ngFor="let r of row.party.representation">
            {{ r.caption }}
          </div>
        </div>
        <div fxFlex="nogrow" class="role-col">
          <div *ngFor="let r of row.party.representation">
            {{ r.participantCategory?.caption }}
          </div>
        </div>
        <div fxFlex class="firm-col">
          <div *ngFor="let r of row.party.representation">
            {{ r.parentOrganizationCaption }}
          </div>
        </div>
        <div fxFlex="nogrow" class="filing-role-col">
          <div *ngFor="let name of row.additionalFieldCaptions">{{ name }}</div>
        </div>
        <div fxFlex="nogrow" class="hover-icon-col" fxLayout="column">
          <div class="hover-icons" fxLayout="row" fxLayoutAlign="end center">
            <button
              *ngIf="!row.party.efmKey || row.hasAdditionalFieldNames"
              class="icon-button delete-icon-button"
              fsxPreventKeyboardEventBubbling
              (keydown.enter)="onRemoveParticipantClicked($event, row)"
              (click)="onRemoveParticipantClicked($event, row)"
            >
              <div *ngIf="row.party.efmKey">
                <i class="material-icons-outlined">highlight_off</i>
              </div>
              <div *ngIf="!row.party.efmKey" class="form-delete-button">
                &nbsp;
              </div>
            </button>
            <span
              id="toggle-expand-detail-row-icon"
              class="material-icons-outlined"
              (click)="onToggleExpandDetailRow($event, row)"
            >
              {{
                expandedRowIndex === row.rowIndex
                  ? "expand_less"
                  : "expand_more"
              }}
            </span>
          </div>
        </div>
      </div>
      <div *ngIf="expandedRowIndex === row.rowIndex">
        <fsx-parties-grid-detail
          [row]="row"
          [partiesMap]="partiesMap"
          [isInitiating]="true"
          [resolver]="resolver"
          [partiesGridConfig]="config"
          [existingPartiesContactIds]="existingPartiesContactIds"
          (validateParentPartyEvent)="validateParticipant($event)"
        >
        </fsx-parties-grid-detail>
      </div>
    </div>
    <div class="add-new-row" fxLayout="column">
      <button
        class="add-new-row-inner"
        (click)="onAddParticipantClicked()"
        fxLayout="row"
        fxFlex="nogrow"
        fxLayoutAlign="start center"
      >
        <span class="material-icons add-icon"> add_circle_outline </span>
        Add Party
      </button>
    </div>
  </div>
</div>
