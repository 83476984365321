import { Inject, Injectable, InjectionToken } from '@angular/core';
import { FILING_SUB_TABS, FilingMode, FilingSubTabItem } from '@fsx/fsx-shared';
import {
  BehaviorSubject,
  Observable,
  Subject,
  combineLatest,
  distinctUntilChanged,
  filter,
  map,
  merge,
  tap,
  withLatestFrom,
} from 'rxjs';
import {
  FsxFilingDataService,
  IFilingDataService,
} from '../services/filing-data.service';
import {
  FsxFilingSubTabsFactoryService,
  IFilingSubTabsFactoryService,
} from './filing-sub-tabs-factory.service';
import {
  FsxValidationIndicatorService,
  IValidationIndicatorService,
} from '../services/validation-indicator.service';

export const FsxFilingSubTabsService =
  new InjectionToken<IFilingSubTabsService>('FsxFilingSubTabsService');

export interface IFilingSubTabsService {
  /**
   * A public member exposing an array of FilingSubTabItem objects.
   */
  filingSubTabItems$: Observable<FilingSubTabItem[]>;

  /**
   * A public member exposing the active FilingSubTabItem in the
   * array of FilingSubTabItem objects.
   */
  activeSubTabItem$: Observable<FilingSubTabItem>;

  /**
   * A public method to allow a FilingSubTabItem to be activated.
   *
   * @param filingSubTabName The name of the tab to be activated.
   */
  activateFilingSubTabItem(filingSubTabName: FILING_SUB_TABS): void;

  /**
   * A public method to allow the next FilingSubTabItem in the array
   * of FilingSubTabItem objects to be selected.
   */
  activateNext(): void;

  /**
   * A public method to allow the previous FilingSubTabItem in the array
   * of FilingSubTabItem objects to be selected.
   */
  activatePrevious(): void;
}

@Injectable()
export class FilingSubTabsService implements IFilingSubTabsService {
  private filingSubTabItemsCache$$ = new BehaviorSubject<FilingSubTabItem[]>(
    []
  );
  private filingSubTabItemsCache$ = this.filingSubTabItemsCache$$
    .asObservable()
    .pipe(
      map((filingSubTabItems: FilingSubTabItem[]) => {
        const filingSubTabItemsCopy = filingSubTabItems.map((x) =>
          Object.assign({}, x)
        ); // Ensures immutability
        return filingSubTabItemsCopy;
      })
    );

  /**
   * A private member for deriving the initial FilingSubTabItem objects based on the Filing Mode.
   */
  private filingSubTabItemsForFilingMode$: Observable<FilingSubTabItem[]> =
    this.filingDataService.filingMode$.pipe(
      filter((filingMode: FilingMode) => {
        const availableFilingModes = [
          FilingMode.OriginalPetition,
          FilingMode.Subsequent,
        ];
        return availableFilingModes.includes(filingMode);
      }),
      map((filingMode: FilingMode) => {
        switch (filingMode) {
          case FilingMode.OriginalPetition:
            return this.filingSubTabsFactoryService.getFilingSubTabItemsForOpf();
          case FilingMode.Subsequent:
            return this.filingSubTabsFactoryService.getFilingSubTabItemsForSubF();
          default:
            return [];
        }
      })
    );

  /**
   * A private member used to trigger the activation of a FilingSubTabItem in the
   * array of FilingSubTabItem objects.
   */
  private activateFilingSubTabItem$$ = new Subject<FILING_SUB_TABS>();

  /**
   * A private member which when triggered will activate the FilingSubTabItem in
   * the array of FilingSubTabItem objects.
   */
  private filingSubTabItemsNewTabActivated$: Observable<FilingSubTabItem[]> =
    this.activateFilingSubTabItem$$.pipe(
      withLatestFrom(this.filingSubTabItemsCache$),
      map(
        ([filingSubTabName, filingSubTabItemsCache]: [
          FILING_SUB_TABS,
          FilingSubTabItem[]
        ]) => {
          const activateFilingSubTabItem = filingSubTabItemsCache.find(
            (tab: FilingSubTabItem) => {
              return tab.name === filingSubTabName;
            }
          )!;

          return filingSubTabItemsCache.map((tab: FilingSubTabItem) => {
            tab.isActive = tab.tabIndex === activateFilingSubTabItem.tabIndex;
            return tab;
          });
        }
      )
    );

  /**
   * A private member used to trigger the activation of the next FilingSubTabItem in the
   * array of FilingSubTabItem objects. We pass the current active tab upfront to make the
   * subsequent stream idempotent; i.e same input to result in same output.
   */
  private activateNextFilingSubTabItem$$ = new Subject<FilingSubTabItem>();

  /**
   * A private member which when triggered will activate the next FilingSubTabItem in
   * the array of FilingSubTabItem objects.
   */
  private filingSubTabItemsNextTabActivated$: Observable<FilingSubTabItem[]> =
    this.activateNextFilingSubTabItem$$.pipe(
      withLatestFrom(this.filingSubTabItemsCache$),
      map(
        ([currentTab, filingSubTabItemsCache]: [
          FilingSubTabItem,
          FilingSubTabItem[]
        ]) => {
          const currentSelectedTab: FilingSubTabItem =
            filingSubTabItemsCache.find((tab) => tab.name === currentTab.name)!;
          const newSelectedTab: FilingSubTabItem | undefined =
            filingSubTabItemsCache.find(
              (tab) => tab.tabIndex === currentSelectedTab.tabIndex + 1
            );
          const newSelectedTabIndex: number =
            newSelectedTab?.tabIndex || currentSelectedTab.tabIndex;
          return filingSubTabItemsCache.map((tab: FilingSubTabItem) => {
            tab.isActive = tab.tabIndex === newSelectedTabIndex;
            return tab;
          });
        }
      )
    );

  /**
   * A private member used to trigger the activation of the previous FilingSubTabItem in the
   * array of FilingSubTabItem objects. We pass the current active tab upfront to make the
   * subsequent stream idempotent; i.e same input to result in same output.
   */
  private activatePreviousFilingSubTabItem$$ = new Subject<FilingSubTabItem>();

  /**
   * A private member which when triggered will activate the previous FilingSubTabItem in
   * the array of FilingSubTabItem objects.
   */
  private filingSubTabItemsPreviousTabActivated$: Observable<
    FilingSubTabItem[]
  > = this.activatePreviousFilingSubTabItem$$.pipe(
    withLatestFrom(this.filingSubTabItemsCache$),
    map(
      ([currentTab, filingSubTabItemsCache]: [
        FilingSubTabItem,
        FilingSubTabItem[]
      ]) => {
        const currentSelectedTab: FilingSubTabItem =
          filingSubTabItemsCache.find((tab) => tab.name === currentTab.name)!;
        const newSelectedTab: FilingSubTabItem | undefined =
          filingSubTabItemsCache.find(
            (tab) => tab.tabIndex === currentSelectedTab.tabIndex - 1
          );
        const newSelectedTabIndex: number = newSelectedTab
          ? newSelectedTab.tabIndex
          : currentSelectedTab.tabIndex;
        return filingSubTabItemsCache.map((tab: FilingSubTabItem) => {
          tab.isActive = tab.tabIndex === newSelectedTabIndex;
          return tab;
        });
      }
    )
  );

  /**
   * A private member which when triggered will set the validity of each FilingSubTabItem
   * in the array of FilingSubTabItem objects.
   */
  private filingSubTabItemsValidated$: Observable<FilingSubTabItem[]> =
    combineLatest([
      this.validationIndicatorService.isValidCaseDetails$,
      this.validationIndicatorService.isValidPartiesAndRepresentation$,
      this.validationIndicatorService.isValidDocuments$,
      this.validationIndicatorService.isValidReview$,
    ]).pipe(
      withLatestFrom(this.filingSubTabItemsCache$),
      filter(
        ([
          [_isDetailsValid, _isPartiesValid, _isDocumentsValid, _isReviewValid],
          filingSubTabItems,
        ]: [[boolean, boolean, boolean, boolean], FilingSubTabItem[]]) => {
          return filingSubTabItems && filingSubTabItems.length > 0;
        }
      ),
      map(
        ([
          [isDetailsValid, isPartiesValid, isDocumentsValid, isReviewValid],
          filingSubTabItems,
        ]: [[boolean, boolean, boolean, boolean], FilingSubTabItem[]]) => {
          return filingSubTabItems.reduce(
            (acc: FilingSubTabItem[], cur: FilingSubTabItem) => {
              // NOTE: Strict checks are necessary to protect against undefined values.
              // - when undefined the tabs are considered valid (no validation has taken place)
              if (cur.name === FILING_SUB_TABS.DETAILS) {
                cur.isValid = isDetailsValid === false ? false : true;
              }
              if (cur.name === FILING_SUB_TABS.PARTIES) {
                cur.isValid = isPartiesValid === false ? false : true;
              }
              if (cur.name === FILING_SUB_TABS.DOCUMENTS) {
                cur.isValid = isDocumentsValid === false ? false : true;
              }
              if (cur.name === FILING_SUB_TABS.REVIEW) {
                cur.isValid = isReviewValid === false ? false : true;
              }
              acc.push(cur);
              return acc;
            },
            []
          );
        }
      )
    );

  /**
   * A public member exposing an array of FilingSubTabItem objects. Here we merge all
   * the private streams together as single output observable.
   */
  filingSubTabItems$: Observable<FilingSubTabItem[]> = merge(
    this.filingSubTabItemsForFilingMode$,
    this.filingSubTabItemsNewTabActivated$,
    this.filingSubTabItemsNextTabActivated$,
    this.filingSubTabItemsPreviousTabActivated$,
    this.filingSubTabItemsValidated$
  ).pipe(
    tap((filingSubTabItems: FilingSubTabItem[]) => {
      this.filingSubTabItemsCache$$.next(filingSubTabItems);
    }),
    distinctUntilChanged(
      (
        filingSubTabItems1: FilingSubTabItem[],
        filingSubTabItems2: FilingSubTabItem[]
      ) => {
        return (
          JSON.stringify(filingSubTabItems1) ===
          JSON.stringify(filingSubTabItems2)
        );
      }
    ),
    tap(() => {
      // console.log('AAA | filingSubTabItems 1 | how many of these?');
    })
  );

  /**
   * A public member exposing the active FilingSubTabItem in the
   * array of FilingSubTabItem objects.
   */
  activeSubTabItem$: Observable<FilingSubTabItem> =
    this.filingSubTabItems$.pipe(
      map((filingSubTabItems: FilingSubTabItem[]) => {
        const activeSubTabItem: FilingSubTabItem = filingSubTabItems.find(
          (tab: FilingSubTabItem) => tab.isActive
        )!;
        return activeSubTabItem;
      }),
      distinctUntilChanged(
        (subTabItem1: FilingSubTabItem, subTabItem2: FilingSubTabItem) => {
          return JSON.stringify(subTabItem1) === JSON.stringify(subTabItem2);
        }
      )
    );

  /**
   * @param filingDataService Where we get the Filing Mode from.
   *
   * @param validationIndicatorService A helper service for determining the tab validity.
   *
   * @param filingSubTabsFactoryService A data service for retrieving the initial set of tabs based on the Filing Mode.
   */
  constructor(
    @Inject(FsxFilingDataService)
    private readonly filingDataService: IFilingDataService,
    @Inject(FsxValidationIndicatorService)
    private readonly validationIndicatorService: IValidationIndicatorService,
    @Inject(FsxFilingSubTabsFactoryService)
    private readonly filingSubTabsFactoryService: IFilingSubTabsFactoryService
  ) {}

  /**
   * A public method to allow a FilingSubTabItem to be activated.
   *
   * @param filingSubTabName The name of the tab to be activated.
   */
  activateFilingSubTabItem(filingSubTabName: FILING_SUB_TABS): void {
    this.activateFilingSubTabItem$$.next(filingSubTabName);
  }

  /**
   * A public method to allow the next FilingSubTabItem in the array
   * of FilingSubTabItem objects to be selected.
   */
  activateNext(): void {
    // We pass the current tab in upfront to ensure the subsequent stream computes the same output based on the same input.
    const currentSelectedTab: FilingSubTabItem =
      this.filingSubTabItemsCache$$.value.find((tab) => tab.isActive)!;
    this.activateNextFilingSubTabItem$$.next(currentSelectedTab);
  }

  /**
   * A public method to allow the previous FilingSubTabItem in the array
   * of FilingSubTabItem objects to be selected.
   */
  activatePrevious(): void {
    // We pass the current tab in upfront to ensure the subsequent stream computes the same output based on the same input.
    const currentSelectedTab: FilingSubTabItem =
      this.filingSubTabItemsCache$$.value.find((tab) => tab.isActive)!;
    this.activatePreviousFilingSubTabItem$$.next(currentSelectedTab);
  }
}
