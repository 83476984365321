<fsx-panel
  [config]="panelConfig"
  (closePanelEvent)="closePanelEventHandler()"
  (defaultPanelEvent)="defaultPanelEventHandler()"
>
  <div header>
    <fsx-contacts-list-panel-header [config]="panelHeaderConfig">
    </fsx-contacts-list-panel-header>
  </div>
  <div content *ngIf="contactSummaries$ | async as contactSummaries">
    <fsx-contacts-list-actions
      [searchType]="data.searchType"
      (addNewContactEvent)="addNewContactEventHandler()"
      (refreshListEvent)="refreshListHandler()"
    ></fsx-contacts-list-actions>
    <fsx-contacts-list-two
      [searchType]="data.searchType"
      [contactSummaries]="contactSummaries"
      [participantSpecs]="data.participantSpecs"
      (editContactEvent)="editContactEventHandler($event)"
    >
    </fsx-contacts-list-two>
  </div>
  <div footer>
    <fsx-contacts-list-panel-footer
      [config]="panelFooterConfig"
      (addButtonClickedEvent)="addButtonClickedEventHandler()"
      (cancelButtonClickedEvent)="cancelButtonClickedEventHandler()"
    >
    </fsx-contacts-list-panel-footer>
  </div>
</fsx-panel>
