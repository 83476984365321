<div fxLayout="row" fxLayoutAlign="end center" fxLayoutGap="15px">
  <div fxFlex="50" fxLayout="row" fxLayoutAlign="start center">
    <span
      id="selected-text"
      *ngIf="countOfSelectedContacts$ | async as countOfSelectedContacts"
    >
      {{ vm.selectedText }} ({{ countOfSelectedContacts }})
    </span>
  </div>
  <div fxFlex="50" fxLayout="row" fxLayoutAlign="end center">
    <button class="secondary" (click)="onCancelButtonClicked()">Cancel</button>
    <button
      class="primary"
      [disabled]="(canSubmitForm$ | async) === false"
      [ngClass]="{ disabled: (canSubmitForm$ | async) === false }"
      (click)="onAddButtonClicked()"
    >
      Add
    </button>
  </div>
</div>
