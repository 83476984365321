<div *ngIf="caption">
  <label class="caption">{{ caption }}</label>
</div>
<form
  class="fsx-address-component"
  *ngFor="let addressFormGroup of addressFormArray.controls; let index = index"
  [ngStyle]="{ width: inputWidth }"
>
  <ng-container *ngIf="fieldDefinition">
    <fsx-profile-single-selection
      #categoryField
      *ngIf="fieldDefinition.category"
      [caption]="'Category'"
      [fieldType]="fieldType.Selection"
      [width]="'168px'"
      [height]="'63px'"
      [resolver]="resolver"
      [fieldDefinition]="fieldDefinition.category"
      [initialValue]="initialValues[index] ? initialValues[index].category : ''"
      [selectionType]="selectionType.AddressCategorySelectionFieldDefinition"
      (formControlEmitter)="setControl($event, 'category', index)"
    ></fsx-profile-single-selection>

    <fsx-profile-single-selection
      #countryField
      *ngIf="fieldDefinition.country"
      [caption]="'Country'"
      [fieldType]="fieldType.Selection"
      [width]="'168px'"
      [resolver]="resolver"
      [fieldDefinition]="fieldDefinition.country"
      [initialValue]="initialValues[index] ? initialValues[index].country : ''"
      [selectionType]="selectionType.StringSelectionFieldDefinition"
      (formControlEmitter)="setControl($event, 'country', index)"
    ></fsx-profile-single-selection>

    <button
      *ngIf="
        addressFormArray.length > addressFormArray.formArraySpecs.minRequired
      "
      class="icon-button"
      [ngClass]="{ disabled: addressFormArray.disabled }"
      (keydown.enter)="delete(index); preventEvent($event)"
      (click)="delete(index)"
    >
      <div class="form-delete-button"></div>
    </button>

    <fsx-text-component
      #addressField1
      *ngIf="fieldDefinition.addressLine1"
      [caption]="'Address Line 1'"
      [width]="'516px'"
      [resolver]="resolver"
      [fieldDefinition]="fieldDefinition.addressLine1"
      [initialValue]="
        initialValues[index] ? initialValues[index].addressLine1 : ''
      "
      (formControlEmitter)="setControl($event, 'addressLine1', index)"
    ></fsx-text-component>

    <fsx-text-component
      #addressField2
      *ngIf="fieldDefinition.addressLine2"
      [caption]="'Address Line 2'"
      [width]="'516px'"
      [resolver]="resolver"
      [fieldDefinition]="fieldDefinition.addressLine2"
      [initialValue]="
        initialValues[index] ? initialValues[index].addressLine2 || '' : ''
      "
      (formControlEmitter)="setControl($event, 'addressLine2', index)"
    ></fsx-text-component>

    <fsx-text-component
      #localityField
      *ngIf="fieldDefinition.locality"
      [caption]="'City'"
      [width]="'168px'"
      [resolver]="resolver"
      [fieldDefinition]="fieldDefinition.locality"
      [initialValue]="initialValues[index] ? initialValues[index].locality : ''"
      (formControlEmitter)="setControl($event, 'locality', index)"
    ></fsx-text-component>

    <fsx-profile-single-selection
      #adminstrativeAreaField
      *ngIf="fieldDefinition.administrativeArea"
      [caption]="'State'"
      [fieldType]="fieldType.Selection"
      [width]="'168px'"
      [resolver]="resolver"
      [fieldDefinition]="fieldDefinition.administrativeArea"
      [selectionType]="selectionType.StringSelectionFieldDefinition"
      [initialValue]="
        initialValues[index] ? initialValues[index].administrativeArea : ''
      "
      (formControlEmitter)="setControl($event, 'administrativeArea', index)"
    ></fsx-profile-single-selection>

    <fsx-text-component
      #postalCodeField
      *ngIf="fieldDefinition.postalCode"
      [caption]="'Zip Code'"
      [width]="'100px'"
      [resolver]="resolver"
      [fieldDefinition]="fieldDefinition.postalCode"
      [initialValue]="
        initialValues[index] ? initialValues[index].postalCode : ''
      "
      (formControlEmitter)="setControl($event, 'postalCode', index)"
    ></fsx-text-component>
  </ng-container>
</form>

<button
  class="icon-button"
  [ngClass]="{ disabled: addressFormArray.disabled }"
  (keydown.enter)="addNewForm(); preventEvent($event)"
  (click)="addNewForm()"
  *ngIf="
    addressFormArray.length < addressFormArray.formArraySpecs.maxAllowed &&
    showAddNewForm
  "
>
  <p class="add-new-form">
    <span class="material-icons add-icon"> add_circle_outline </span>
    <span>Add&nbsp;</span>
    <span *ngIf="addressFormArray.length > 0">Another&nbsp;</span>
    <span>Address</span>
  </p>
</button>
