<div class="search-buttons-container">
  <div class="search-container" fxLayout="column" fxLayoutAlign="end start">
    <p fxFlex="none" class="static-text">Search</p>
    <div fxFlex="none">
      <div class="search-icon-container" *ngIf="!searchTerm.value">
        <span class="material-icons search-icon"> search </span>
      </div>
      <input
        #searchField
        class="contacts-search"
        fxFlex="none"
        [formControl]="searchTerm"
        type="text"
        placeholder="Name / Client No. / Email / Party Type"
        fsxFocusInput
        (focus)="searchField.focus()"
        (blur)="searchField.blur()"
        [ngClass]="{
          'input-with-value': searchTerm.value,
          disabled: searchTerm.disabled
        }"
      />
    </div>
  </div>

  <div fxLayout="row">
    <div *ngIf="searchType === searchTypeEnum.attorneys">
      <button
        class="radio-button"
        [ngClass]="{
          active: attorneySearchType === attorneySearchTypeEnum.Firm
        }"
        (click)="setAttorneySearchType(attorneySearchTypeEnum.Firm)"
      >
        My Firm
      </button>
      <button
        class="radio-button"
        [ngClass]="{
          active: attorneySearchType === attorneySearchTypeEnum.Other
        }"
        (click)="setAttorneySearchType(attorneySearchTypeEnum.Other)"
      >
        Other
      </button>
    </div>

    <button id="btn-refresh" (click)="refreshIconClicked()">
      <span class="material-icons-outlined">refresh</span>
    </button>
  </div>

  <button
    class="add-new"
    (click)="addNewContact()"
    (keydown.tab)="jumpToTableHeader($event)"
  >
    Add New
  </button>
</div>
