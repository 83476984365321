<fsx-basic-single-selection
  [height]="'24px'"
  [fieldType]="fieldType"
  [selectionType]="selectionType"
  [inputDropdownOptions]="dropdownOptions"
  [initialValue]="initialValue"
  [readOnly]="readOnly"
  (click)="onDropdownClicked($event)"
  (formControlEmitter)="onFormControlEmitted($event)"
  (selectedValue)="onSelected($event)"
>
</fsx-basic-single-selection>
