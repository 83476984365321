import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import {
  GlobalFilingButtonsComponent,
  MainMenuSidenavComponent,
} from './components';
import {
  FocusDirective,
  FocusFirstElementDirective,
  PreventKeyboardEventBubblingDirective,
} from './directives';
import { MATERIAL_MODULES } from './modules';
import { DocumentAccessCatgeoryCaptionPipe } from './pipes/document-access-catgeory-caption.pipe';
import { ConvertedPdfFileSizePipe } from './pipes/converted-pdf-file-size.pipe';
import { ConvertedPdfPipe } from './pipes/converted-pdf.pipe';
import { FileSizePipe } from './pipes/file-size.pipe';
import { ConvertedPdfStatusPipe } from './pipes/converted-pdf-status.pipe';
import { LeadOrSupportingPipe } from './pipes/lead-or-supporting.pipe';
import { DocumentOrderNumberPipe } from './pipes/document-order-number.pipe';
import { FileUploadStatePipe } from './pipes/file-upload-state.pipe';
import { ContactsIdFilterPipe } from './pipes/contacts-id-filter.pipe';
import { FilingModeDisplayNamePipe } from './pipes/filing-mode-display-name-pipe.pipe';
import { AddressCaptionPipe } from './pipes/address-caption.pipe';
import { TransactionIdPipe } from './pipes/transaction-id.pipe';
import { FilingCaptionTitlePipe } from './pipes/filing-caption-title.pipe';
import { CustomAddressCaptionPipe } from './pipes/custom-address-caption.pipe';

const COMPONENTS = [GlobalFilingButtonsComponent, MainMenuSidenavComponent];
const DIRECTIVES = [
  FocusDirective,
  FocusFirstElementDirective,
  PreventKeyboardEventBubblingDirective,
];

const PIPES = [
  AddressCaptionPipe,
  CustomAddressCaptionPipe,
  DocumentAccessCatgeoryCaptionPipe,
  ConvertedPdfStatusPipe,
  FileUploadStatePipe,
  FileSizePipe,
  ConvertedPdfPipe,
  ConvertedPdfFileSizePipe,
  LeadOrSupportingPipe,
  DocumentOrderNumberPipe,
  ContactsIdFilterPipe,
  FilingModeDisplayNamePipe,
  TransactionIdPipe,
  FilingCaptionTitlePipe,
];

const CORE_MODULES = [
  CommonModule,
  HttpClientModule,
  FormsModule,
  ReactiveFormsModule,
  RouterModule,
  FlexLayoutModule,
  MATERIAL_MODULES,
];

@NgModule({
  declarations: [COMPONENTS, DIRECTIVES, PIPES],
  imports: [CORE_MODULES],
  exports: [COMPONENTS, DIRECTIVES, CORE_MODULES, PIPES],
})
export class SharedModule {}
